import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Badge } from '../../../components';
import { DEMO_ID } from '../../../constants';

const HeadingWithBadge = (props) => {
  const { children } = props;

  const eventId = useParams()?.eventId;

  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  if (!isDemo) return children;

  return (
    <div className="flex items-center gap-2 flex-wrap">
      {children}

      <Badge
        text="Sample"
        variant="purple-gradient"
        popoverBody="This is a Sample Project, where you can try out CrowdClip® without providing any assets."
      />
    </div>
  );
};

export default HeadingWithBadge;
