import React from 'react';
import classNames from 'classnames';

import { PiLightning } from 'react-icons/pi';

const PremiumIcon = (props) => {
  const { withCircle } = props;

  return (
    <div
      className={classNames('flex items-center justify-center', {
        'w-6 h-6 bg-primary-900 text-white rounded-full animate-heartbeat':
          withCircle,
      })}
    >
      <PiLightning size={withCircle ? '0.9rem' : '1.1rem'} />
    </div>
  );
};

export default PremiumIcon;
