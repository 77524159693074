import axios from 'axios';
import config from '../../../config';
import { useAttendeeStore } from '../../../stores/attendee';
import { useUserStore } from '../../../stores/user';
import { getApiKeyClient } from '.';

const { featureToggles } = config;

export const getHttp = (baseUrl) => {
  const accessToken = useUserStore.getState().accessToken;
  const instance = axios.create({
    baseURL: baseUrl,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  useUserStore.subscribe(
    (state) => state.accessToken,
    (accessToken) => {
      instance.defaults.headers.Authorization = `Bearer ${accessToken}`;
    },
    { fireImmediately: true },
  );

  return instance;
};

export const getAttendeeClient = (baseUrl) => {
  if (featureToggles.auth0) {
    const accessToken = useAttendeeStore.getState().bearerToken;
    if (!accessToken) {
      throw new Error('Bearer token not found');
    }
    const instance = axios.create({
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    useAttendeeStore.subscribe(
      (state) => state.bearerToken,
      (bearerToken) => {
        instance.defaults.headers.Authorization = `Bearer ${bearerToken}`;
      },
      { fireImmediately: true },
    );

    return instance;
  } else {
    return getApiKeyClient(baseUrl);
  }
};
