import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { isEmpty } from '../../../../Utils';
import { sendBulkFaceEmail } from '../../../../UtilsEmail';

import { Spinner } from '../../../../components';
import EmailSendGroup from '../../../../components/form/emailSendGroup';
import PageSubheader from '../../../../components/pageSubheader';

import { URL } from '../../../../constants';

import { FACE_EMAIL_STATUS } from '../../../../constants';

const MissingAssetsModal = (props) => {
  const { show, onHide, event, people } = props;

  const [peopleEmails, setPeopleEmails] = useState({});
  const [isEmailsSending, setIsEmailsSending] = useState(false);

  useEffect(() => {
    people.forEach((person) => {
      const id = person.face.elementId;
      if (!peopleEmails[id])
        peopleEmails[id] = {
          email: person.face.email || '',
          checked: !!person.face.email,
          status: FACE_EMAIL_STATUS.NOT_SENT,
          err: null,
        };
    });

    setPeopleEmails({ ...peopleEmails });
  }, [people]);

  const { peopleWithEmail, peopleWithoutEmail } = useMemo(() => {
    let peopleWithEmail = [];
    let peopleWithoutEmail = [];

    people.forEach((person) => {
      if (person.face.email) peopleWithEmail.push(person);
      else peopleWithoutEmail.push(person);
    });

    return { peopleWithEmail, peopleWithoutEmail };
  }, [people]);

  const canBulkShare = useMemo(() => {
    return !isEmpty(
      Object.keys(peopleEmails)
        .map((id) => peopleEmails[id])
        .filter((person) => !!person.email),
    );
  }, [peopleEmails]);

  if (isEmpty(people)) return null;

  const renderPeopleContainer = (people) => {
    return (
      <div className="w-full flex flex-col gap-6 sm:gap-4">
        {people.map((person) => {
          const face = person.face;
          const emailObj = peopleEmails[face.elementId] || {};
          const hasEmail = !!face.email;

          return (
            <div
              className="w-full flex items-center sm:items-start gap-4"
              key={face.elementId}
            >
              <img
                src={person.img}
                className={`w-20 min-w-20 h-20 rounded-full object-cover ${
                  hasEmail ? 'cursor-pointer' : ''
                }`}
                alt={`${face.name} Icon`}
              />

              <div className="w-full">
                <h5>{face.name}</h5>

                <EmailSendGroup
                  type="missing-clips"
                  event={event}
                  face={face}
                  faceEmails={peopleEmails}
                  setFaceEmails={setPeopleEmails}
                  btnText="Request"
                  className="w-full"
                />

                {[FACE_EMAIL_STATUS.SENT, FACE_EMAIL_STATUS.RESEND].includes(
                  emailObj.status,
                ) && (
                  <div className="mt-1 text-sm italic opacity-80">
                    Video shared to email successfully
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleBulkSend = async (e) => {
    const peopleUpd = peopleWithEmail.map((person) => person.face);

    await sendBulkFaceEmail(
      e,
      'missing-clips',
      event,
      peopleUpd,
      peopleEmails,
      setPeopleEmails,
      setIsEmailsSending,
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header className="flex items-center justify-between" closeButton>
        <Modal.Title>Request missing clips from attendees</Modal.Title>
      </Modal.Header>

      <Modal.Body className="flex flex-col gap-8">
        {!isEmpty(peopleWithEmail) && (
          <div className="w-full flex flex-col items-center gap-6 sm:gap-2">
            <div className="w-full">
              Just hit the 'Send emails' button and people below will receive an
              email asking them to upload a required number of videos so that
              everybody can get a personalised highlight reel!
            </div>

            <div className="w-full">
              <span className="font-bold">Note</span>: We will ask attendees to
              upload their videos within 24 hours, so after that, you will need
              to go back to{' '}
              <Link to={`/${event?.eventId}${URL.VIDEO_BUILDER}`}>
                Video Builder
              </Link>{' '}
              and hit 'Analyse' again.
            </div>

            <div className="w-full flex flex-col items-center gap-2">
              <button
                className="w-full sm:w-52 h-[var(--form-el-height)] flex items-center justify-center bg-black text-lg text-white rounded-full hover:opacity-80 disabled:opacity-65"
                onClick={(e) => handleBulkSend(e)}
                disabled={!canBulkShare}
              >
                <div
                  className={`absolute ${
                    !isEmailsSending ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  Send to all
                </div>
                <Spinner
                  size="lg"
                  color="white"
                  className={`absolute ${isEmailsSending ? 'opacity-100' : 'opacity-0'}`}
                />
              </button>

              <div className="text-sm italic opacity-50">
                Request missing assets from all with email below
              </div>
            </div>

            {renderPeopleContainer(peopleWithEmail)}
          </div>
        )}

        {!isEmpty(peopleWithoutEmail) && (
          <div className="w-full flex flex-col items-center gap-6 sm:gap-4">
            <PageSubheader
              heading="Missing emails"
              subheading={
                <>The following attendees have don't have an email address.</>
              }
            />

            {renderPeopleContainer(peopleWithoutEmail)}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MissingAssetsModal;
