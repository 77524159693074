import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const PopoverTrigger = (props) => {
  const {
    content,
    delay,
    placement,
    addClass,
    hidePopover,
    popoverHeader,
    popoverBody,
    isThinPopover,
  } = props;

  const popover = (
    <Popover
      className={classNames(
        'custom-popover m-0.5 border border-primary-900 border-solid pointer-events-none',
        addClass ?? '',
      )}
    >
      {popoverHeader && (
        <Popover.Header
          className={classNames({
            'bg-gradient-primary-right text-white border-primary-900': true,
            'px-3 py-2': isThinPopover,
          })}
        >
          {parse(popoverHeader)}
        </Popover.Header>
      )}

      {popoverBody && (
        <Popover.Body
          className={classNames({
            'flex flex-col gap-0.5': true,
            'px-3 py-2': isThinPopover,
          })}
        >
          {typeof popoverBody === 'string' ? parse(popoverBody) : popoverBody}
        </Popover.Body>
      )}
    </Popover>
  );

  if (hidePopover) return content;
  else
    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        delay={delay || { show: 0, hide: 0 }}
        placement={placement ?? 'top'}
        overlay={popover}
      >
        {content}
      </OverlayTrigger>
    );
};

export default PopoverTrigger;
