import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { PiArrowSquareOut, PiEnvelope } from 'react-icons/pi';
import { useNavigate, useParams } from 'react-router-dom';

import { isEmpty } from '../../../Utils';

import { Button, Badge } from '../../../components';
import ContentHeader from '../../../components/contentHeader';
import PageSpinner from '../../../components/pageSpinner';

import { EMAILS } from '../../../constants';

import { useEventStore } from '../../../stores/event';
import { useUserStore } from '../../../stores/user';

import { SGTemplate } from '../../../types';

import { fetchTemplates, getAllEmails } from './components/Utils';
import EmailPreviewModal from './components/emailPreviewModal';
import SendTestEmailModal from './components/sendTestEmailModal';

const EmailHub = () => {
  const { eventId } = useParams();

  const user = useUserStore((state) => state.user);
  const setEventId = useEventStore((state) => state.setEventId);
  const currentEvent = useEventStore((state) => state.currentEvent);

  const [templates, setTemplates] = useState<{ [key: string]: SGTemplate }>({});
  const [previewTemplate, setPreviewTemplate] = useState<SGTemplate>({});
  const [sendTestTemplate, setSendTestTemplate] = useState<SGTemplate>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState(null);

  const navigate = useNavigate();

  // set event id on mount
  useEffect(() => {
    if (eventId) setEventId(eventId);
  }, [eventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (currentEvent.error) setPageError(currentEvent.error);
      else {
        const fetchedTemplates = await fetchTemplates(emails);
        setTemplates(fetchedTemplates);
      }

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  const onClickPreview = (templateId: string, url?: string) => {
    // if template is selected – open its preview
    if (templateId) setPreviewTemplate(templates[templateId]);
    // else – go to selection page
    else url && navigate(url);
  };

  const emails = getAllEmails(currentEvent);

  const customText = useMemo(() => {
    const template = !isEmpty(previewTemplate)
      ? previewTemplate
      : !isEmpty(sendTestTemplate)
        ? sendTestTemplate
        : null;

    let customText = '';

    if (template) {
      if (template.template_id === EMAILS.CREATION_CUSTOM)
        customText = currentEvent?.emailsData?.creationTemplateText;
      else customText = (JSON.parse(template.test_data ?? '') ?? {}).text ?? '';
    }

    return customText;
  }, [currentEvent, previewTemplate, sendTestTemplate]);

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title="Email Hub"
      isPageContainer
      className="gap-6"
    >
      <ContentHeader
        title="Email Hub"
        subtitle="View and manage emails your attendees receive"
      />

      {emails.map((email) => {
        if (!email.show) return null;

        const template = templates[email.templateId];

        const thumbnailClass =
          'w-52 aspect-[3/2] flex items-center justify-center text-lg font-medium text-uppercase rounded-md cursor-pointer transition-all duration-300';

        return (
          <React.Fragment>
            <div className="flex items-center gap-16">
              <div
                className={classNames(thumbnailClass, 'group relative')}
                onClick={() =>
                  onClickPreview(email.templateId, email.selectionUrl)
                }
              >
                {template ? (
                  <React.Fragment>
                    <div
                      className={classNames(
                        thumbnailClass,
                        'absolute top-0 left-0 bg-black-overlay-800 text-white opacity-0 hover:opacity-100 ',
                      )}
                    >
                      Preview
                    </div>

                    <img
                      src={template.thumbnail_url}
                      alt="Template Thumbnail"
                      className={classNames(
                        thumbnailClass,
                        'object-cover object-top border-1 border-grey-200 border-solid',
                      )}
                    />
                  </React.Fragment>
                ) : (
                  <div
                    className={classNames(
                      thumbnailClass,
                      'group border-1 border-grey-200 border-solid text-grey-900 hover:text-primary-900 hover:border-primary-900 hover:bg-primary-50',
                    )}
                  >
                    Select
                  </div>
                )}
              </div>

              <div className="w-[calc(100%_-_(3_*_4rem)_-_13rem_-_6rem_-_(2_*_2.5rem)_-_0.5rem)]">
                <div className="flex mb-1 gap-1 items-center">
                  <div className="font-bold">{email.title}</div>

                  {email.isAutomatic && (
                    <Badge
                      size="sm"
                      text="Automatic"
                      popoverBody="No action required on your end"
                    />
                  )}

                  {email.isOptional && (
                    <Badge
                      variant="grey"
                      size="sm"
                      text="Optional"
                      popoverBody="It’s not essential, and might be used in few cases"
                    />
                  )}
                </div>

                <div className="text-sm text-black/[0.5]">
                  {email.description}
                </div>
              </div>

              {email.selectionUrl ? (
                <Button
                  onClick={() => navigate(email.selectionUrl)}
                  isThin
                  className="w-24"
                >
                  Change
                </Button>
              ) : (
                <div className="w-24" />
              )}

              <div className="flex gap-2">
                <Button
                  onClick={() =>
                    onClickPreview(email.templateId, email.selectionUrl)
                  }
                  variant="square-icon"
                  disabled={!template}
                  popoverBody="Preview email"
                  isThinPopover
                >
                  <PiArrowSquareOut />
                </Button>

                <Button
                  onClick={() => setSendTestTemplate(template)}
                  variant="square-icon"
                  disabled={!template}
                  popoverBody="Send a test email"
                  isThinPopover
                >
                  <PiEnvelope />
                </Button>
              </div>
            </div>

            <hr className="m-0 last:hidden" />
          </React.Fragment>
        );
      })}

      <EmailPreviewModal
        show={!isEmpty(previewTemplate)}
        onHide={() => setPreviewTemplate({})}
        template={previewTemplate}
        event={currentEvent}
        user={user}
        customText={customText}
      />

      <SendTestEmailModal
        onHide={() => setSendTestTemplate({})}
        template={sendTestTemplate}
        event={currentEvent}
        user={user}
        customText={customText}
      />
    </PageSpinner>
  );
};

export default EmailHub;
