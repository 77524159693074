import React from 'react';
import moment from 'moment';
import { PiInfoFill } from 'react-icons/pi';
import { toast } from 'react-toastify';

import landscapeOverlayImg from './assets/landscape-overlay.png';
import personImg from './assets/person.png';

import config from './config';
import { URL, S3_BUCKET_URL } from './constants';

import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  DEMO_ACC,
  EVENT_TYPE_DEMO,
} from './constants';

import { getUserEvents } from './services/api/events';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const goTo = (link) => {
  window.location = link;
};

export const isEmpty = (value) => {
  return (
    value === null ||
    value === undefined ||
    value.length === 0 ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
};

export const isPlural = (value) => {
  if (typeof value === 'number') return value === 0 || value > 1 ? 's' : '';
  else return value?.length > 1 ? 's' : '';
};

export const getPluralWord = (value, word) => {
  const ending = isPlural(value);

  if (word === 'person') {
    return isEmpty(ending) ? 'person' : 'people';
  }

  return word + ending;
};

export const getPluralPhrase = (value, word, middleText) => {
  const num = typeof value === 'number' ? value : value?.length;

  return (
    num +
    (middleText ? ' ' + middleText : '') +
    ' ' +
    getPluralWord(value, word)
  );
};

export const round = (value, isToDecimal) => {
  if (isToDecimal) return Math.round(value * 10) / 10;
  return Math.ceil(value);
};

export const getPct = (value) => {
  return Math.round(value * 100 * 10) / 10;
};

export const getDuration = (length) => {
  const min = Math.floor(length / 60);
  const sec = Math.round(length % 60);

  return (min ? min + ' min ' : '') + (sec ? sec + ' seconds' : '');
};

export const msecToSec = (milliseconds) => {
  return milliseconds / 1000;
};

export const numberWithSpaces = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const isEnoughClips = (faceVideos, wildcards) => {
  let existingClips = [...faceVideos];
  let requiredClips = [...wildcards];

  // sort clips from longest to shortest
  existingClips.sort((one, other) => other - one);
  requiredClips.sort((one, other) => other - one);

  let missingClips = [];

  // check each required clip to see if it has matching existing clip
  requiredClips.forEach((reqClip) => {
    // if reqClip is matching – remove the matching clip from existingClips (so that it doesn't match anymore)
    if (existingClips[0] >= reqClip) existingClips.shift();
    // if reqClip is NOT matching – add the clip to missingClips
    else missingClips.push(reqClip);
  });

  return missingClips;
};

export const formatDate = (date) => {
  return moment(date).format('D MMM YYYY');
};

export const isOnVideoBuilder = () => {
  const pathname = window.location.pathname;
  return pathname.includes('/video-builder/');
};

export const getIsShowSideNav = (accessToken, user) => {
  const pathname = window.location.pathname;

  // side nav is existing for authenticated users only, except for few pages
  const hasSideNav =
    accessToken &&
    user &&
    pathname !== URL.HOME &&
    ![
      URL.PRICING,
      URL.SHARE,
      URL.CHECK_IN,
      URL.CHECK_IN_STAGE,
      URL.REVIEW_VIDEO,
    ].reduce((acc, url) => acc || pathname.includes(url), false);

  // if side nav is opened
  const isOpenSideNav =
    JSON.parse(localStorage.getItem('isOpenSideNav')) ?? false;

  return { hasSideNav, isOpenSideNav };
};

export const isDemoAcc = (user) => {
  return user.email === DEMO_ACC;
};

export const canAccessEvent = async (user, event) => {
  const userEvents = await getUserEvents(user.session.userId);

  const foundEvent = userEvents.find((e) => e.eventId === event.eventId);

  return !!foundEvent;
};

export const canEditEvent = (event) => {
  let now = moment().utc();

  return (
    event.type !== EVENT_TYPE_DEMO &&
    (!event.startDate || moment(event.startDate).isSameOrAfter(now, 'day'))
  );
};

export const getVBLinkParts = (pathname) => {
  const pathnameParts = pathname.split('/');
  const eventId = pathnameParts[1];
  let page = pathnameParts[3];

  if (page === 'highlight-reel') page = 'hl_reel';

  return { eventId, page };
};

// TODO replace with uuidv4
export const guidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
};

export const validateName = (firstName, lastName) => {
  let firstNameErr = null;
  let lastNameErr = null;
  let firstNameTrim = firstName.trim();
  let lastNameTrim = lastName.trim();

  if (firstNameTrim.length === 0) {
    firstNameErr = 'First name is required';
  } else if (!/^[a-zA-Z\s]+$/.test(firstNameTrim)) {
    firstNameErr = 'Name must contain only letters and spaces';
  }

  if (lastNameTrim.length === 0) {
    lastNameErr = 'Last name is required';
  } else if (!/^[a-zA-Z\s]+$/.test(lastNameTrim)) {
    lastNameErr = 'Name must contain only letters and spaces';
  }

  return { firstNameErr, lastNameErr };
};

export const validateEmail = (email, confirmEmail = null) => {
  let emailErr = null;
  let confirmEmailErr = null;

  if (email.trim().length === 0) emailErr = 'Email address is required';
  else if (!emailRegex.test(email)) emailErr = 'Invalid email address';

  if (confirmEmail !== null) {
    if (confirmEmail.trim().length === 0)
      confirmEmailErr = 'Confirm email address is required';
    else if (email !== confirmEmail) confirmEmailErr = 'Emails must match';
  }
  return { emailErr, confirmEmailErr };
};

export const validatePassword = (password, confirmPassword) => {
  let passwordErr = null;
  let confirmPasswordErr = null;

  let passwordTrim = password.trim();

  if (passwordTrim.length === 0) passwordErr = 'Password is required';
  else if (
    passwordTrim.length < PASSWORD_MIN_LENGTH ||
    passwordTrim.length > PASSWORD_MAX_LENGTH
  )
    passwordErr = `Password must be ${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH} characters long`;
  else if (!/[a-z]/.test(passwordTrim))
    passwordErr = 'Password must contain at least one lowercase character';
  else if (!/[A-Z]/.test(passwordTrim))
    passwordErr = 'Password must contain at least one uppercase character';
  else if (!/[0-9]/.test(passwordTrim))
    passwordErr = 'Password must contain at least one number';
  else if (!/[`~!@#$%^&*()_+\-={}|[\]\\;':",./<>?]/.test(passwordTrim))
    passwordErr = 'Password must contain at least one special character';

  if (confirmPassword.trim().length === 0)
    confirmPasswordErr = 'Confirm password is required';
  else if (password !== confirmPassword)
    confirmPasswordErr = 'Passwords must match';

  return { passwordErr, confirmPasswordErr };
};

export const validateTsAndCs = (isTsAndCs) => {
  let TsAndCsErr = null;

  if (!isTsAndCs) TsAndCsErr = 'You must agree to the Terms and Conditions';

  return { TsAndCsErr };
};

export const countSecsFromNow = (to) => {
  let now = new Date();
  let then = new Date(to);
  return Math.round((then - now) / 1000);
};

export const getFileUrl = (fileId, fileName, extension, time, showPreview) => {
  let url =
    `${S3_BUCKET_URL}.files/${fileId}/` +
    (showPreview ? 'preview-' : '') +
    encodeURI(fileName);

  if (extension) url += '.' + extension;
  if (time) url += '#t=' + time;

  return url;
};

export const getFileImg = (file) => {
  if (!!file?.url) return file.url;

  return file && file.fileType === 'image'
    ? getFileUrl(file.elementId, file.name)
    : personImg;
};

export const getThumbnail = (frameId) => {
  return frameId
    ? `${S3_BUCKET_URL}.thumbnails/${frameId}/720x405.png`
    : landscapeOverlayImg;
};

export const getEventImg = (type, eventImageLocation) => {
  const imageKey = eventImageLocation
    ? eventImageLocation
    : `default/default-${type}.png`;
  return `${S3_BUCKET_URL}.files/${imageKey}`;
};

// convert uploaded image to base64 for preview
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    if (callback && typeof callback === 'function') {
      callback(reader.result);
    }
  });
  reader.readAsDataURL(img);
};

export const getDefaultTips = () => {
  return [
    {
      tip: 'Videographer sales skyrocket by <b>28%</b> using CrowdClip®',
      icon: <PiInfoFill />,
    },
    {
      tip: 'CrowdClip® turns one video asset into hundreds, effortlessly',
      icon: <PiInfoFill />,
    },
    {
      tip: 'Videographers save an average of <b>1,673</b> hours using CrowdClip®',
      icon: <PiInfoFill />,
    },
    {
      tip: '<b>43,114</b> minutes of footage analysed and counting!',
      icon: <PiInfoFill />,
    },
  ];
};

export const getVideoBuilderLink = (event) => {
  return `/${event.eventId}${event.isAssigneeAccess ? URL.VB_ASSIGNEE : URL.VIDEO_BUILDER}`;
};

export const getUrl = (page, eventId, id) => {
  switch (page) {
    case URL.VIDEO_BUILDER:
      return `/${eventId}${URL.VIDEO_BUILDER}`;
    case URL.VB_ASSIGNEE:
      return `/${eventId}${URL.VB_ASSIGNEE}`;
    case URL.VB_PEOPLE:
      return `/${eventId}${URL.VB_PEOPLE}`;
    case URL.VB_HL_REEL:
      return `/${eventId}${URL.VB_HL_REEL}`;
    case URL.VB_CLIPS:
      return `/${eventId}${URL.VB_CLIPS}`;
    case URL.VB_ANALYSIS:
      return `/${eventId}${URL.VB_ANALYSIS}`;
    case URL.VB_SHARE:
      return `/${eventId}${URL.VB_SHARE}`;

    case URL.DASH_EVENT_BUILDER:
      return `${URL.DASH_EVENT_BUILDER}?eventId=${eventId}`;
    case URL.EMAIL_HUB:
      return `${URL.EMAIL_HUB}${eventId}${id ? '/' + id : ''}`;

    case URL.CHECK_IN:
      return `${URL.CHECK_IN}${eventId}`;
    case URL.SHARE:
      return `${URL.SHARE}${eventId}`;

    default:
      return '/';
  }
};

export const getCheckInLink = (eventId) => {
  return window.location.origin + URL.CHECK_IN + eventId;
};

export const getShareLink = (eventId, faceId) => {
  const hubLink = window.location.origin + URL.SHARE + eventId;

  if (faceId) return hubLink + '/' + faceId;

  return hubLink;
};

export const handleCopy = (text, setIsCopied) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success('Copied to clipboard!', {
        toastId: 'copy-success',
      });

      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 8000);
    })
    .catch((error) => {
      console.error('Failed to copy:', error);
    });
};

export const isProduction = () => {
  return config.appEnv === 'production';
};

export const getDemoSelected = (array, isUnselected) => {
  if (!array) return [];
  if (isUnselected) return array.filter((el) => !el.isSelected);
  return array.filter((el) => el.isSelected);
};

export const getDemoSuffix = (event) => {
  if (event?.type === 'demo') return '-[Demo]';
  else return '';
};

export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const updateEmailText = (text, event, user, customText) => {
  return text
    .replace(/{{{event_name}}}/g, event.name)
    .replace(
      /{{{insert name 'default=Amazing human'}}}/g,
      user?.firstName ?? 'Amazing human',
    )
    .replace(/{{{share_url}}}/g, 'https://www.crowdclip.com/')
    .replace(/{{{text}}}/g, customText || '{{{text}}}');
};
