import ReactGA from 'react-ga4';

export const trackHubClickPerson = (personName) => {
  ReactGA.event({
    category: 'Attendee',
    action: 'share_hub_click_person',
    label: personName,
  });
};

export const trackCreationClickDownload = (type) => {
  ReactGA.event({
    category: 'Attendee',
    action: `share_creation_click_${type}_download`,
  });
};

export const trackCreationDownloadSuccess = (type) => {
  ReactGA.event({
    category: 'Attendee',
    action: `share_creation_${type}_download_success`,
  });
};

export const trackCreationDownloadError = (type) => {
  ReactGA.event({
    category: 'Attendee',
    action: `share_creation_${type}_download_error`,
  });
};

export const trackCreationClickNativeShare = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'share_creation_click_native_share',
  });
};

export const trackCreationNativeShareSuccess = (label) => {
  ReactGA.event({
    category: 'Attendee',
    action: 'share_creation_native_share_success',
    label: label,
  });
};

export const trackCreationNativeShareError = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'share_creation_native_share_error',
  });
};

export const trackSharePageClickHelp = (type) => {
  ReactGA.event({
    category: 'Attendee',
    action: `${type}_click_help`,
  });
};
