import moment from 'moment';
import ReactGA from 'react-ga4';

export const trackEnterEmailClickContinue = (availability) => {
  ReactGA.event({
    category: 'User',
    action: 'enter_email_click_continue',
    label: availability,
  });
};

export const trackSendVerificationEmailSuccess = () => {
  ReactGA.event({
    category: 'User',
    action: 'send_verification_email_success',
  });
};

export const trackSendVerificationEmailError = () => {
  ReactGA.event({
    category: 'User',
    action: 'send_verification_email_error',
  });
};

export const trackEmailConfirmationClickResend = () => {
  ReactGA.event({
    category: 'User',
    action: 'email_confirmation_click_resend',
  });
};

export const trackEmailConfirmationClickGoBack = () => {
  ReactGA.event({
    category: 'User',
    action: 'email_confirmation_click_go_back',
  });
};

export const trackAccountCreateSuccess = () => {
  const timestampMills = moment(new Date()).valueOf();

  ReactGA.event({
    category: 'User',
    action: 'account_create_success',
    value: timestampMills,
  });
};

export const trackAccountCreateError = () => {
  ReactGA.event({
    category: 'User',
    action: 'account_create_error',
  });
};

export const trackLogInSuccess = (previousPage) => {
  const timestampMills = moment(new Date()).valueOf();

  ReactGA.event({
    category: 'User',
    action: 'log_in_success',
    label: previousPage,
    value: timestampMills,
  });
};

export const trackLogInError = (errMsg) => {
  ReactGA.event({
    category: 'User',
    action: 'log_in_error',
    label: errMsg,
  });
};

export const trackLogOut = () => {
  ReactGA.event({
    category: 'User',
    action: 'log_out',
  });
};
