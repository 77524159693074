import React, { useState } from 'react';
import moment from 'moment';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, formatDate } from '../../../../Utils';

import {
  trackSetDateUpdateSuccess,
  trackSetDateUpdateError,
} from '../../../../analytics';

import { Alert } from '../../../../components';

import FormFeedback from '../../../../components/form/formFeedback';
import SubmitBtn from '../../../../components/submitBtn';

import { setAttendeeReviewData } from '../../../../services/api/mongodb';
import { useEventStore } from '../../../../stores/event';

import VBAccordion from './vbAccordion';

const SetDateStep = () => {
  const { eventId } = useParams();

  const currentEvent = useEventStore((state) => state.currentEvent);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const releaseDate =
    currentEvent.attendeeReviewData?.releaseDate?.slice(0, 10) ?? '';

  const [date, setDate] = useState(releaseDate);
  const [dateError, setDateError] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const onChangeDate = (e) => {
    let value = e.target.value;
    let now = moment().utc();

    if (moment(value).isSameOrBefore(now, 'day'))
      setDateError(
        'Date cannot be the same as or earlier than the current date',
      );
    else setDateError(null);

    setDate(value);
  };

  const handleSaveDate = async () => {
    setIsSaving(true);

    const mills = new Date(date).getTime();

    try {
      await setAttendeeReviewData({ eventId, releaseDate: mills });
      await updateCurrentEvent();

      toast.success('Your release date has been successfully saved');
      trackSetDateUpdateSuccess();
    } catch {
      toast.error('Something went wrong. Try again later');
      trackSetDateUpdateError();
    } finally {
      setIsSaving(false);
    }
  };

  const isAllowUpdate = currentEvent.stage === 'upload';

  return (
    <VBAccordion
      heading="Set Date"
      subheading={
        !!releaseDate ? (
          <>
            Release date is set to: <b>{formatDate(releaseDate)}</b>
          </>
        ) : (
          <>
            Set the date for when the automatic analysis of your assets will
            kick off
          </>
        )
      }
      progressText={!!releaseDate ? 'Completed' : 'In Progress'}
      isOpen={!releaseDate}
    >
      {!isAllowUpdate && (
        <Alert
          alertData={{
            variant: 'warning',
            text: 'The release date can no longer be updated since the analysis has already started.',
          }}
        />
      )}

      <fieldset
        className="flex flex-col sm:flex-row gap-2 sm:gap-4"
        disabled={!isAllowUpdate}
      >
        <FloatingLabel label="Release Date*" className="w-full sm:w-4/5">
          <Form.Control
            type="date"
            name="releaseDate"
            value={date}
            onChange={onChangeDate}
            placeholder="dd/mm/yyyy"
            isInvalid={!!dateError}
            required
          />
          <FormFeedback error={dateError} />
        </FloatingLabel>

        <SubmitBtn
          title={!!releaseDate ? 'Update' : 'Save'}
          onClick={handleSaveDate}
          isProcessing={isSaving}
          isDisabled={isEmpty(date) || !!dateError}
          className="w-full sm:w-1/5"
        />
      </fieldset>

      <div>
        Analysis will start at <b>12 AM UTC</b> on the release date. Once the
        analysis is finished, we will automatically email all attendees with
        links to their personal review pages.
      </div>
    </VBAccordion>
  );
};

export default SetDateStep;
