import config from '../../config';
import { getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const apiKeyClient = () => getApiKeyClient(baseUrl);

export const incrementEmailedMetric = async (metricId) => {
  try {
    await apiKeyClient().put(`/api/video-creation-metrics/${metricId}/emailed`);
  } catch (err) {
    throw new Error(`Error updating video creation metrics: ${err.message}`);
  }
};

export const incrementViewedMetric = async (metricId) => {
  try {
    await apiKeyClient().put(`/api/video-creation-metrics/${metricId}/viewed`);
  } catch (err) {
    throw new Error(`Error updating video creation metrics: ${err.message}`);
  }
};

export const incrementSharedMetric = async (metricId) => {
  try {
    await apiKeyClient().put(`/api/video-creation-metrics/${metricId}/shared`);
  } catch (err) {
    throw new Error(`Error updating video creation metrics: ${err.message}`);
  }
};

export const incrementDownloadedMetric = async (metricId) => {
  try {
    await apiKeyClient().put(
      `/api/video-creation-metrics/${metricId}/downloaded`,
    );
  } catch (err) {
    throw new Error(`Error updating video creation metrics: ${err.message}`);
  }
};
