import React, { useState } from 'react';
import auth0 from 'auth0-js';

import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { isEmpty, validateEmail, validateTsAndCs } from '../../../Utils';

import EmailField from '../../../components/form/emailField';
import FormFeedback from '../../../components/form/formFeedback';
import SubmitBtn from '../../../components/submitBtn';

import config from '../../../config';
import { useAttendeeStore } from '../../../stores/attendee';

import { getTsAndCsText } from './TsAndCsText';

const { featureToggles } = config;

const authenticate = async (webAuth, event, email) => {
  return new Promise((resolve, reject) => {
    webAuth.authorize(
      {
        state: event.eventId,
        connection: 'email',
        nonce: 'nonce',
        login_hint: email,
      },
      (err, authResult) => {
        if (err) {
          console.error(err);
          return reject(err);
        }

        return resolve(authResult);
      },
    );
  });
};

const EmailStage = (props) => {
  const { event } = props;

  const setAttendeeEmail = useAttendeeStore((state) => state.setAttendeeEmail);

  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [isTsAndCs, setIsTsAndCs] = useState(true);

  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const onChange = (e, setValue) => {
    const { type, name, value, checked } = e.target;

    setValue(type === 'checkbox' ? checked : value);
    setErrors({});

    if (name === 'email') setAttendeeEmail(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { emailErr, confirmEmailErr } = validateEmail(
      email,
      isShowConfirm ? confirmEmail : null,
    );
    const { TsAndCsErr } = validateTsAndCs(isTsAndCs);

    // if errors found – set errors and stop here
    if (emailErr || confirmEmailErr || TsAndCsErr) {
      setErrors({ emailErr, confirmEmailErr, TsAndCsErr });
      return;
    }

    // if it's not confirm stage – change to it and stop here
    if (!isShowConfirm) {
      setIsShowConfirm(true);
      return;
    }

    // check in attendee
    const redirectUri = `${
      featureToggles.auth0 ? window.location.origin : ''
    }/complete-check-in?eventId=${event.eventId}`;

    if (featureToggles.auth0) {
      setIsProcessing(true);
      const webAuth = new auth0.WebAuth({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        responseType: 'token id_token',
        scope: 'openid profile email',
        redirectUri: redirectUri,
      });
      return authenticate(webAuth, event, email);
    } else {
      navigate(redirectUri);
    }
  };

  return (
    <Form className="w-full flex flex-col gap-4" onSubmit={onSubmit} noValidate>
      <div className="text-center font-bold">
        Tell us where to send your personalised video
      </div>

      <EmailField
        value={email}
        isInvalid={errors?.emailErr}
        errMsg={errors?.emailErr}
        onChange={(e) => onChange(e, setEmail)}
        required
      />

      {isShowConfirm ? (
        <EmailField
          customLabel="Confirm email*"
          name="confirmEmail"
          value={confirmEmail}
          isInvalid={errors?.confirmEmailErr}
          errMsg={errors?.confirmEmailErr}
          onChange={(e) => onChange(e, setConfirmEmail)}
          required
        />
      ) : (
        <Form.Check id="check-api-checkbox">
          <Form.Check.Input
            checked={isTsAndCs}
            onChange={(e) => onChange(e, setIsTsAndCs)}
            isInvalid={errors?.TsAndCsErr}
          />
          <Form.Check.Label>
            {getTsAndCsText(event.TsAndCsLink, event.organiserName)}
          </Form.Check.Label>
          <FormFeedback error={errors?.TsAndCsErr} />
        </Form.Check>
      )}

      <SubmitBtn
        title="Check-in"
        isProcessing={isProcessing}
        isDisabled={!isEmpty(errors)}
        className="w-full"
      />
    </Form>
  );
};

export default EmailStage;
