import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, validateEmail } from '../../Utils';

import { Button } from '../../components';
import EmailField from '../../components/form/emailField';
import ConfirmModal from '../../components/modals/confirmModal';
import SubmitBtn from '../../components/submitBtn';

import { URL } from '../../constants';

import { checkEmailAvailability } from '../../services/api/auth';
import { sendMsNotification } from '../../services/api/emails';

const MsRequestForm = () => {
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(null);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [showExistingEmail, setShowExistingEmail] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const navigate = useNavigate();

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailErr(null);
    setShowExistingEmail(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let { emailErr } = validateEmail(email);

    if (emailErr) setEmailErr(emailErr);
    else {
      // checks email availability
      checkEmailAvailability(email)
        .then(async (availability) => {
          if (availability === 'existing') setShowExistingEmail(true);
          else {
            setIsEmailSending(true);

            const isSuccess = await sendMsNotification(
              `${email} has requested access to our beautiful Managed Services.`,
            );

            if (isSuccess) setShowConfirmModal(true);
            else toast.error('Something went wrong. Try again later');

            setIsEmailSending(false);
          }
        })
        .catch((err) => {
          toast.error('Something went wrong. Try again later');
        });
    }
  };

  const onLoginClick = () => {
    // store the current page URL in local storage (to redirect back after login)
    const currentPage = window.location.href;
    localStorage.setItem('previousPage', currentPage);

    navigate(URL.LOGIN);
  };

  return (
    <div className="flex flex-col gap-4">
      <h3>Want to try?</h3>

      <Form noValidate onSubmit={onSubmit}>
        <fieldset
          className="flex flex-col sm:flex-row gap-2 sm:gap-4"
          disabled={isEmailSending}
        >
          <EmailField
            className="w-full sm:w-4/5"
            value={email}
            isInvalid={emailErr}
            errMsg={emailErr}
            onChange={(e) => onChangeEmail(e)}
            required
          />

          <SubmitBtn
            title="Request access"
            isProcessing={isEmailSending}
            isDisabled={isEmpty(email)}
            className="w-full sm:w-1/5"
          />
        </fieldset>
      </Form>

      {showExistingEmail && (
        <div className="flex flex-col items-center justify-center p-6 sm:px-[10%] sm:py-16 gap-4 bg-primary-50 text-center rounded-md">
          <h4>You are already a part of CrowdClip&reg; team!</h4>

          <div>Log in to your account</div>

          <Button className="w-44" onClick={onLoginClick}>
            Log In
          </Button>
        </div>
      )}

      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        heading="Thank you, our team will contact you shortly!"
      />
    </div>
  );
};

export default MsRequestForm;
