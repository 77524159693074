import React from 'react';
import classNames from 'classnames';

import { Spinner, Button } from '.';

const SubmitBtn = (props) => {
  const {
    title,
    onClick,
    type,
    variant,
    form,
    className,
    isProcessing,
    isDisabled,
    allowPointerEvents,
    popoverHeader,
    popoverBody,
  } = props;

  return (
    <Button
      onClick={onClick}
      type={type ?? 'submit'}
      variant={variant ?? 'primary'}
      form={form ?? 'rec'}
      className={classNames('relative', className ?? '')}
      disabled={isProcessing || isDisabled}
      allowPointerEvents={allowPointerEvents}
      popoverHeader={popoverHeader}
      popoverBody={popoverBody}
    >
      <div
        className={`absolute transition-opacity duration-500 ${!isProcessing ? 'opacity-100' : 'opacity-0'}`}
      >
        {title}
      </div>

      <Spinner
        size="lg"
        color="white"
        className={`absolute transition-opacity duration-500 ${isProcessing ? 'opacity-100' : 'opacity-0'}`}
      />
    </Button>
  );
};

export default SubmitBtn;
