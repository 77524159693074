import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Modal, Form, FloatingLabel, Dropdown } from 'react-bootstrap';
import { PiUserCircleDuotone, PiCheckBold } from 'react-icons/pi';
import { toast } from 'react-toastify';

import { isEmpty, validateEmail } from '../../../../Utils';

import {
  trackVBAssignModalAddAssignee,
  trackVBAssignModalRemoveAssignee,
  trackVBAssignModalChangeAssess,
  trackVBAssignModalOpenLink,
  trackVBAssignModalCopyLink,
  trackVBAssignModalSaveSuccess,
  trackVBAssignModalSaveError,
} from '../../../../analytics';

import { Button } from '../../../../components';
import FormFeedback from '../../../../components/form/formFeedback';
import LinkBox from '../../../../components/linkBox';
import SubmitBtn from '../../../../components/submitBtn';

import { URL, EMAILS } from '../../../../constants';

import { sendEmail } from '../../../../services/api/emails';
import { setAssigneeData } from '../../../../services/api/mongodb';

import { useEventStore } from '../../../../stores/event';
import { useUserStore } from '../../../../stores/user';

const AssignModal = (props) => {
  const { show, onHide, isProcessing } = props;

  const user = useUserStore((state) => state.user);
  const currentEvent = useEventStore((state) => state.currentEvent);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [existingEmails, setExistingEmails] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [errors, setErrors] = useState([]);

  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  // fill assignee array based on data from database
  useEffect(() => {
    const existingEmails = [];
    const assigneesNew = [];

    const types = [
      { array: 'peopleAssignees', check: 'isPeopleChecked' },
      { array: 'hlReelAssignees', check: 'isHlReelChecked' },
      { array: 'clipsAssignees', check: 'isClipsChecked' },
    ];

    if (isEmpty(currentEvent.assigneeData)) return;

    types.forEach((type) => {
      currentEvent.assigneeData[type.array].forEach((assigneeEmail) => {
        if (!existingEmails.includes(assigneeEmail))
          existingEmails.push(assigneeEmail);

        const foundAssignee = assigneesNew.find(
          (assignee) => assignee.email === assigneeEmail,
        );

        if (foundAssignee) {
          foundAssignee[type.check] = true;
          if (
            foundAssignee.isPeopleChecked &&
            foundAssignee.isHlReelChecked &&
            foundAssignee.isClipsChecked
          )
            foundAssignee.access = 'full';
        } else
          assigneesNew.push({
            email: assigneeEmail,
            access: 'limited',
            [type.check]: true,
          });
      });
    });

    setExistingEmails(existingEmails);
    setAssignees(assigneesNew);
  }, [currentEvent.assigneeData]);

  const handleAddAssignee = (e) => {
    e.preventDefault();

    trackVBAssignModalAddAssignee();

    let { emailErr } = validateEmail(email);

    assignees.forEach((assignee) => {
      if (email === assignee.email)
        emailErr = 'This email address is already added';
    });

    if (emailErr) {
      setEmailErr(emailErr);
      return;
    }

    const assignee = {
      email,
      access: 'full',
      isPeopleChecked: true,
      isHlReelChecked: true,
      isClipsChecked: true,
    };

    setAssignees([...assignees, assignee]);
    setEmail('');
  };

  const onChangeAssignee = (index, e) => {
    const assigneesTemp = [...assignees];

    const { type, name, value, checked } = e.target;

    if (name === 'access') trackVBAssignModalChangeAssess(value);

    assigneesTemp[index] = {
      ...assigneesTemp[index],
      [name]: type === 'checkbox' ? checked : value,
    };

    setAssignees(assigneesTemp);
    setErrors([]);
  };

  const handleRemoveAssignee = (index) => {
    trackVBAssignModalRemoveAssignee();

    const assigneesTemp = [...assignees];
    assigneesTemp.splice(index, 1);
    setAssignees(assigneesTemp);
  };

  const handleSave = async () => {
    let peopleAssignees = [];
    let hlReelAssignees = [];
    let clipsAssignees = [];
    let errorsTemp = [];

    assignees.forEach((assignee) => {
      if (assignee.isPeopleChecked) peopleAssignees.push(assignee.email);
      if (assignee.isHlReelChecked) hlReelAssignees.push(assignee.email);
      if (assignee.isClipsChecked) clipsAssignees.push(assignee.email);

      if (
        !assignee.isPeopleChecked &&
        !assignee.isHlReelChecked &&
        !assignee.isClipsChecked
      )
        errorsTemp.push(
          'No access has been granted. Please either assign some permissions or remove the assignee.',
        );
      else errorsTemp.push('');
    });

    if (errorsTemp.every((err) => err === '')) errorsTemp = [];

    if (!isEmpty(errorsTemp)) {
      setErrors(errorsTemp);
      return;
    }

    try {
      setIsSaving(true);

      await setAssigneeData({
        eventId: currentEvent.eventId,
        peopleAssignees: JSON.stringify(peopleAssignees),
        hlReelAssignees: JSON.stringify(hlReelAssignees),
        clipsAssignees: JSON.stringify(clipsAssignees),
      });

      assignees.forEach(async (assignee) => {
        // if email already existed, it already received email
        if (existingEmails.includes(assignee.email)) return;

        const emailData = {
          recipientEmail: assignee.email,
          templateId: EMAILS.ASSIGNEE_INVITE,
          dynamicTemplateData: {
            name: user.firstName ?? user.email,
            event_name: currentEvent.name,
            share_url: `${window.location.origin}/${currentEvent.eventId}${URL.VB_ASSIGNEE}?email=${assignee.email}`,
          },
        };

        const isSuccess = await sendEmail(emailData);

        if (!isSuccess)
          toast.error(
            <div>
              Email couldn't be sent to <b>{assignee.email}</b>, please try
              again later.
            </div>,
          );
      });

      await updateCurrentEvent();

      toast.success(
        'Permissions have been updated, and emails have been sent to the assignees.',
      );
      trackVBAssignModalSaveSuccess();
    } catch {
      toast.error('Something went wrong. Try again later');
      trackVBAssignModalSaveError();
    } finally {
      setIsSaving(false);
    }
  };

  const accessOptions = [
    {
      value: 'full',
      label: 'Full Access',
      description:
        'Assignees can access people, highlight reels, and clips upload pages.',
    },
    {
      value: 'limited',
      label: 'Limited Access',
      description:
        'Assignees will only have access to the upload pages you’ve selected.',
    },
  ];

  const accessCheckboxes = [
    { name: 'isPeopleChecked', label: 'People' },
    { name: 'isHlReelChecked', label: 'Highlight Reel' },
    { name: 'isClipsChecked', label: 'Clips' },
  ];

  return (
    <Modal show={show} onHide={!isProcessing ? onHide : null} centered>
      <Modal.Body className="flex flex-col px-4 sm:px-8 py-8 gap-4">
        <h5>Assign to people</h5>

        <Form onSubmit={handleAddAssignee} noValidate>
          <div className="flex gap-2">
            <FloatingLabel label="Enter assignee email" className="w-3/4">
              <Form.Control
                type="email"
                name="email"
                value={email ?? ''}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailErr(null);
                }}
                placeholder="name@example.com"
                isInvalid={!!emailErr}
                required
              />

              <FormFeedback error={emailErr} />
            </FloatingLabel>

            <Button variant="secondary" type="submit" className="w-1/4">
              Add
            </Button>
          </div>
        </Form>

        {!isEmpty(assignees) && (
          <div className="flex flex-col gap-4">
            {assignees.map((assignee, index) => (
              <div className="flex flex-col gap-1 sm:gap-0">
                <div className="flex items-center gap-2">
                  <PiUserCircleDuotone size="1.5rem" />

                  <div>{assignee.email}</div>

                  <Dropdown align="end" className="ml-auto">
                    <Dropdown.Toggle
                      variant=""
                      id="access-dropdown"
                      className="text-sm capitalize"
                    >
                      {assignee.access} Access
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-80 text-sm rounded-md">
                      {accessOptions.map((option) => (
                        <Dropdown.Item
                          onClick={() =>
                            onChangeAssignee(index, {
                              target: { name: 'access', value: option.value },
                            })
                          }
                          className="px-3"
                        >
                          <div
                            className={classNames('flex items-center gap-2', {
                              'text-success-900 font-bold':
                                option.value === assignee.access,
                            })}
                          >
                            {option.value === assignee.access ? (
                              <PiCheckBold />
                            ) : (
                              <div className="w-4" />
                            )}
                            <div>{option.label}</div>
                          </div>

                          <div className="pl-6 text-xs text-wrap opacity-50">
                            {option.description}
                          </div>
                        </Dropdown.Item>
                      ))}

                      <Dropdown.Divider />

                      <Dropdown.Item
                        className="px-3"
                        onClick={() => {
                          handleRemoveAssignee(index);
                        }}
                      >
                        <div className="pl-6 text-error-900">Remove</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {assignee.access === 'limited' && (
                  <div className="flex gap-4 text-sm">
                    {accessCheckboxes.map((check) => (
                      <Form.Check
                        id={`${check.label.toLowerCase().replace(/\s+/g, '-')}-checkbox-${index}`}
                      >
                        <Form.Check.Input
                          name={check.name}
                          checked={assignee[check.name]}
                          onChange={(e) => onChangeAssignee(index, e)}
                        />
                        <Form.Check.Label>{check.label}</Form.Check.Label>
                      </Form.Check>
                    ))}
                  </div>
                )}

                <FormFeedback error={errors[index]} className="m-0" />
              </div>
            ))}
          </div>
        )}

        <div className="text-sm italic">
          Your assignees will get an email with a link, giving them access only
          to the upload sections you’ve designated for them.
        </div>

        <hr className="my-1" />

        <h5>Share a link instead</h5>

        <LinkBox
          link={`${window.location.origin}/${currentEvent.eventId}${URL.VB_ASSIGNEE}?accessCode=${moment(currentEvent.created).format('x')}`}
          variant="white"
          handleOpenTrack={trackVBAssignModalOpenLink}
          handleCopyTrack={trackVBAssignModalCopyLink}
        />

        <div className="text-sm italic">
          Anyone with this link will have full access.
        </div>

        <SubmitBtn
          title="Save"
          onClick={handleSave}
          isProcessing={isSaving}
          isDisabled={!isEmpty(errors)}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AssignModal;
