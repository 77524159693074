import { object, string } from 'yup';

let personSchema = object({
  name: string().required('Attendee name is required'),
  email: string().email('Invalid email address'),
});

export const validateForm = async (name, email) => {
  const errors = {};

  await personSchema
    .validate({ name, email }, { abortEarly: false })
    .catch((err) => {
      err.inner.forEach((error) => {
        errors[error.path] = error.errors[0];
      });
    });

  return errors;
};
