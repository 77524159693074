import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { isEmpty, getDemoSelected } from '../../../../Utils';
import { Button } from '../../../../components';
import { DEMO_ID } from '../../../../constants';

import ThumbnailsContainer from '../../components/thumbnailsContainer';

const DemoComponent = (props) => {
  const {
    setClips,
    setUploadingClipsObj,
    setFilesNum,
    handleDemoClips,
    handleUploadStart,
  } = props;

  const eventId = useParams()?.eventId;

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const { isBtnDisabled, popoverBody } = useMemo(() => {
    let isBtnDisabled = false;
    let popoverBody = '';

    if (demoData.isLimited) {
      isBtnDisabled = !isEmpty(getDemoSelected(demoData.clips, true));
      popoverBody = 'Select all clips to upload';
    } else {
      isBtnDisabled = isEmpty(getDemoSelected(demoData.clips));
      popoverBody = 'Select at least 1 clip to upload';
    }

    return { isBtnDisabled, popoverBody };
  }, [demoData.isLimited, demoData.clips]);

  if (!isDemo) return null;

  const imitateUploading = () => {
    const selectedClips = getDemoSelected(demoData.clips);

    handleUploadStart(selectedClips);
    setFilesNum(selectedClips.length);
    setClips([]);

    const interval = setInterval(() => {
      setUploadingClipsObj((prevClips) => {
        const nextClips = { ...prevClips };

        let prc = nextClips[Object.keys(nextClips)[0]].progress;

        if (prc < 100) {
          prc++;

          Object.keys(nextClips).map(
            (id) =>
              (nextClips[id] = {
                ...nextClips[id],
                progress: prc,
                elementId: id,
              }),
          );
        } else {
          // when upload 'complete' – set clips array
          setClips(selectedClips);

          demoData.isClipsUploaded = true;
          localStorage.setItem('demoData', JSON.stringify(demoData));

          clearInterval(interval);
        }

        return nextClips;
      });
    }, 15 * selectedClips.length);

    return () => clearInterval(interval);
  };

  return (
    <React.Fragment>
      <ThumbnailsContainer
        videos={demoData.clips}
        isBig
        isDemo
        handleDemoClips={handleDemoClips}
      />

      <Button
        onClick={imitateUploading}
        className="w-full sm:w-1/2 self-center"
        disabled={isBtnDisabled}
        allowPointerEvents
        popoverHeader="Cannot upload"
        popoverBody={popoverBody}
        hidePopover={!isBtnDisabled}
      >
        Upload
      </Button>
    </React.Fragment>
  );
};

export default DemoComponent;
