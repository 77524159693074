import React, { useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import moment from 'moment';
import { isMobile, isTablet } from 'react-device-detect';
import {
  PiDotsThreeOutlineFill,
  PiShareFatBold,
  PiDownloadSimple,
  PiCopy,
  PiHandCoins,
} from 'react-icons/pi';
import { toast } from 'react-toastify';

import { isEmpty } from '../../Utils';

import {
  trackCreationClickDownload,
  trackCreationDownloadSuccess,
  trackCreationDownloadError,
  trackCreationClickNativeShare,
  trackCreationNativeShareSuccess,
  trackCreationNativeShareError,
} from '../../analytics';

import { Spinner, Button } from '../../components';

import { getGeneratedVideo } from '../../services/api/video';
import {
  incrementDownloadedMetric,
  incrementSharedMetric,
} from '../../services/api/videoCreationMetrics';

import withRouter from '../../withRouter';

import CaptionModal from './captionModal';

const isTouchDevice = isMobile || isTablet;

const VideoDetails = (props) => {
  const { event, person, videoUrl, onClickRemoveWatermark } = props;

  const [savedFile, setSavedFile] = useState(null);

  const [isDownloadingDevice, setIsDownloadingDevice] = useState(false);
  const [isDownloadingBrowser, setIsDownloadingBrowser] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpenDropdown = Boolean(anchorEl);

  const [showShareModal, setShowShareModal] = useState(false);
  const [isCaptionOnlyModal, setIsCaptionOnlyModal] = useState(false);

  if (isEmpty(person)) return null;

  const creation = person.creations[0];

  const handleClickDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  // download video to the device memory
  const handleDownload = async (isShareError = false) => {
    if (!isShareError) trackCreationClickDownload('device');

    try {
      fetch(videoUrl)
        .then((response) => {
          if (isShareError) setIsDownloadingBrowser(true);
          else setIsDownloadingDevice(true);

          return response.blob();
        })
        .then(async (blob) => {
          if (isShareError) setIsDownloadingBrowser(false);
          else setIsDownloadingDevice(false);

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `CrowdClip ${person.face.name} video.mp4`;
          a.click();
          window.URL.revokeObjectURL(url);
          incrementDownloadedMetric(person.metrics.metricId);

          if (!isShareError) trackCreationDownloadSuccess('device');

          toast.success("Downloaded to the device's memory");
        });
    } catch (error) {
      console.error(error);

      if (!isShareError) trackCreationDownloadError('device');
    }
  };

  // show error toast and start video downloading
  const handleSharingError = () => {
    handleDownload(true);

    toast.error(
      'Unfortunately, the video was not shared successfully. As a workaround, the video can be downloaded into the device, ready for you to share.',
      { autoClose: 10000 },
    );
  };

  // download video to browser storage
  const handleBrowserDownload = async () => {
    if (savedFile === null) {
      setIsDownloadingBrowser(true);
      trackCreationClickDownload('browser');

      try {
        const res = await getGeneratedVideo(creation.elementId);
        const currentDate = new Date().valueOf();

        const newFile = new File([res], `crowdclip_${currentDate}.mp4`, {
          type: res.type,
        });
        setSavedFile(newFile);
        trackCreationDownloadSuccess('browser');

        if (!!event.caption) setShowShareModal(true);
        else handleNativeShare('No caption', newFile);
      } catch (err) {
        console.error(err);
        handleSharingError();
        trackCreationDownloadError('browser');
      }

      setIsDownloadingBrowser(false);
    } else {
      if (!!event.caption) setShowShareModal(true);
      else handleNativeShare('No caption');
    }
  };

  // share video via native share
  const handleNativeShare = async (type, newFile) => {
    const file = newFile ?? savedFile;

    try {
      trackCreationClickNativeShare();

      if (file !== null) {
        if (!navigator.share) {
          console.error(
            'Browser does not support sharing, please download the file and share manually.',
          );
          handleSharingError();
          trackCreationNativeShareError();
          return;
        }

        if (!navigator.canShare({ files: [file] })) {
          console.error(
            'File cannot be shared, please download the file and share manually.',
          );
          handleSharingError();
          trackCreationNativeShareError();
          return;
        }

        await navigator
          .share({
            text: 'Check out this video',
            files: [file],
          })
          .catch((error) => {
            // AbortError – user closed the sharing window
            if (!error.toString().includes('AbortError')) {
              console.error('Error sharing:', error);
              handleSharingError();
              trackCreationNativeShareError();
            }
          });

        incrementSharedMetric(person.metrics.metricId);

        trackCreationNativeShareSuccess(type);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const actions = [
    {
      label: 'Download',
      icon: <PiDownloadSimple />,
      onClick: () => handleDownload(),
      isProcessing: isDownloadingDevice,
    },
    {
      label: 'Copy Caption',
      icon: <PiCopy />,
      onClick: () => {
        setShowShareModal(true);
        setIsCaptionOnlyModal(true);
        handleCloseDropdown();
      },
    },
  ];

  const paymentBtn = !event?.paid && !person?.face?.paid && (
    <Button
      onClick={onClickRemoveWatermark}
      variant="black-outline"
      className="text-sm font-bold"
      isThin
      withIcon
    >
      <PiHandCoins size="1.25rem" />
      Remove Watermark
    </Button>
  );

  const renderMobileBtns = () => {
    return (
      <React.Fragment>
        <Button
          onClick={handleBrowserDownload}
          variant="animated-border"
          className="running-glow z-1"
          isThin
          disabled={isDownloadingBrowser}
        >
          <div className="w-full h-full flex items-center justify-center p-2 gap-2 bg-grey-100 group-hover:bg-transparent rounded">
            {isDownloadingBrowser ? (
              <Spinner size="sm" color="primary" hoverColor="white" />
            ) : (
              <PiShareFatBold className="text-[1.25rem] fill-[url('#icon-purple-gradient-2')] group-hover:fill-white" />
            )}

            <div className="text-sm font-bold bg-gradient-purple-light bg-clip-text text-transparent group-hover:text-white">
              Share
            </div>
          </div>
        </Button>

        <Button
          onClick={handleClickDropdown}
          variant="transparent-black"
          isThin
        >
          <PiDotsThreeOutlineFill />
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={isOpenDropdown}
          onClose={handleCloseDropdown}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          className="mt-2"
        >
          {actions.map((action) => (
            <MenuItem onClick={action.onClick} disabled={action.isProcessing}>
              <ListItemIcon className="text-[1.25rem]">
                {action.isProcessing ? (
                  <Spinner size="sm" hoverColor="white" />
                ) : (
                  action.icon
                )}
              </ListItemIcon>

              <ListItemText>
                <div className="text-sm">{action.label}</div>
              </ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  };

  const renderDesktopBtns = () => {
    return (
      <React.Fragment>
        {actions.map((action) => (
          <Button
            onClick={action.onClick}
            variant="black-outline"
            className="text-[1.25rem]"
            isThin
            withIcon
            disabled={action.isProcessing}
          >
            {action.isProcessing ? (
              <Spinner size="sm" hoverColor="white" />
            ) : (
              action.icon
            )}

            <div className="text-sm font-bold">{action.label}</div>
          </Button>
        ))}

        {!isTouchDevice && paymentBtn}
      </React.Fragment>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between gap-4">
        <div className="leading-[2.5rem] whitespace-nowrap">
          {moment(creation.created).fromNow()}
        </div>

        <div className="flex flex-wrap justify-end gap-2 sm:gap-4">
          {isTouchDevice ? renderMobileBtns() : renderDesktopBtns()}
        </div>
      </div>

      {isTouchDevice && paymentBtn}

      <CaptionModal
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
        event={event}
        handleNativeShare={handleNativeShare}
        isCaptionOnly={isCaptionOnlyModal}
      />
    </div>
  );
};

export default withRouter(VideoDetails);
