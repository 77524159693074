import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';

import { isEmpty, getBase64 } from '../../../Utils';

import DraggerSimplified from '../../../components/draggerSimplified';
import SubmitBtn from '../../../components/submitBtn';

import { BRAND_CI_STEPS } from '../../../constants';

import { checkIn } from '../../../services/checkInService';

import DetailsSection from './detailsSection';

const DetailsModal = (props) => {
  const { show, onHide, event, face, isProcessing, handleDetailsSteps } = props;

  const [isPhotoView, setIsPhotoView] = useState(false);
  const [name, setName] = useState(face.name ?? '');
  const [instagram, setInstagram] = useState(face.instagram ?? '');
  const [customFieldData, setCustomFieldData] = useState(
    face.customFieldData ?? '',
  );
  const [selfieFile, setSelfieFile] = useState(null);
  const [selfieUrl, setSelfieUrl] = useState('');

  const onSubmitDetails = async (e) => {
    e.preventDefault();

    try {
      const faceData = {
        elementId: face.elementId,
        name,
        instagram,
        customFieldData,
      };

      // update face details, and handle before and after actions
      handleDetailsSteps(BRAND_CI_STEPS.DETAILS_STARTED);
      await checkIn({ eventId: event.eventId, faceData });
      handleDetailsSteps(BRAND_CI_STEPS.DETAILS_ENDED);

      // if no photos uploaded – go to photo view
      if (isEmpty(face.photos)) setIsPhotoView(true);
      // else – handle no photo actions
      else {
        // hide details modal (as confirm modal will appear)
        onHide();
        handleDetailsSteps(BRAND_CI_STEPS.NO_PHOTO);
      }
    } catch (e) {
      // handle error actions
      handleDetailsSteps(BRAND_CI_STEPS.ERROR, e.message);
    }
  };

  const onSubmitPhoto = async (e) => {
    e.preventDefault();

    try {
      // hide details modal (as confirm modal will appear)
      onHide();

      // upload photo, and handle before and after actions
      handleDetailsSteps(BRAND_CI_STEPS.PHOTO_STARTED);
      await checkIn({
        eventId: event.eventId,
        photo: selfieFile,
        faceId: face.elementId,
      });
      handleDetailsSteps(BRAND_CI_STEPS.PHOTO_ENDED);

      // set view back to details
      setIsPhotoView(false);
    } catch (e) {
      // handle error actions
      handleDetailsSteps(BRAND_CI_STEPS.ERROR, e.message);
    }
  };

  const changeSelfie = (file) => {
    getBase64(file, (url) => setSelfieUrl(url));
    setSelfieFile(file);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body className="flex flex-col items-center px-4 sm:px-8 py-8 gap-4 text-center">
        {!isPhotoView ? (
          <Form
            className="flex flex-col gap-4"
            onSubmit={onSubmitDetails}
            noValidate
          >
            <h5>Fill in your details</h5>

            <DetailsSection
              event={event}
              name={name}
              setName={setName}
              instagram={instagram}
              setInstagram={setInstagram}
              customFieldData={customFieldData}
              setCustomFieldData={setCustomFieldData}
            />

            <SubmitBtn
              title="Submit"
              isProcessing={isProcessing}
              isDisabled={name === ''}
            />
          </Form>
        ) : (
          <Form
            className="flex flex-col gap-4"
            onSubmit={onSubmitPhoto}
            noValidate
          >
            <h5>Upload a photo or a selfie</h5>

            <DraggerSimplified
              type="image"
              heading="Photo upload (1 max)"
              imgUrl={selfieUrl}
              onDrop={changeSelfie}
            />

            <SubmitBtn
              title="Upload"
              isProcessing={isProcessing}
              isDisabled={!selfieFile}
            />
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DetailsModal;
