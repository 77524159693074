import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { goTo, isEmpty, getShareLink, getRandomNumber } from '../../Utils';

import { trackAttendeeGenerationFinish } from '../../analytics';

import { Spinner, ProgressBar } from '../../components';
import ChangingTip from '../../components/changingTip';
import ErrorModal from '../../components/modals/errorModal';
import PageSpinner from '../../components/pageSpinner';

import { getOneCreation } from '../../services/api/creations';
import { getEventPublic } from '../../services/api/events';
import { getAttendeeReviewData } from '../../services/reviewVideoService';

const ReviewVideoGenerating = () => {
  const { faceId } = useParams();

  const [eventId, setEventId] = useState(null);
  const [person, setPerson] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  const generationEstLocal = localStorage.getItem('generationEst') ?? null;
  const [generationEst, setGenerationEst] = useState(generationEstLocal);
  const [remainingMin, setRemainingMin] = useState(0);

  const estMin = 15;

  // handle data fetching and initialization on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      await fetchData();

      // if no generationEst is yet saved in localStorage – set it in 15 min
      if (!generationEst) {
        const estTime = new Date(new Date().getTime() + estMin * 60000);

        localStorage.setItem('generationEst', estTime);
        setGenerationEst(estTime);
        updateRemainingMin(estTime);
      }

      // else – just update remainingMin
      else updateRemainingMin(generationEst);
    };

    onMount();
  }, [faceId]);

  // fetch attendee, event & creation data
  const fetchData = async () => {
    try {
      const { person, eventId } = await getAttendeeReviewData(faceId);
      const event = await getEventPublic(eventId);

      if (isEmpty(person) || isEmpty(event)) {
        setPageError(404);
        return;
      }

      // check if creation is already generated
      const creation = await getOneCreation(faceId);
      if (!!creation) {
        goTo(getShareLink(eventId, faceId));
        return;
      }

      setEventId(eventId);
      setPerson(person);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setError('Error fetching some data');
    }
  };

  // update remaining time for video generation
  const updateRemainingMin = (generationEst) => {
    const now = new Date();
    const generationEstDate = new Date(generationEst);

    let remainingMin = (generationEstDate.getTime() - now.getTime()) / 60000;
    const remainingNum = getRandomNumber(2, 6);

    if (remainingMin <= remainingNum) {
      const extensionNum = getRandomNumber(1, 5);

      // if generationEst already passed, use now as start date
      const startDate = generationEstDate < now ? now : generationEstDate;

      const newEstTime = new Date(startDate.getTime() + extensionNum * 60000);
      remainingMin = (newEstTime.getTime() - now.getTime()) / 60000;

      localStorage.setItem('generationEst', newEstTime);
      setGenerationEst(newEstTime);
    }

    setRemainingMin(remainingMin);
  };

  // re-fetch every 10 seconds
  useEffect(() => {
    const generationInterval = setInterval(async () => {
      const creation = await getOneCreation(faceId);

      // if video is generated – redirect to Share Video page
      if (creation) {
        trackAttendeeGenerationFinish();
        goTo(getShareLink(eventId, faceId));
        clearInterval(generationInterval);
      }

      // else – update est time remaining
      else updateRemainingMin(generationEst);
    }, 10000);

    return () => clearInterval(generationInterval);
  }, [eventId, faceId, generationEst]);

  const progress = useMemo(() => {
    return Math.round(((estMin - remainingMin) / estMin) * 100);
  }, [remainingMin]);

  const tipsList = [
    'Your video is being assembled in the cosmic workshop. Just a few more lightyears!',
    'Stitching together your interstellar memories—almost ready for launch!',
    'Your video is orbiting the digital universe and will land shortly',
    'Our space engineers are fine-tuning your video at warp speed. Hold tight!',
    'Creating your masterpiece among the stars. It’ll be worth the wait!',
    'The countdown has begun. Your video is fueling up for the final launch!',
    'Gathering cosmic dust to sprinkle some magic on your video. Almost there!',
    'Your video is passing through a wormhole of creativity. Hang in there!',
    'Our satellites are compiling your video footage from across the galaxy!',
    'Your video is on a mission to become out of this world. Preparing for re-entry!',
  ];

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title="Generating Your Video"
      noTips
      isPageContainer
      className="h-[calc(100vh_-_2*var(--header-height))] p-0 items-center justify-center"
    >
      <div className="w-full sm:w-2/3 flex flex-col gap-4 items-center text-center">
        <Spinner customSize="w-20 h-20" color="primary" />

        <h4>Generating your video</h4>

        <div>This might take a bit. Feel free to check back later!</div>

        <ProgressBar
          progress={progress}
          fileName={`${person?.face?.name} personalised video`}
          estTime={Math.round(remainingMin)}
          isThin
          isBoxed
        />

        <ChangingTip tipsList={tipsList} />

        <hr />

        <div className="text-sm italic">
          Contact us at{' '}
          <a href="mailto:hello@crowdclip.com">hello@crowdclip.com</a> for
          further assistance.
        </div>
      </div>

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
        isContactUs
      />
    </PageSpinner>
  );
};

export default ReviewVideoGenerating;
