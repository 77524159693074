import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export const useAttendeeStore = create(
  subscribeWithSelector((set, get) => ({
    face: null,
    bearerToken: null,
    attendeeEmail: null,
    setBearerToken: (bearerToken) => {
      set((state) => ({ ...state, bearerToken }));
    },
    setFace: (face) => {
      set((state) => ({ ...state, face }));
    },
    setAttendeeEmail: (attendeeEmail) => {
      set((state) => ({ ...state, attendeeEmail }));
    },
  })),
);
