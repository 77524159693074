import React, { useState, useMemo } from 'react';

import { isEmpty } from '../../../../Utils';

import PageSubheader from '../../../../components/pageSubheader';

import ThumbnailsContainer from '../../components/thumbnailsContainer';

const FailedClips = (props) => {
  const { timedOutClips, errorClips, deletingClips, deleteClip } = props;

  const [isCollapsedTimedOut, setIsCollapsedTimedOut] = useState(true);

  const hasGroups = useMemo(() => {
    return !isEmpty(timedOutClips) && !isEmpty(errorClips);
  }, [errorClips, timedOutClips]);

  if (isEmpty(timedOutClips) && isEmpty(errorClips)) return null;

  return (
    <div className="w-full flex flex-col gap-4">
      <PageSubheader heading="Analysis failed" />

      {!isEmpty(errorClips) && (
        <div className="flex flex-col gap-2">
          {hasGroups && <div className="font-bold">Couldn't process</div>}

          <ThumbnailsContainer
            videos={errorClips}
            deletingClips={deletingClips}
            deleteClip={deleteClip}
          />
        </div>
      )}

      {!isEmpty(timedOutClips) && (
        <div className="flex flex-col gap-2">
          {hasGroups && <div className="font-bold">Timed out</div>}

          <ThumbnailsContainer
            videos={timedOutClips}
            isCollapsed={isCollapsedTimedOut}
            onClickShowMoreBtn={() =>
              setIsCollapsedTimedOut(!isCollapsedTimedOut)
            }
            deletingClips={deletingClips}
            deleteClip={deleteClip}
          />
        </div>
      )}
    </div>
  );
};

export default FailedClips;
