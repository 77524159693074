import React from 'react';
import parse from 'html-react-parser';
import { Modal } from 'react-bootstrap';

import { isEmpty, updateEmailText } from '../../../../Utils';

import { User, Event, SGTemplate } from '../../../../types';

const EmailPreviewModal = (props: {
  show: boolean;
  onHide: () => void;
  template: SGTemplate;
  event: Event;
  user: User;
  customText?: string;
}) => {
  const { show, onHide, template, event, user, customText } = props;

  if (isEmpty(event) || isEmpty(template)) return null;

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header className="px-4 sm:px-8" closeButton>
        <Modal.Title>
          <b>Subject</b>: {updateEmailText(template.subject, event, user)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-0">
        {parse(updateEmailText(template.html_content, event, user, customText))}
      </Modal.Body>

      <Modal.Footer className="justify-start px-4 sm:px-8">
        <div className="text-sm italic">
          Please note that all links (if existing) in the preview are
          substituted with www.crowdclip.com. The emails your attendees receive
          will include all relevant links.
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailPreviewModal;
