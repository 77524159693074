import ReactGA from 'react-ga4';

export const initializeTracker = (config, userId) => {
  ReactGA.initialize(config.googleAnalyticsTagID, { userId: userId });
};

export const trackPageView = (location) => {
  ReactGA.send({
    hitType: 'pageview',
    page: location.pathname + location.search,
    title: document.title,
  });
};

export const unsetUserID = () => {
  ReactGA.set({ userId: undefined });
};

export const setUserID = (userId) => {
  ReactGA.set({ userId: userId });
};
