import { v4 as uuidv4 } from 'uuid';

import { privateFileCreate, attendeeFileCreate } from './api/files';
import { Uploader } from './api/upload';

export const uploadFile = async ({
  file,
  eventId,
  fileKind = null,
  parentElementId = null,
  isPublic = false,
  onProgress = () => {},
}) => {
  return new Promise((resolve, reject) => {
    const fileId = uuidv4();

    const uploader = new Uploader({ fileId, file, isPublic });

    const formData = new FormData();

    const uploadRequestId = uuidv4();
    formData.append('uploadRequestId', uploadRequestId);

    const files = [];

    files.push({
      elementId: fileId,
      eventId,
      fileKind,
      name: file.name.replace(/'/g, ''),
      uploadSessionId: uuidv4(),
      uploadRequestId,
      size: file.size,
      progress: file.size,
      parentElementId,
      progressCompleted: new Date().toISOString(),
      mediaStoreCompleted: new Date().toISOString(),
    });

    formData.append('files', file);

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        const progressObj = {
          id: file.uid,
          elementId: fileId,
          parentElementId,
          name: file.name,
          progress: newPercentage,
        };
        onProgress && onProgress(progressObj);
      })
      .onComplete(async () => {
        try {
          if (isPublic) await attendeeFileCreate(files);
          else await privateFileCreate(files);

          resolve();
        } catch (err) {
          reject(err);
        }
      })
      .onError((error) => {
        reject(error);
      });

    uploader.start();
  });
};
