import moment from 'moment';
import ReactGA from 'react-ga4';

import { getDemoSuffix } from '../Utils';

export const trackCreateProject = (source) => {
  ReactGA.event({
    category: 'User',
    action: `click_create_project`,
    label: source,
  });
};

export const trackOpenProject = (source, event) => {
  ReactGA.event({
    category: 'User',
    action: `open_project_from_${source}`,
    label: `${event.eventId} – ${event.name} (${event.type})`,
  });
};

export const trackEditProject = (source) => {
  ReactGA.event({
    category: 'User',
    action: 'click_edit_project',
    label: source,
  });
};

export const trackEditProjectChangeImg = (type) => {
  ReactGA.event({
    category: 'User',
    action: `edit_project_change_${type}`,
  });
};

export const trackUpdateProjectSuccess = () => {
  ReactGA.event({
    category: 'User',
    action: 'update_project_success',
  });
};

export const trackUpdateProjectError = () => {
  ReactGA.event({
    category: 'User',
    action: 'update_project_error',
  });
};

export const trackProjectComplete = (event) => {
  const timestampMills = moment(new Date()).valueOf();

  ReactGA.event({
    category: 'User',
    action: `project_complete${getDemoSuffix(event)}`,
    label: `${event.eventId} – ${event.name} (${event.type})`,
    value: timestampMills,
  });
};

export const trackFirstProjectComplete = (event) => {
  const timestampMills = moment(new Date()).valueOf();

  ReactGA.event({
    category: 'User',
    action: `first_project_complete${getDemoSuffix(event)}`,
    label: `${event.eventId} – ${event.name} (${event.type})`,
    value: timestampMills,
  });
};

export const trackCheckInQRDownload = (source) => {
  ReactGA.event({
    category: 'User',
    action: 'check_in_qr_download',
    label: source,
  });
};
