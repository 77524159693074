import React from 'react';
import { PiUserRectangleFill } from 'react-icons/pi';

import { round } from '../../Utils';
import PopoverTrigger from '../popoverTrigger';

const TemplatePlaceholder = ({
  duration,
  popoverBody,
  hidePopover,
  addClass,
}) => {
  return (
    <PopoverTrigger
      content={
        <div
          className={`${addClass} flex flex-col items-center justify-center bg-grey-100 rounded-md border-1 border-grey-200 border-dashed`}
        >
          <PiUserRectangleFill size="2.5rem" className="hidden sm:block" />
          <div>WILDCARD</div>
          {duration && <div className="text-xs">{round(duration)} seconds</div>}
        </div>
      }
      delay={{ show: 300, hide: 0 }}
      placement="top"
      popoverBody={
        popoverBody || 'The personalised attendee clip will be inserted here'
      }
      hidePopover={hidePopover}
    />
  );
};

export default TemplatePlaceholder;
