import moment from 'moment';
import { object, string } from 'yup';

let schema = object({
  name: string().required('Name is required'),
  email: string().required('Email is required').email('Invalid email address'),
  date: string().test({
    name: 'test-date',
    test(value, ctx) {
      let now = moment().utc();

      if (!value) return ctx.createError({ message: 'Event date is required' });
      if (moment(value).isBefore(now, 'day'))
        return ctx.createError({
          message: 'Event date cannot be earlier than current date',
        });
      return true;
    },
  }),
  numOfPeople: string().required('Number of people is required'),
  msg: string().required('Message is required'),
});

export const validateForm = async (formData) => {
  const errors = {};

  await schema.validate(formData, { abortEarly: false }).catch((err) => {
    err.inner.forEach((error) => {
      errors[error.path] = error.errors[0];
    });
  });

  return errors;
};
