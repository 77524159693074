import ReactGA from 'react-ga4';

export const trackCheckInEnterEmailSuccess = (label) => {
  ReactGA.event({
    category: 'Attendee',
    action: 'check_in_enter_email_success',
    label,
  });
};

export const trackCheckInEnterEmailError = (errMsg) => {
  ReactGA.event({
    category: 'Attendee',
    action: 'check_in_enter_email_error',
    label: errMsg,
  });
};

export const trackCheckInUpdatePersonSuccess = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'check_in_update_person_success',
  });
};

export const trackCheckInUpdatePersonError = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'check_in_update_person_error',
  });
};

export const trackCheckInUploadFileSuccess = (type) => {
  ReactGA.event({
    category: 'Attendee',
    action: `check_in_upload_${type}_success`,
  });
};

export const trackCheckInUploadFileError = (type, fileName) => {
  ReactGA.event({
    category: 'Attendee',
    action: `check_in_upload_${type}_error`,
    label: fileName,
  });
};

export const trackCheckInBatchUploadVideoSuccess = (count) => {
  ReactGA.event({
    category: 'Attendee',
    action: 'check_in_batch_upload_video_success',
    label: count,
  });
};

export const trackCheckInDetailsModalClickYes = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'check_in_details_modal_click_yes',
  });
};

export const trackCheckInDetailsModalClickNo = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'check_in_details_modal_click_no',
  });
};
