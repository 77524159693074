import React from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from '../../../../components';
import LinkBox from '../../../../components/linkBox';

const ShareHubModal = (props) => {
  const { show, onHide, hubLink, isDemo } = props;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body className="flex flex-col items-center p-8 gap-4 text-center">
        <h4>Your Project Share Hub</h4>

        {isDemo ? (
          <div>
            This is an <b>example page</b> and it doesn't reflect the choices
            you've made before.
          </div>
        ) : (
          <div>
            This is a shareable page for this project, all personalised
            highlight reels are visible here.
          </div>
        )}

        <LinkBox type="hub" link={hubLink} variant="white" addClass="w-full" />

        <Button className="w-1/2" onClick={onHide}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ShareHubModal;
