import React from 'react';
import { Modal } from 'react-bootstrap';

import { trackUGModalClose, trackUGModalSelectRole } from '../../analytics';

import { UG_ID } from '../../constants';

import Logo from '../logo';

const UserGuidingModal = (props) => {
  const { show, onHide } = props;

  const onBtnClick = (type) => {
    onHide();

    trackUGModalSelectRole(type);

    const guideId =
      type === 'marketer'
        ? UG_ID.ONBOARDING_MARKETER
        : UG_ID.ONBOARDING_VIDEOGRAPHER;

    window.userGuiding.previewGuide(guideId);
  };

  const onClose = () => {
    onHide();

    trackUGModalClose();
  };

  const btnClass =
    'w-4/5 sm:w-1/2 h-[var(--form-el-height)] px-3 py-1.5 bg-white text-md sm:text-lg text-black rounded-md border-2 border-white border-solid hover:opacity-80';

  return (
    <Modal
      size="lg"
      className="text-white"
      show={show}
      onHide={onClose}
      centered
    >
      <Modal.Body className="flex flex-col items-center md:p-12 gap-y-4 sm:gap-y-7 text-center bg-cc-purple-700 rounded-md">
        <h1 className="text-xl sm:text-2xl leading-10">
          Welcome to{' '}
          <Logo isWhite width="140px" className="align-middle inline-block" />
        </h1>

        <div className="text-lg sm:text-xl">
          <b>Before you begin!</b> Tell us about yourself.
          <br />
          What kind of work do you do?
        </div>

        <div className="w-full sm:w-4/5 flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-8">
          <button className={btnClass} onClick={() => onBtnClick('marketer')}>
            Marketer / Event Organiser
          </button>

          <button
            className={btnClass}
            onClick={() => onBtnClick('videographer')}
          >
            Videographer
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserGuidingModal;
