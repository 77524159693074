import axios from 'axios';
import config from '../../../config';

const { apiBaseUrl } = config;

export const getHttp = () => {
  return axios.create({
    baseURL: apiBaseUrl.bffApi,
  });
};
