import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { PiDownloadSimple } from 'react-icons/pi';

import { useEventStore } from 'stores/event';
import { isEmpty, getThumbnail } from '../../../../Utils';

import {
  trackShareDownload,
  trackShareCreationShare,
} from '../../../../analytics';

import { Spinner } from '../../../../components';
import EmailSendGroup from '../../../../components/form/emailSendGroup';
import LinkBox from '../../../../components/linkBox';
import { downloadVideo } from '../../../../components/videoDownload';

const ShareFaceBlock = (props) => {
  const {
    event,
    person,
    peopleEmails,
    setPeopleEmails,
    afterEmailSend,
    isDemo,
    isIncreased,
  } = props;

  const currentEvent = useEventStore((state) => state.currentEvent);

  const [isDownloading, setIsDownloading] = useState(false);

  const { face, creation, metrics } = useMemo(() => {
    return {
      face: person?.face ?? {},
      creation: person.creations[0] ?? {},
      metrics: person?.metrics ?? {},
    };
  }, [person]);

  if (isEmpty(person) || isEmpty(peopleEmails)) return null;

  const handleDownload = async () => {
    setIsDownloading(true);

    await downloadVideo(person.videoUrl, face.name);

    trackShareDownload('creation', currentEvent);

    setIsDownloading(false);
  };

  const handleAfterEmailSend = () => {
    afterEmailSend();

    trackShareCreationShare(
      metrics.emailedCount > 0 ? 'Re-share' : 'First time',
    );
  };

  const videoWidth = classNames({
    'w-full sm:w-[calc((var(--page-width)_-_(2rem_*_2))_/_3)]': !isIncreased,
    'w-full sm:w-[calc((var(--page-width)_-_2rem)_/_2)]': isIncreased,
  });

  const videoHeight = classNames({
    'h-[calc(90vw_*_9_/_16)] sm:h-[calc((var(--page-width)_-_(2rem_*_2))_/_3_*_9_/_16)]':
      !isIncreased,
    'h-[calc(90vw_*_9_/_16)] sm:h-[calc((var(--page-width)_-_2rem)_/_2_*_9_/_16)]':
      isIncreased,
  });

  return (
    <div
      className={`relative ${videoWidth} flex flex-col items-center gap-4 text-start`}
    >
      <video
        id="video"
        className={`${videoWidth} ${videoHeight} bg-black rounded-md object-contain`}
        src={person.videoUrl}
        poster={creation.frameId ? getThumbnail(creation.frameId) : null}
        controls
      />

      <div className="w-full flex items-center gap-4">
        <img
          src={face.img}
          className="w-10 min-w-10 h-10 rounded-full object-cover"
          alt={`${face.name} Icon`}
        />

        <h5 className="m-0">{face.name}</h5>

        <div className="flex ml-auto">
          <LinkBox
            type="creation"
            link={peopleEmails[face.elementId].videoLink}
            variant="grey"
            btnOnly
          />

          <button
            className="group flex items-center justify-center p-2 text-[1.25rem] rounded-md cursor-pointer hover:bg-black hover:text-white disabled:opacity-65"
            onClick={handleDownload}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <Spinner size="sm" hoverColor="white" />
            ) : (
              <PiDownloadSimple />
            )}
          </button>
        </div>
      </div>

      <div className="w-full flex flex-col gap-2">
        <EmailSendGroup
          type="individual-creation"
          event={event}
          face={face}
          faceEmails={peopleEmails}
          setFaceEmails={setPeopleEmails}
          afterEmailSend={handleAfterEmailSend}
          btnText="Share"
          className="w-full max-w-full"
          isDemo={isDemo}
        />

        {!isDemo && (
          <div className="flex justify-between gap-2 sm:mt-[-0.25rem] text-sm italic opacity-80">
            <div>Emailed: {metrics.emailedCount ?? 0}</div>
            <div>Played: {metrics.viewedCount ?? 0}</div>
            <div>Downloaded: {metrics.downloadedCount ?? 0}</div>
            <div>Shared: {metrics.sharedCount ?? 0}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShareFaceBlock;
