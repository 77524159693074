import React, { useState } from 'react';
import parse from 'html-react-parser';
import { Collapse, FloatingLabel, Form } from 'react-bootstrap';
import { PiCaretUp, PiCaretDown } from 'react-icons/pi';
import { toast } from 'react-toastify';

import ContentHeader from '../../components/contentHeader';
import ConfirmModal from '../../components/modals/confirmModal';
import PageContainer from '../../components/pageContainer';
import SubmitBtn from '../../components/submitBtn';

import { sendMsNotification } from '../../services/api/emails';

import withRouter from '../../withRouter';

import { getHelpContent } from './helpContent';

const Help = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [requestText, setRequestText] = useState('');
  const [infoOpen, setInfoOpen] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contentBlocks = getHelpContent();

  const [openedFAQs, setOpenedFAQs] = useState(
    contentBlocks.map((_, index) => index),
  );

  const onFaqClick = (index) => {
    // By default, all FAQs are opened
    setOpenedFAQs((prevOpenedFAQs) => {
      if (prevOpenedFAQs.includes(index)) {
        // Remove index if it's already in the array
        return prevOpenedFAQs.filter((i) => i !== index);
      } else {
        // Add index to the array
        return [...prevOpenedFAQs, index];
      }
    });
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const text =
      'You have a new <b>Support Request</b> from the following user:<br />' +
      `<b>User</b>: ${email}<br />` +
      `<b>Name</b>: ${name}<br /><br />` +
      requestText;

    const isSuccess = await sendMsNotification(
      'Support Request',
      text.replace(/\n/g, '<br />'),
      ['naomi@crowdclip.com'],
    );

    if (isSuccess) setShowConfirmModal(true);
    else toast.error('Something went wrong. Try again later');

    setIsLoading(false);
  };

  const renderAnswerContent = (block) => {
    const content = block.content;

    switch (block.type) {
      case 'text':
        return <div>{parse(content)}</div>;

      case 'note':
        return <div className="text-sm italic">{parse(content)}</div>;

      case 'few-blocks':
        return (
          <div className="flex flex-col sm:flex-row gap-8">
            {content.map((option) => (
              <div className="w-full sm:w-1/2 flex flex-col gap-2">
                {option.text && (
                  <div className="text-center">{parse(option.text)}</div>
                )}

                {option.video && (
                  <video
                    className="w-[60%] sm:w-[45%] mx-auto"
                    src={option.video}
                    controls
                  />
                )}

                {option.items && (
                  <ol className="w-full flex flex-col self-center m-0 gap-2 sm:gap-4 list-decimal">
                    {option.items.map((item) => (
                      <li>{parse(item)}</li>
                    ))}
                  </ol>
                )}
              </div>
            ))}
          </div>
        );

      case 'element':
        return content;

      default:
        return null;
    }
  };

  return (
    <PageContainer title="Help" addClass="gap-6">
      <ContentHeader title="Help Page" />

      <div className="flex flex-col gap-3">
        <div>
          <h3 className="inline-block">Sharing Generated Videos</h3>

          <div
            className="inline-block ml-2"
            onClick={() => setInfoOpen(!infoOpen)}
            aria-controls="collapse-wrapper"
            aria-expanded={infoOpen}
          >
            <div className="w-6 h-6 flex items-center justify-center bg-true-black text-white rounded-full cursor-pointer hover:bg-primary-900">
              {infoOpen ? <PiCaretUp /> : <PiCaretDown />}
            </div>
          </div>
        </div>

        <div>
          You may encounter known issues when attempting to share your newly
          generated videos using a mobile device. As a workaround, you may
          download and share manually.
        </div>

        <Collapse in={infoOpen}>
          <div id="collapse-wrapper">
            <div className="p-4 rounded-md border-1 border-grey-900 border-solid">
              {contentBlocks.map((faq, index) => {
                const isOpened = openedFAQs.includes(index);
                return (
                  <React.Fragment>
                    <div className="flex flex-col gap-4">
                      <div
                        className="flex items-center justify-between gap-2 font-bold cursor-pointer hover:text-primary-900"
                        onClick={() => onFaqClick(index)}
                      >
                        <div>{parse(faq.question)}</div>
                        <div className="text-[1.1rem] leading-none">
                          {isOpened ? <PiCaretUp /> : <PiCaretDown />}
                        </div>
                      </div>

                      <Collapse in={isOpened}>
                        <div id="collapse-wrapper">
                          <div className="flex flex-col gap-4">
                            {faq.answer.map((block) =>
                              renderAnswerContent(block),
                            )}
                          </div>
                        </div>
                      </Collapse>
                    </div>

                    {index !== contentBlocks.length - 1 && <hr />}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </Collapse>
      </div>

      <div className="flex flex-col p-4 gap-4 bg-grey-100 rounded-md border-1 border-grey-900 border-solid">
        <h2 className="text-center">Support Request Form</h2>

        <div>
          We're here to help whenever you encounter an issue or have questions.
          Complete the Support Request Form to contact the CrowdClip&reg;
          support team and we will endeavour to assist you within 48 hours.
        </div>

        <Form onSubmit={onSubmit} noValidate>
          <fieldset disabled={isLoading}>
            <div className="flex flex-col gap-2">
              <h5>Name</h5>

              <FloatingLabel label="Full name">
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Please enter a full name"
                  required
                />
              </FloatingLabel>
            </div>

            <div className="flex flex-col gap-2">
              <h5>Email</h5>

              <FloatingLabel label="Email">
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Please enter your email address"
                  required
                />
              </FloatingLabel>
            </div>

            <div className="flex flex-col gap-2">
              <h5>How can we help?</h5>

              <FloatingLabel label="Tell us more">
                <Form.Control
                  as="textarea"
                  value={requestText}
                  onChange={(e) => setRequestText(e.target.value)}
                  placeholder="Tell use more"
                  required
                  style={{ height: '100px' }}
                />
              </FloatingLabel>
            </div>

            <SubmitBtn
              title="Submit"
              isProcessing={isLoading}
              isDisabled={name === '' || email === '' || requestText === ''}
              className="w-1/2 sm:w-full self-center"
            />
          </fieldset>
        </Form>
      </div>

      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        heading="Thank you, our team will contact you shortly!"
      />
    </PageContainer>
  );
};

export default withRouter(Help);
