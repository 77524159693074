import ShareVideo_Android from '../../assets/share-video-android.mp4';
import ShareVideo_iOS from '../../assets/share-video-ios.mp4';
import ShareVideo_Manually from '../../assets/share-video-manually.mp4';

export const getHelpContent = () => {
  const content = [
    {
      question: 'Are you using an iOS Device?',
      answer: [
        {
          type: 'text',
          content:
            'For best results, <b>use a Safari web browser on an iOS device.</b> Manually download and share your video using an iOS device to share on social media platforms.',
        },
        {
          type: 'few-blocks',
          content: [
            {
              text: '<b>Share Instruction iOS</b>',
              video: ShareVideo_iOS,
            },
            {
              text: '<b>Instruction Checklist</b>',
              items: [
                'On the web app, click <b>Share</b>',
                'Click <b>Download</b>',
                'Copy Caption',
                'Click <b>Share</b>',
                'Click <b>Save Video</b>',
                'Close web app',
                'Open <b>Instagram</b> app',
                'Select [+]',
                'Select <b>Reel</b>',
                'Select <b>video</b>  from your gallery',
                'Click <b>Next</b>',
                'Paste captions',
                'Click <b>Share</b>',
              ],
            },
          ],
        },
      ],
    },
    {
      question: 'Are you using an Android Device?',
      answer: [
        {
          type: 'text',
          content:
            'For best results, <b>use a Chrome web browser on an Android device.</b> Manually download and share your video using an Android device to share on social media platforms.',
        },
        {
          type: 'few-blocks',
          content: [
            {
              text: '<b>Share Instruction Android</b>',
              video: ShareVideo_Android,
            },
            {
              text: '<b>Instruction Checklist</b>',
              items: [
                'On the web app, click <b>Share</b>',
                '<b>Copy</b> Caption',
                'Close prompt',
                'Long press on the <b>video image</b> to Download',
                'For best results, keep video length at one minute or less',
                'Open the <b>Instagram</b> app',
                'Select [+]',
                'Select <b>Reel</b>',
                'Select <b>video</b> from your gallery',
                'Click <b>Next</b>',
                'Paste captions',
                'Click <b>Share</b>',
              ],
            },
          ],
        },
      ],
    },
    {
      question: 'Are you sharing on Instagram?',
      answer: [
        {
          type: 'text',
          content:
            '<b>Manually draft and share your video</b> on Instagram using a mobile device.',
        },
        {
          type: 'note',
          content:
            'Note that there is a known issue for some OS preventing users from sharing to Instagram automatically.',
        },
        {
          type: 'few-blocks',
          content: [
            {
              text: '<b>Share Instruction Instagram</b>',
              video: ShareVideo_Manually,
            },
            {
              text: '<b>Instruction Checklist</b>',
              items: [
                'Click <b>Share</b>',
                'Click <b>Download</b>',
                'Click <b>Share</b>',
                'Select <b>Instagram icon</b>',
                'Click <b>Save Draft</b>',
                'Open the <b>Instagram</b> app',
                'Select Instagram Reels <u>feed</u>',
                'Open Drafts',
                'Select content/post',
                '<b>Share</b> to Reels',
              ],
            },
          ],
        },
      ],
    },
  ];

  return content;
};
