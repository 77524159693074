import React, { useState, useEffect } from 'react';

import { goTo } from '../../Utils';

import { trackPaymentSuccess, trackPaymentError } from '../../analytics';

import ErrorModal from '../../components/modals/errorModal';
import PageSpinner from '../../components/pageSpinner';

import { URL, STRIPE_PRODUCTS } from '../../constants';

import { retrieveSession } from '../../services/api/stripe';

import Error from './error';

const PaymentRedirect = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get('type');
  const sessionId = queryParameters.get('sessionId');

  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getSession = async () => {
      const gaLabel =
        type.charAt(0).toUpperCase() + type.slice(1).replace(/-/g, ' ');

      try {
        const session = await retrieveSession(sessionId);
        const referenceId = session.client_reference_id;

        if (type === STRIPE_PRODUCTS.REMOVE_WATERMARK_PROJECT) {
          trackPaymentSuccess(gaLabel);
          goTo(`/${referenceId}${URL.VB_SHARE}?payment_success=true`);
        } else if (type === STRIPE_PRODUCTS.REMOVE_WATERMARK_ATTENDEE) {
          trackPaymentSuccess(gaLabel);
          goTo(
            `${URL.SHARE}${referenceId.replace(/_/g, '/')}?payment_success=true`,
          );
        } else setPageError(404);
      } catch (error) {
        setError("We're having trouble verifying your payment with Stripe");
        trackPaymentError(gaLabel);
      }
    };

    getSession();
  }, [sessionId, type]);

  if (!!pageError) return <Error code={pageError} />;

  return (
    <React.Fragment>
      <PageSpinner isLoading noTips isFullPage />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
        isContactUs
      />
    </React.Fragment>
  );
};

export default PaymentRedirect;
