import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import { sendMobileReminder } from '../../../UtilsEmail';

import SellingPoint from '../../../components/sellingPoint';

import { useUserStore } from '../../../stores/user';

const MobileReminderBox = () => {
  const user = useUserStore((state) => state.user);

  if (!isMobile && !isTablet) return null;

  return (
    <SellingPoint
      text={
        <>
          Continue your project on a desktop computer?{' '}
          <span className="underline" onClick={() => sendMobileReminder(user)}>
            Click here
          </span>{' '}
          and we'll email you a link for easy access.
        </>
      }
    />
  );
};

export default MobileReminderBox;
