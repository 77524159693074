import React from 'react';

import { useEventStore } from '../../../../stores/event';

import VBAccordion from './vbAccordion';

const WaitStep = (props) => {
  const { isAssetsUploaded, isVideosGenerated } = props;

  const currentEvent = useEventStore((state) => state.currentEvent);

  return (
    <VBAccordion
      heading="Wait"
      subheading={
        !isVideosGenerated
          ? 'Wait for the release date to arrive, and the videos will be generated'
          : 'Analysis and video generation have been successfully completed'
      }
      progressText={isVideosGenerated ? 'Completed' : 'In Progress'}
      isOpen={!isVideosGenerated}
      isDisabled={
        !isAssetsUploaded || !currentEvent.attendeeReviewData?.releaseDate
      }
    >
      <div>
        On release day, your assets will be automatically analysed, and all
        attendees will receive an email with a link to their review page.
      </div>

      <div>
        Please check back later — once the first video is generated, you’ll gain
        access to our ‘Share’ page, where you can view the generated videos and
        event statistics.
      </div>

      {currentEvent.stage === 'upload' && (
        <div>
          You can upload additional assets and update the release date before it
          comes up and the analysis begins, but make sure to notify your
          attendees about the deadline change.
        </div>
      )}
    </VBAccordion>
  );
};

export default WaitStep;
