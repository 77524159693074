import config from '../../config';

import { getAccessTokenClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);

export const analyseAll = async (eventId) => {
  try {
    await accessTokenClient().get(`api/analyse/${eventId}`);
    return { success: true };
  } catch (err) {
    if (err.response.status === 503)
      return {
        errMsg: err.response?.data ?? 'Something went wrong. Try again later',
      };
    throw new Error('Error analysing assets', err);
  }
};
