import React from 'react';
import classNames from 'classnames';
import { PiUserRectangleFill } from 'react-icons/pi';

import { round } from '../Utils';
import templateDiagram from '../assets/template-diagram.png';

const templateElements = [
  { type: 'selects', text: 'Selects', duration: 8 },
  { type: 'wildcard', text: 'Wildcard', duration: 5 },
  { type: 'selects', text: 'Selects', duration: 7 },
  { type: 'wildcard', text: 'Wildcard', duration: 2 },
  { type: 'selects', text: 'Selects', duration: 11 },
  { type: 'bookend', text: 'Outro', duration: 4 },
];

const TemplateExample = (props) => {
  const { customElements, showClipsExample } = props;

  const elements = customElements || templateElements;

  if (showClipsExample)
    return (
      <img
        src={templateDiagram}
        className="w-full max-h-40"
        alt="Template diagram"
      />
    );

  return (
    <div
      className={classNames(
        'w-full flex items-center justify-center mx-auto gap-2',
        { 'flex-wrap': window.innerWidth < 640 },
      )}
    >
      {elements.map((el) => {
        const blockClass = classNames({
          'flex flex-col items-center justify-center p-1 rounded-md': true,
          'h-12 bg-grey-900 text-white': el.type === 'selects',
          'h-16 bg-grey-100 border-1 border-black border-solid':
            el.type === 'wildcard',
          'h-10 bg-[#999] text-white': el.type === 'bookend',
        });

        return (
          <div
            className={blockClass}
            style={{
              width: `${(100 / elements.length) * (window.innerWidth < 640 ? 1.5 : 1)}%`,
            }}
          >
            {el.type === 'wildcard' && <PiUserRectangleFill size="1rem" />}

            <div className="text-xs font-bold">{el.text}</div>

            <div className="text-[0.6rem]">
              {round(el.duration)}{' '}
              {window.innerWidth >= 480 ? 'seconds' : 'sec'}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TemplateExample;
