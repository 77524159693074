import React, { useState, useEffect } from 'react';
import {
  PiPlayFill,
  PiEnvelopeFill,
  PiDownloadSimpleFill,
  PiShareFatFill,
} from 'react-icons/pi';

import { round, getPct } from '../../../../Utils';

const StatsBlock = (props) => {
  const { people } = props;

  const [stats, setStats] = useState({});

  useEffect(() => {
    let emailedNum = 0;
    let emailedTotal = 0;

    let viewedNum = 0;
    let viewedTotal = 0;

    let downloadedNum = 0;
    let downloadedTotal = 0;

    let sharedNum = 0;
    let sharedTotal = 0;

    people.forEach((person) => {
      const { metrics } = person;

      if (metrics.emailedCount >= 1) emailedNum++;
      emailedTotal += metrics.emailedCount ?? 0;

      if (metrics.viewedCount >= 1) viewedNum++;
      viewedTotal += metrics.viewedCount ?? 0;

      if (metrics.downloadedCount >= 1) downloadedNum++;
      downloadedTotal += metrics.downloadedCount ?? 0;

      if (metrics.sharedCount >= 1) sharedNum++;
      sharedTotal += metrics.sharedCount ?? 0;
    });

    const emailedPct = getPct(emailedNum / people.length);
    const emailedAverage = round(emailedTotal / people.length);

    const viewedPct = getPct(viewedNum / people.length);
    const viewedAverage = round(viewedTotal / people.length);

    const downloadedPct = getPct(downloadedNum / people.length);
    const downloadedAverage = round(downloadedTotal / people.length);

    const sharedPct = getPct(sharedNum / people.length);
    const sharedAverage = round(sharedTotal / people.length);

    setStats({
      emailedPct,
      emailedTotal,
      emailedAverage,
      viewedPct,
      viewedTotal,
      viewedAverage,
      downloadedPct,
      downloadedTotal,
      downloadedAverage,
      sharedPct,
      sharedTotal,
      sharedAverage,
    });
  }, [people]);

  const avg = () => {
    return window.innerWidth >= 640 ? 'Average' : 'Avg.';
  };

  const data = [
    {
      mainData: {
        icon: <PiEnvelopeFill />,
        text: 'Attendees emailed',
        num: stats.emailedPct,
      },
      otherData: [
        { text: 'Total emails sent', num: stats.emailedTotal },
        { text: `${avg()} emails per attendee`, num: stats.emailedAverage },
      ],
    },
    {
      mainData: {
        icon: <PiPlayFill />,
        text: 'Videos played',
        num: stats.viewedPct,
      },
      otherData: [
        { text: 'Total video plays', num: stats.viewedTotal },
        { text: `${avg()} plays per video`, num: stats.viewedAverage },
      ],
    },
    {
      mainData: {
        icon: <PiDownloadSimpleFill />,
        text: 'Videos downloaded',
        num: stats.downloadedPct,
      },
      otherData: [
        { text: 'Total video downloads', num: stats.downloadedTotal },
        { text: `${avg()} downloads per video`, num: stats.downloadedAverage },
      ],
    },
    {
      mainData: {
        icon: <PiShareFatFill />,
        text: 'Videos shared',
        num: stats.sharedPct,
      },
      otherData: [
        { text: 'Total video shares', num: stats.sharedTotal },
        { text: `${avg()} shares per video`, num: stats.sharedAverage },
      ],
    },
  ];

  return (
    <div className="flex flex-wrap gap-4">
      {data.map((metric) => (
        <div className="w-[calc((var(--page-width)_-_1rem)_/_2)] sm:w-[calc((var(--page-width)_-_(1rem_*_3))_/_4)]">
          <div className="flex p-3 sm:p-4 gap-3 sm:gap-4 items-center rounded-t-md border-1 border-black border-solid">
            <div className="flex items-center justify-center p-2 sm:p-3 text-lg sm:text-[1.25rem] text-white bg-black rounded-full">
              {metric.mainData.icon}
            </div>
            <div>
              <div className="text-md sm:text-lg font-bold">
                {metric.mainData.num ?? 0}%
              </div>
              <div className="text-xs sm:text-sm">{metric.mainData.text}</div>
            </div>
          </div>

          <div className="flex flex-col p-3 sm:p-4 gap-1 text-white bg-black rounded-b-md">
            {metric.otherData.map((el) => (
              <div className="flex items-center justify-between gap-1">
                <div className="text-xs sm:text-sm">{el.text}</div>
                <div className="text-sm sm:text-md font-bold">
                  {el.num ?? 0}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatsBlock;
