import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { PiSparkleFill } from 'react-icons/pi';

const SellingPoint = (props) => {
  const { variant, text, comingSoonText, icon, addClass } = props;

  const containerClass = classNames({
    'w-full flex items-center flex-row p-3 gap-2 sm:gap-4 rounded-md': true,
    'bg-gradient-purple-light text-white': !variant || variant === 'purple',
    'bg-gradient-primary-right text-white': variant === 'pink',
    'border-1 border-grey-900 border-solid text-black':
      variant === 'grey-outline',
  });

  return (
    <div className={`${containerClass} ${addClass || ''}`}>
      {icon || <PiSparkleFill size="1.5rem" />}

      <div className="w-[calc(100%_-_2rem)] sm:w-[calc(100%_-_2.5rem)]">
        {comingSoonText ? (
          <>
            <b>Coming Soon Feature</b>: {parse(comingSoonText)}
          </>
        ) : (
          text
        )}
      </div>
    </div>
  );
};

export default SellingPoint;
