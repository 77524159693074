import React, { useState, useEffect } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  isEmpty,
  canEditEvent,
  getEventImg,
  getBase64,
  getVideoBuilderLink,
} from '../../Utils';

import {
  trackEditProjectChangeImg,
  trackUpdateProjectSuccess,
  trackUpdateProjectError,
} from '../../analytics';

import ContentHeader from '../../components/contentHeader';
import FormFeedback from '../../components/form/formFeedback';
import ErrorModal from '../../components/modals/errorModal';
import PageSpinner from '../../components/pageSpinner';
import SubmitBtn from '../../components/submitBtn';

import {
  URL,
  EVENT_TYPE_VIDEOGRAPHER,
  EVENT_TYPE_BRAND,
  REC_WILDCARDS,
} from '../../constants';

import { mergeEvent, getUserEvents } from '../../services/api/events';
import {
  updateOneEvent,
  setAttendeeReviewData,
} from '../../services/api/mongodb';

import { useEventStore } from '../../stores/event';
import { useUserStore } from '../../stores/user';
import withRouter from '../../withRouter';

import EventCheckInSection from './components/eventCheckInSection';
import EventDetailsSection from './components/eventDetailsSection';
import EventShareSection from './components/eventShareSection';

import { validateForm } from './eventBuilderValidations';

const EventBuilder = () => {
  const user = useUserStore((state) => state.user);

  const queryParameters = new URLSearchParams(window.location.search);
  const eventId = queryParameters.get('eventId');
  const isAttendeeReview = queryParameters.get('isAttendeeReview') === 'true';

  const setEventId = useEventStore((state) => state.setEventId);
  const currentEvent = useEventStore((state) => state.currentEvent);

  const [openedEvent, setOpenedEvent] = useState({});
  const [eventName, setEventName] = useState('Untitled');
  const [type, setType] = useState(EVENT_TYPE_VIDEOGRAPHER);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [orientation, setOrientation] = useState(null);
  const [wildcards, setWildcards] = useState(REC_WILDCARDS);
  const [hashtag, setHashtag] = useState('');
  const [hashtagArray, setHashtagArray] = useState([]);
  const [caption, setCaption] = useState('');
  const [TsAndCsLink, setTsAndCsLink] = useState('');
  const [organiserName, setOrganiserName] = useState('');
  const [customFieldLabel, setCustomFieldLabel] = useState('');

  const [bannerFile, setBannerFile] = useState(null);
  const [bannerUrl, setBannerUrl] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [pageError, setPageError] = useState(null);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const isBrand = type === 'brand';
  const isEditMode = !isEmpty(eventId);

  useEffect(() => {
    if (!isEditMode) {
      setIsLoading(true);
      onSubmit('', true);
    }
  }, [isEditMode]);

  // set event id on mount
  useEffect(() => {
    if (eventId) setEventId(eventId);
  }, [eventId, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (isEditMode) {
        // if editing event –  fill the form with event data
        if (currentEvent.error) setPageError(currentEvent.error);
        else {
          setOpenedEvent(currentEvent);
          setEventName(currentEvent.name ?? '');
          setType(currentEvent.type ?? null);
          setStartDate(currentEvent.startDate?.slice(0, 10) ?? '');
          setEndDate(currentEvent.endDate?.slice(0, 10) ?? '');
          setOrientation(currentEvent.videoMode ?? null);
          setWildcards(currentEvent.wildcards ?? REC_WILDCARDS);
          setCaption(currentEvent.caption ?? '');
          setHashtag(getHashtagText(currentEvent.hashTag ?? []));
          setHashtagArray(currentEvent.hashTag ?? []);
          setTsAndCsLink(currentEvent.TsAndCsLink ?? '');
          setOrganiserName(currentEvent.organiserName ?? '');
          setCustomFieldLabel(currentEvent.customFieldLabel ?? '');
          setBannerUrl(getEventImg('banner', currentEvent.banner));
          setLogoUrl(getEventImg('logo', currentEvent.logo));
        }

        setIsLoading(false);
      }
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  const getHashtagText = (hashtagArray) => {
    return '#' + hashtagArray.join(' #');
  };

  const changeType = (value) => {
    setType(value);
    if ((value === EVENT_TYPE_BRAND) & isEmpty(wildcards))
      setWildcards(REC_WILDCARDS);
  };

  const onChange = (e, setValue) => {
    let value = e.target.value;

    if (e.target.name === 'hashtag') {
      const hashtagArray = value
        .split(/[\s#,.]+/)
        .filter((tag, index) => tag !== '' || index !== 0);

      value = getHashtagText(hashtagArray);

      setHashtagArray(hashtagArray);
    }

    setValue(value);

    setErrors({});
  };

  const onChangeImg = (file, isBanner) => {
    getBase64(file, (url) => {
      if (isBanner) setBannerUrl(url);
      else setLogoUrl(url);
    });

    if (isBanner) setBannerFile(file);
    else setLogoFile(file);

    trackEditProjectChangeImg(isBanner ? 'banner' : 'logo');

    setErrors({});
  };

  const onSubmit = async (e, withoutTracker = false) => {
    if (e) e.preventDefault();

    const errors = await validateForm(isEditMode, {
      type,
      eventName,
      startDate,
      endDate,
      orientation,
      wildcards,
    });

    if (!isEmpty(errors) && isEditMode) {
      setErrors(errors);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      toast.error('Some data is missing or invalid');
    } else {
      setIsSaving(true);
      const formData = new FormData();

      const hasCustomBanner = !!bannerFile;
      const hasCustomLogo = !!logoFile;

      if (isEditMode) {
        formData.append('eventId', openedEvent.eventId);
        formData.append('created', openedEvent.created);
        formData.append('stage', openedEvent.stage);
      }

      if (orientation) formData.append('videoMode', orientation);
      else if (openedEvent.videoMode)
        formData.append('videoMode', openedEvent.videoMode);

      if (isBrand) {
        if (startDate) formData.append('startDate', startDate);
        if (endDate) formData.append('endDate', endDate);

        wildcards.forEach((w) => {
          formData.append('wildcards[]', parseFloat(w));
        });
      }

      formData.append('name', eventName);
      formData.append('userId', user.session.userId);
      formData.append('type', type);
      formData.append('caption', caption);
      hashtagArray.forEach((ht) => {
        formData.append('hashTag[]', ht);
      });
      formData.append('customFieldLabel', customFieldLabel);

      formData.append('hasCustomBanner', hasCustomBanner);
      if (hasCustomBanner) formData.append('banner', bannerFile);

      formData.append('hasCustomLogo', hasCustomLogo);
      if (hasCustomLogo) formData.append('logo', logoFile);

      try {
        const event = await mergeEvent(formData);
        await updateOneEvent({ eventId, TsAndCsLink, organiserName });

        await getUserEvents(user.session.userId);
        setIsSaving(false);

        if (!withoutTracker) trackUpdateProjectSuccess();

        if (isAttendeeReview) {
          await setAttendeeReviewData({
            eventId: event.eventId,
            isAttendeeReview: 'true',
          });
        }

        // go to edit if new brand event
        if (isBrand && !isEditMode)
          navigate(`${URL.DASH_EVENT_BUILDER}?eventId=${event.eventId}`);
        else {
          setIsLoading(false);
          navigate(getVideoBuilderLink(event));
        }
      } catch {
        setIsSaving(false);
        if (!withoutTracker) trackUpdateProjectError();
        toast.error('Something went wrong. Try again later');
      }

      // session storage cleanup
      sessionStorage.removeItem('attendees');
    }
  };

  useEffect(() => {
    // session storage cleanup
    sessionStorage.removeItem('attendees');
  }, []);

  const renderEditFields = () => {
    return (
      <div
        className={`flex flex-col gap-6 ${
          isBrand ? 'sm:flex-row sm:gap-10' : ''
        }`}
      >
        {isBrand && (
          <EventDetailsSection
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={(e) => onChange(e, setStartDate)}
            onChangeEndDate={(e) => onChange(e, setEndDate)}
            orientation={orientation}
            setOrientation={setOrientation}
            wildcards={wildcards}
            setWildcards={setWildcards}
            errors={errors}
          />
        )}

        <div
          className={`${isBrand ? 'w-full sm:w-1/2' : ''} flex flex-col gap-8 `}
        >
          <EventCheckInSection
            bannerUrl={bannerUrl}
            logoUrl={logoUrl}
            TsAndCsLink={TsAndCsLink}
            organiserName={organiserName}
            customFieldLabel={customFieldLabel}
            onChangeImg={onChangeImg}
            onChangeTsAndCsLink={(e) => onChange(e, setTsAndCsLink)}
            onChangeOrganiserName={(e) => onChange(e, setOrganiserName)}
            onChangeCustomFieldLabel={(e) => onChange(e, setCustomFieldLabel)}
            isBrand={isBrand}
          />

          <EventShareSection
            caption={caption}
            hashtag={hashtag}
            onChangeCaption={(e) => onChange(e, setCaption)}
            onChangeHashtag={(e) => onChange(e, setHashtag)}
          />
        </div>
      </div>
    );
  };

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`Project Builder – ${eventName}`}
      isPageContainer
      className="gap-6"
    >
      <ContentHeader title={`${isEditMode ? 'Edit' : 'Create'} Project`} />

      <Form onSubmit={onSubmit} noValidate>
        <fieldset className="flex flex-col gap-6" disabled={isSaving}>
          <FloatingLabel label="Project name*">
            <Form.Control
              aria-label="Project name*"
              type="text"
              name="eventName"
              value={eventName}
              onChange={(e) => onChange(e, setEventName)}
              placeholder="Project name"
              isInvalid={errors.eventName}
              required
            />
            <FormFeedback error={errors.eventName} />
          </FloatingLabel>

          {/* temporary removed  */}
          {/* <EventTypeSelector
            type={type}
            changeType={changeType}
            error={errors.type}
          /> */}

          {isEditMode && renderEditFields()}

          <SubmitBtn
            title={isEditMode ? 'Update' : 'Create'}
            isProcessing={isSaving}
          />
        </fieldset>
      </Form>

      <ErrorModal
        show={!canEditEvent(openedEvent)}
        heading="Sorry, this project cannot be edited"
        subheading="You may not have access to edit this project or it has already started."
      />
    </PageSpinner>
  );
};

export default withRouter(EventBuilder);
