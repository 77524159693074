import React from 'react';
import { Upload } from 'antd';
import { isMobile, isTablet } from 'react-device-detect';
import { PiCloudArrowUp } from 'react-icons/pi';

const DraggerSimplified = (props) => {
  const { type, isMultiple, onDrop, uploadRef, isDemo } = props;

  const beforeUpload = (file, fileList) => {
    onDrop(file, fileList);
    return false;
  };

  let draggerProps = { name: 'file', showUploadList: false };

  if (type === 'video') draggerProps = { ...draggerProps, accept: '.mov,.mp4' };
  else if (type === 'image')
    draggerProps = { ...draggerProps, accept: '.bmp,.gif,.jpeg,.jpg,.png' };

  if (isMultiple) draggerProps = { ...draggerProps, multiple: true };
  else draggerProps = { ...draggerProps, maxCount: 1, multiple: false };

  return (
    <div className="flex flex-col gap-2">
      {isDemo && (
        <div className="italic mt-2">
          You cannot upload your own files here, but you'll be able to in all
          live projects.
        </div>
      )}

      <Upload.Dragger
        {...draggerProps}
        className="w-full rounded-md shadow-xl transition-all hover:bg-grey-100"
        beforeUpload={(file, fileList) => beforeUpload(file, fileList)}
        ref={uploadRef}
        disabled={isDemo}
      >
        <div className="flex flex-col items-center justify-center gap-2 px-4">
          <PiCloudArrowUp size="2.5rem" />

          {isMobile || isTablet ? (
            <div className="text-md">
              <b>Click here</b> to upload people
            </div>
          ) : (
            <div className="text-md">
              <b>Drag & drop</b> to upload people
              <br />
              or <b>click here to browse</b>
            </div>
          )}
        </div>
      </Upload.Dragger>
    </div>
  );
};

export default DraggerSimplified;
