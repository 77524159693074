import React from 'react';
import { Form, FloatingLabel, InputGroup } from 'react-bootstrap';

const DetailsSection = (props) => {
  const {
    event,
    name,
    setName,
    instagram,
    setInstagram,
    customFieldData,
    setCustomFieldData,
  } = props;

  return (
    <React.Fragment>
      <FloatingLabel label="First name*">
        <Form.Control
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Please enter a name"
          required
        />
      </FloatingLabel>

      {event.customFieldLabel && (
        <FloatingLabel label={event.customFieldLabel}>
          <Form.Control
            type="text"
            name="customFieldData"
            value={customFieldData}
            onChange={(e) => setCustomFieldData(e.target.value)}
            placeholder={event.customFieldLabel}
          />
        </FloatingLabel>
      )}

      <div className="flex flex-col gap-2">
        <div>
          Be featured, get exclusive content or sneak peek behind the scenes of
          this event via Instagram!
        </div>

        <InputGroup>
          <InputGroup.Text>@</InputGroup.Text>
          <FloatingLabel label="Instagram">
            <Form.Control
              type="text"
              name="instagram"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              placeholder="Instagram"
            />
          </FloatingLabel>
        </InputGroup>
      </div>
    </React.Fragment>
  );
};

export default DetailsSection;
