import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

import eventCaption from '../../../assets/event-caption.png';

import HeadingWithInfo from './headingWithInfo';

const EventShareSection = (props) => {
  const { caption, hashtag, onChangeCaption, onChangeHashtag } = props;

  return (
    <div className="flex flex-col gap-2">
      <HeadingWithInfo
        heading="Share"
        infoHeader="Setting up your Share page"
        infoBody={
          <>
            <div>
              Together with video, your attendees will receive hashtags and
              caption to copy and paste to social media.
            </div>

            <img src={eventCaption} alt="Caption Graphic" />
          </>
        }
      />

      <div className="text-sm italic">
        Provide your attendees with a caption and hashtags they can use in their
        social media posts
      </div>

      <FloatingLabel label="Caption">
        <Form.Control
          as="textarea"
          type="text"
          name="caption"
          value={caption}
          onChange={onChangeCaption}
          placeholder="Caption"
        />
      </FloatingLabel>

      <FloatingLabel label="Hashtag">
        <Form.Control
          type="text"
          name="hashtag"
          value={hashtag === '#' ? '' : hashtag}
          onChange={onChangeHashtag}
          placeholder="Hashtag"
        />
        <Form.Text muted>
          #crowdclip hashtag will be added to the list automatically
        </Form.Text>
      </FloatingLabel>
    </div>
  );
};

export default EventShareSection;
