import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { isMobile, isTablet } from 'react-device-detect';
import { toast } from 'react-toastify';
import {
  trackOnboardingModalClose,
  trackOnboardingModalVideoWatchFully,
  trackOnboardingModalVideoWatchPartially,
  trackOnboardingModalClickRemindMeLater,
  trackQuickstartPackDownload,
} from '../../analytics';
import { HELP_VIDEO_ID, ZIP_URL } from '../../constants';
import Logo from '../logo';

declare global {
  interface Window {
    onYouTubeIframeAPIReady?: () => void;
    YT: typeof YT;
  }

  namespace YT {
    class Player {
      constructor(elementId: string, options: PlayerOptions);
      playVideo(): void;
      pauseVideo(): void;
      stopVideo(): void;
    }

    interface PlayerOptions {
      height: string;
      width: string;
      videoId: string;
      events?: {
        onStateChange?: (event: OnStateChangeEvent) => void;
      };
      enablejsapi: number;
    }

    interface PlayerEvent {
      target: Player;
    }

    interface OnStateChangeEvent extends PlayerEvent {
      data: number;
    }

    enum PlayerState {
      UNSTARTED = -1,
      ENDED = 0,
      PLAYING = 1,
      PAUSED = 2,
      BUFFERING = 3,
      CUED = 5,
    }
  }
}

const OnboardingVideoModal = (props: {
  isNewUser: boolean;
  showOnboardingVideo: boolean;
  setShowOnboardingVideo: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const playerRef = useRef<YT.Player | null>(null);
  const [watched, setWatched] = useState(false);
  const [watchedPartially, setWatchedPartially] = useState(false);

  useEffect(() => {
    const lastSeen = localStorage.getItem('lastSeenIntroModal');

    if (props.isNewUser) {
      if (!lastSeen) {
        // If the user has never seen the modal, show it
        props.setShowOnboardingVideo(true);
      } else {
        const lastSeenDate = new Date(lastSeen);
        const currentDate = new Date();

        // Calculate the difference in days
        const diffInDays = Math.round(
          (currentDate.getTime() - lastSeenDate.getTime()) /
            (1000 * 60 * 60 * 24),
        );

        if (diffInDays >= 28) {
          // If a month or more has passed since the user last saw the modal, show it again
          props.setShowOnboardingVideo(true);
        }
      }
    }
  }, [props.isNewUser]);

  const handleClose = () => {
    props.setShowOnboardingVideo(false);
    trackOnboardingModalClose();

    handlePlayerGATracking();

    const showToastAfterTheModal = localStorage.getItem(
      'showToastAfterTheModal',
    );
    if (props.isNewUser && showToastAfterTheModal) {
      setTimeout(() => {
        if (isMobile || isTablet) {
          localStorage.removeItem('showToastAfterTheModal');
          toast.warning(
            <div>
              <div>CrowdClip&reg; is best used on a desktop.</div>
              <div>
                You can continue on your mobile device, but beware, things will
                look a bit funny.
              </div>
            </div>,
            {
              autoClose: false,
            },
          );
        }
      }, 200);
    }
  };

  const handleDownloadQuickStart = () => {
    // Update the date when the user last saw the modal
    localStorage.setItem('lastSeenIntroModal', new Date().toISOString());

    trackQuickstartPackDownload('Onboarding modal');
  };

  const handleCloseRemindLater = () => {
    props.setShowOnboardingVideo(false);
    trackOnboardingModalClickRemindMeLater();

    handlePlayerGATracking();

    const showToastAfterTheModal = localStorage.getItem(
      'showToastAfterTheModal',
    );
    if (props.isNewUser && showToastAfterTheModal) {
      setTimeout(() => {
        if (isMobile || isTablet) {
          localStorage.removeItem('showToastAfterTheModal');
          toast.warning(
            <div>
              <div>CrowdClip&reg; is best used on a desktop.</div>
              <div>
                You can continue on your mobile device, but beware, things will
                look a bit funny.
              </div>
            </div>,
            {
              autoClose: false,
            },
          );
        }
      }, 200);
    }
  };

  const handlePlayerGATracking = () => {
    if (watched && !watchedPartially) trackOnboardingModalVideoWatchFully();
    else if (!watched && watchedPartially)
      trackOnboardingModalVideoWatchPartially();
  };

  useEffect(() => {
    if (props.showOnboardingVideo || props.isNewUser) {
      playerRef.current = new window.YT.Player('player', {
        height: '352',
        width: '100%',
        videoId:
          isMobile || isTablet
            ? HELP_VIDEO_ID.ONBOARDING_MOBILE
            : HELP_VIDEO_ID.ONBOARDING_DESKTOP,

        events: {
          onStateChange: onPlayerStateChange,
        },
        enablejsapi: 1,
      });
    }
  }, [props]);

  const onPlayerStateChange = (event: YT.OnStateChangeEvent) => {
    if (event.data === YT.PlayerState.PLAYING) {
      setWatchedPartially(true);
    } else if (event.data === YT.PlayerState.PAUSED) {
      setWatchedPartially(true);
    } else if (event.data === YT.PlayerState.ENDED) {
      setWatched(true);
      setWatchedPartially(false);
    }
  };

  const videoClass = classNames({
    'w-full rounded-md': true,
    'h-[calc((100vw_-_(2_*_var(--bs-modal-margin))_-_(2_*_var(--bs-modal-padding)))_*_16_/_9)] max-h-[90vh]':
      isMobile || isTablet,
    'h-[200px] lg:h-[352px]': !isMobile && !isTablet,
  });

  // Only show the modal when there are no user created events
  return !props.isNewUser && !props.showOnboardingVideo ? null : (
    <Modal
      size="lg"
      className="text-white"
      show={props.showOnboardingVideo}
      onHide={handleClose}
      centered
    >
      <Modal.Body className="text-center bg-cc-purple-700 md:p-12 space-y-4 sm:space-y-7 rounded-md">
        <h1 className="text-xl sm:text-2xl leading-10">
          Welcome to{' '}
          <Logo isWhite width="140px" className="align-middle inline-block" />
        </h1>

        <p className="text-lg sm:text-xl">
          <b>Before you begin!</b> Learn how CrowdClip&reg;'s AI can help you
          generate hundreds of videos for your clients events in a few clicks
        </p>

        <div id="player" className={videoClass} />

        <div>
          {isMobile || isTablet ? (
            <p>
              We recommend downloading Quickstart pack on your desktop computer.
              The Quickstart pack is a ZIP, that contains pre-made media assets,
              to help you get started.
            </p>
          ) : (
            <p className="text-lg sm:text-xl">
              Get a head start, download ready-to-try assets and give
              CrowdClip&reg; a go!
            </p>
          )}

          <a
            href={ZIP_URL.QUICKSTART}
            download="Quickstart to CrowdClip.zip"
            onClick={() => {
              handleDownloadQuickStart();
            }}
          >
            <button
              type="button"
              className="text-white sm:text-xl bg-black border-white border-4 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full px-3 sm:px-5 py-3"
            >
              Download the quickstart pack
            </button>
          </a>

          <div className="mt-3">
            <span
              onClick={handleCloseRemindLater}
              className="cursor-pointer hover:underline"
            >
              Remind me later
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OnboardingVideoModal;
