import React, { useState } from 'react';
import { Modal, Form, FloatingLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';

import {
  isEmpty,
  isPlural,
  getPluralPhrase,
  validateEmail,
  updateEmailText,
} from '../../../../Utils';

import FormFeedback from '../../../../components/form/formFeedback';
import SubmitBtn from '../../../../components/submitBtn';

import { sendEmail } from '../../../../services/api/emails';

const SendTestEmailModal = (props: any) => {
  const { onHide, template, event, user, customText } = props;

  const [emails, setEmails] = useState<string>('');
  const [emailsErr, setEmailsErr] = useState<string | null>(null);

  const [isSending, setIsSending] = useState<boolean>(false);

  if (isEmpty(event) || isEmpty(template)) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const emailsArr: string[] = emails.split(',');
    let emailErrTemp: string | null = null;

    emailsArr.forEach((email) => {
      if (!emailErrTemp) {
        const { emailErr } = validateEmail(email.trim());

        if (emailErr)
          emailErrTemp = 'Some of the addresses appear to be invalid';
      }
    });

    if (emailErrTemp) setEmailsErr(emailErrTemp);
    else {
      setIsSending(true);

      let successCount: number = 0;
      let errorList: string[] = [];

      for (const email of emailsArr) {
        const emailData = {
          recipientEmail: email.trim(),
          templateId: template.template_id,
          dynamicTemplateData: {
            event_name: event.name,
            name: user.firstName,
            share_url: 'https://www.crowdclip.com/',
            text: customText,
          },
        };

        const isSuccess: any = await sendEmail(emailData);

        if (isSuccess) successCount++;
        else errorList.push(email.trim());
      }

      if (successCount > 0)
        toast.success(
          <div>
            Sent email to <b>{getPluralPhrase(successCount, 'address')}</b>.
          </div>,
        );

      if (!isEmpty(errorList))
        toast.error(
          <div>
            Email{isPlural(errorList)} couldn't be sent to{' '}
            <b>{errorList.join(', ')}</b>, please try again later.
          </div>,
        );

      setIsSending(false);
    }
  };

  return (
    <Modal show={!isEmpty(template)} onHide={onHide} size="lg" centered>
      <Modal.Header className="px-4 sm:px-8" closeButton>
        <Modal.Title>Send a test email</Modal.Title>
      </Modal.Header>

      <Modal.Body className="flex flex-col px-4 sm:px-8 gap-4">
        <div>
          <b>Subject</b>: {updateEmailText(template.subject, event, user)}
        </div>

        <Form onSubmit={onSubmit} noValidate className="gap-4">
          <FloatingLabel label="Email addresses">
            <Form.Control
              type="text"
              name="emails"
              value={emails ?? ''}
              onChange={(e) => {
                setEmails(e.target.value);
                setEmailsErr(null);
              }}
              placeholder="name@example.com"
              isInvalid={!!emailsErr}
              required
            />

            <FormFeedback error={emailsErr} />

            <Form.Text>
              Enter 1 or more email addresses, separated by commas
            </Form.Text>
          </FloatingLabel>

          <SubmitBtn
            title="Send"
            isProcessing={isSending}
            isDisabled={isEmpty(emails) || !isEmpty(emailsErr)}
          />
        </Form>
      </Modal.Body>

      <Modal.Footer className="justify-start px-4 sm:px-8">
        <div className="text-sm italic">
          Please note that all links (if existing) in the test email are
          substituted with www.crowdclip.com. The emails your attendees receive
          will include all relevant links.
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SendTestEmailModal;
