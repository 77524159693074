import React from 'react';
import classNames from 'classnames';
import { PiXBold, PiPencilFill } from 'react-icons/pi';
import { Spinner } from '../';

import PopoverTrigger from '../popoverTrigger';

const SideIcon = (props) => {
  const { icon, itemType, isProcessing, onClick, hidePopover } = props;

  const iconClass = classNames({
    'absolute w-6 h-6 items-center justify-center rounded-full text-white border-1 border-white border-solid cursor-pointer transition-all duration-500 z-2 hidden group-hover:flex group-focus:flex hover:scale-110': true,
    'top-[0.75rem] right-[0.75rem]': icon === 'delete' && itemType === 'person',
    'top-[-0.55rem] right-[-0.55rem]':
      icon === 'delete' && itemType !== 'person',
    'top-[-0.55rem] left-[-0.55rem] bg-success-900': icon === 'edit',
    'bg-error-900': icon === 'delete',
  });

  // delete icon – shows X or spinner if deleting, also has popover of hover
  if (icon === 'delete')
    return (
      <PopoverTrigger
        content={
          <div className={iconClass} onClick={onClick}>
            {isProcessing ? (
              <Spinner size="2xs" color="white" />
            ) : (
              <PiXBold className="text-sm" />
            )}
          </div>
        }
        placement="bottom"
        popoverBody={`<div>Clicking 'X' will <b>delete</b> the ${itemType} and this action <b>cannot be undone</b></div>`}
        hidePopover={!itemType || hidePopover}
      />
    );

  // edit icon – shows pen
  if (icon === 'edit')
    return (
      <div className={iconClass} onClick={onClick}>
        <PiPencilFill className="text-sm" />
      </div>
    );

  return null;
};

export default SideIcon;
