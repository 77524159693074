import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

import eventCustomField from '../../../assets/event-custom-field.png';
import eventImages from '../../../assets/event-images.png';
import eventTsAndCs from '../../../assets/event-ts-and-cs.png';

import DraggerSimplified from '../../../components/draggerSimplified';
import { getTsAndCsText } from '../../CheckIn/components/TsAndCsText';
import HeadingWithInfo from './headingWithInfo';

const EventCheckInSection = (props) => {
  const {
    bannerUrl,
    logoUrl,
    TsAndCsLink,
    organiserName,
    customFieldLabel,
    onChangeImg,
    onChangeTsAndCsLink,
    onChangeOrganiserName,
    onChangeCustomFieldLabel,
    isBrand,
  } = props;

  const bannerLogoInfoBody = (
    <>
      <div>
        The banner and logo will be shown at the top of the Check-In page.
      </div>

      <img src={eventImages} alt="Banner & Logo Graphic" />
    </>
  );

  return (
    <div className="flex flex-col gap-8">
      <h4 className="font-semibold">Check-In Page</h4>

      <div
        className={`flex flex-col gap-6 mt-[-1rem] ${
          isBrand ? '' : 'sm:flex-row sm:gap-10'
        }`}
      >
        <div
          className={`${isBrand ? '' : 'w-full sm:w-1/2'} flex flex-col gap-2`}
        >
          <HeadingWithInfo
            subheading="Banner"
            infoHeader="Setting up your Banner & Logo"
            infoBody={bannerLogoInfoBody}
          />

          <DraggerSimplified
            type="image"
            imgUrl={bannerUrl}
            isDashboard
            isColumn={isBrand}
            onDrop={(file) => onChangeImg(file, true)}
          />

          <div className="text-sm italic">
            Pro Tip: for the best experience, make sure your banner fits a 16:9
            aspect ratio.
          </div>
        </div>

        <div
          className={`${isBrand ? '' : 'w-full sm:w-1/2'} flex flex-col gap-2`}
        >
          <HeadingWithInfo
            subheading="Logo"
            infoHeader="Setting up your Banner & Logo"
            infoBody={bannerLogoInfoBody}
          />

          <DraggerSimplified
            type="image"
            imgUrl={logoUrl}
            isDashboard
            isColumn={isBrand}
            onDrop={(file) => onChangeImg(file, false)}
          />

          <div className="text-sm italic">
            Pro Tip: for the best experience, make sure your logo fits a 1:1
            aspect ratio.
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <HeadingWithInfo
          subheading="Terms & Conditions"
          infoHeader="Setting up your Terms & Conditions"
          infoBody={
            <>
              <div>
                The Terms & Conditions (if existing) will be shown on the
                Check-In page. Attendees cannot proceed without accepting them.
              </div>

              <img src={eventTsAndCs} alt="T&C Graphic" />

              <div>
                The Organiser name (if provided) will be shown instead of the
                "event organiser's" text. The T&C hyperlink will lead the
                attendee to the provided Link.
              </div>
            </>
          }
        />

        <div className="text-sm italic">
          Add your T&C to appear on check-in page along with CrowdClip&reg; T&C
        </div>

        <FloatingLabel label="Link">
          <Form.Control
            type="text"
            name="TsAndCsLink"
            value={TsAndCsLink}
            onChange={onChangeTsAndCsLink}
            placeholder="Link"
          />
        </FloatingLabel>

        <FloatingLabel label="Organiser name">
          <Form.Control
            type="text"
            name="organiserName"
            value={organiserName}
            onChange={onChangeOrganiserName}
            placeholder="Organiser name"
          />
        </FloatingLabel>

        <div className="text-sm">
          <b>What attendees see</b>:{' '}
          {getTsAndCsText(TsAndCsLink, organiserName)}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <HeadingWithInfo
          subheading="Custom Field"
          infoHeader="Setting up your Custom Field"
          infoBody={
            <>
              <div>
                The Custom Field (if existing) will be shown as an additional
                optional field for attendees to fill on the Check-In page.
              </div>

              <img src={eventCustomField} alt="Custom Field Graphic" />
            </>
          }
        />

        <div className="text-sm italic">
          Add a custom property to appear on check-in page
        </div>

        <FloatingLabel label="Custom field label">
          <Form.Control
            type="text"
            name="customFieldLabel"
            value={customFieldLabel}
            onChange={onChangeCustomFieldLabel}
            placeholder="Custom field label"
          />
        </FloatingLabel>
      </div>
    </div>
  );
};

export default EventCheckInSection;
