import React from 'react';
import classNames from 'classnames';
import { PiCaretRight } from 'react-icons/pi';
import { Link } from 'react-router-dom';

const SideNavBtn = (props) => {
  const {
    icon,
    text,
    href,
    onClick,
    isNewTab,
    extraIcon,
    isBtnImitator,
    isProminent,
    addClass,
  } = props;

  if (isBtnImitator)
    return (
      <div className="flex items-center w-full h-10 px-3 py-1.5 gap-2 no-underline text-black">
        <div className="flex gap-2 items-center">
          <div className="text-[1.1rem] leading-none">{icon}</div>
          <div>{text}</div>
        </div>

        {extraIcon}
      </div>
    );

  const textClass = classNames({
    'bg-gradient-primary-right bg-clip-text text-transparent': isProminent,
  });

  return (
    <Link
      to={href}
      onClick={onClick}
      target={isNewTab ? '_blank' : null}
      className={`w-full h-10 flex items-center justify-between px-3 py-1.5 gap-2 no-underline text-true-black rounded-md hover:bg-grey-100 hover:text-black hover:opacity-100  ${addClass || ''}`}
    >
      <div className="flex gap-2 items-center">
        <div className="text-[1.1rem] leading-none">{icon}</div>
        <div className={textClass}>{text}</div>
      </div>

      <div className="text-[1.1rem] leading-none">
        {extraIcon || <PiCaretRight />}
      </div>
    </Link>
  );
};

export default SideNavBtn;
