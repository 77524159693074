import React, { useState, useEffect, useMemo } from 'react';

import { ProgressBar } from '../../../../components';
import { getGenerateText } from '../../components/getText';

const GenerateSubStep = (props) => {
  const {
    completePeople,
    incompletePeople,
    generatedPeople,
    isDemo,
    handleDemoGenerationActions,
  } = props;

  // demo data
  const demoData = useMemo(() => {
    return JSON.parse(localStorage.getItem('demoData')) ?? {};
  }, []);
  const [demoPeople, setDemoPeople] = useState(demoData.people ?? []);

  const { demoCompletePeople, demoIncompletePeople, demoGeneratedPeople } =
    useMemo(() => {
      const demoCompletePeople = [];
      const demoIncompletePeople = [];
      const demoGeneratedPeople = [];

      demoPeople.forEach((person) => {
        if (person.isSelected) {
          if (person.isGenerated) demoGeneratedPeople.push(person);
          else if (person.isComplete) demoCompletePeople.push(person);
          else demoIncompletePeople.push(person);
        }
      });

      return { demoCompletePeople, demoIncompletePeople, demoGeneratedPeople };
    }, [demoPeople]);

  // imitate generation
  useEffect(() => {
    if (
      isDemo &&
      demoData.event?.stage === 'generation' &&
      !demoData.isVideosGenerated
    ) {
      const interval = setInterval(() => {
        setDemoPeople((prevPeople) => {
          const nextPeople = [...prevPeople];

          const person = nextPeople.find(
            (person) => person.isComplete && !person.isGenerated,
          );

          // if complete not-generated person exists
          if (person) {
            person.isGenerated = true;

            // last video was just 'generated'
            if (nextPeople.every((p) => p.isGenerated)) {
              clearInterval(interval);

              demoData.isVideosGenerated = true;
              demoData.people = nextPeople;

              handleDemoGenerationActions(demoData);
            }
          }

          return nextPeople;
        });
      }, 1500);

      return () => clearInterval(interval);
    }
  }, [demoData, handleDemoGenerationActions, isDemo]);

  const { generateText, progress } = getGenerateText({
    completePeople: isDemo ? demoCompletePeople : completePeople,
    incompletePeople: isDemo ? demoIncompletePeople : incompletePeople,
    generatedPeople: isDemo ? demoGeneratedPeople : generatedPeople,
  });

  return (
    <React.Fragment>
      <div>{generateText}</div>

      {progress < 100 && (
        <div className="w-full flex flex-col gap-4 text-center">
          <ProgressBar progress={progress} />

          <div className="font-bold uppercase">Generating</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default GenerateSubStep;
