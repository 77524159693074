import React from 'react';

const UploadMoreBtn = (props) => {
  const { children, onClick } = props;
  return (
    <button
      onClick={onClick}
      className="w-full sm:w-1/2 h-[var(--form-el-height)] self-center p-4 rounded-md bg-primary-50 font-bold text-primary-900 border-1 border-primary-translucent border-solid hover:border-primary-900"
    >
      {children}
    </button>
  );
};

export default UploadMoreBtn;
