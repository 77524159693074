import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  Dispatch,
  SetStateAction,
  FC,
} from 'react';
import { Form, Modal } from 'react-bootstrap';
import { isMobile, isTablet } from 'react-device-detect';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { goTo, isEmpty, validateEmail, isProduction } from '../../Utils';

import { trackLogInSuccess, trackLogInError } from '../../analytics';

import { Button } from '../../components';
import EmailField from '../../components/form/emailField';
import PasswordField from '../../components/form/passwordField';
import SubmitBtn from '../../components/submitBtn';

import config from '../../config';
import { URL } from '../../constants';

import { getUserEvents } from '../../services/api/events';
import { signIn } from '../../services/api/sessions';
import { Event } from '../../types';
import OnboardingPage from './onboardingPage';

interface ErrorType {
  emailErr?: string;
  passwordErr?: string;
  emailPwErr?: string;
  forgotPwEmailErr?: string;
}

const Login: FC = () => {
  const [email, setEmail] = useState<string>(
    sessionStorage.getItem('email') || '',
  );
  const [password, setPassword] = useState<string>('');
  const [isKeepSignIn, setIsKeepSignIn] = useState<boolean>(true);
  const [forgotPwEmail, setForgotPwEmail] = useState<string>('');
  const [errors, setErrors] = useState<ErrorType>({});
  const [showResetPwModal, setShowResetPwModal] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const queryParameters = new URLSearchParams(window.location.search);

  const navigate = useNavigate();

  useEffect(() => {
    const onMount = async () => {
      if (queryParameters.get('forgotPassword')) {
        setShowResetPwModal(true);
        window.history.pushState(null, '', URL.LOGIN);
      }
    };

    onMount();
  }, []);

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    setValue: Dispatch<SetStateAction<string>>,
  ) => {
    const value = e.target.value;
    setValue(value);
    setErrors({});
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let errorsTemp: ErrorType = {};

    const { emailErr } = validateEmail(email);
    if (emailErr) errorsTemp.emailErr = emailErr;

    if (!password.trim().length) {
      errorsTemp.passwordErr = 'Password is required';
    }

    if (!isEmpty(errorsTemp)) {
      setErrors(errorsTemp);
    } else {
      setIsLoggingIn(true);

      try {
        const user = await signIn(email, password, isKeepSignIn);
        const userEvents = await getUserEvents(user.session.userId);

        const previousPage = localStorage.getItem('previousPage');
        goTo(previousPage || URL.HOME);
        localStorage.removeItem('previousPage');

        trackLogInSuccess(previousPage);

        setIsLoggingIn(false);

        if (isProduction()) {
          const advancedMatching: any = { em: email };
          const options = {
            autoConfig: true,
            debug: false,
          };
          ReactPixel.init(config.pixelID || '', advancedMatching, options);
          ReactPixel.track('Leads');

          if (hotjar.initialized()) {
            hotjar.identify(user.session.userId, { email });
          }
        }

        if (isMobile || isTablet) {
          if (!!userEvents.length) {
            const lastSeen = localStorage.getItem('lastSeenIntroModal');
            const notDemoEvent = userEvents.filter(
              (event: Event) => event.type !== 'demo',
            );
            if (!!notDemoEvent.length && lastSeen) {
              setTimeout(() => {
                toast.warning(
                  <div>
                    <div>CrowdClip&reg; is best used on a desktop.</div>
                    <div>
                      You can continue on your mobile device, but beware, things
                      will look a bit funny.
                    </div>
                  </div>,
                  {
                    autoClose: false,
                  },
                );
              }, 200);
            } else {
              localStorage.setItem('showToastAfterTheModal', 'true');
            }
          }
        }
      } catch (err) {
        console.log(err);

        const errMsg =
          err && typeof err === 'object' && 'errMsg' in err
            ? (err.errMsg as string)
            : null;

        if (errMsg) errorsTemp.emailPwErr = errMsg;
        else toast.error('Something went wrong. Try again later');

        setErrors(errorsTemp);
        trackLogInError(errMsg ?? 'Something went wrong. Try again later');
        setIsLoggingIn(false);
      }
    }
  };

  const onClickForgotPw = (email: string) => {
    setShowResetPwModal(true);
    setForgotPwEmail(email);
    setErrors({});
  };

  const onSubmitForgotPw = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { emailErr } = validateEmail(forgotPwEmail);
    if (emailErr) {
      setErrors({ forgotPwEmailErr: emailErr });
    } else {
      // TODO: add reset password email
      navigate(URL.FORGOT_PASSWORD);
    }
  };

  return (
    <OnboardingPage title="Login" heading="Log in to CrowdClip®">
      <Form noValidate onSubmit={onSubmit}>
        <fieldset className="gap-4" disabled={isLoggingIn}>
          <EmailField
            value={email}
            isInvalid={errors.emailErr || errors.emailPwErr}
            errMsg={errors.emailErr}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange(e, setEmail)
            }
            required
          />

          <Form.Group>
            <PasswordField
              label="Password*"
              name="password"
              value={password}
              isInvalid={errors.passwordErr || errors.emailPwErr}
              errMsg={errors.passwordErr}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange(e, setPassword)
              }
            />
            <Form.Control.Feedback
              className={errors.emailPwErr ? 'show' : ''}
              type="invalid"
            >
              {errors.emailPwErr}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="flex flex-col sm:flex-row gap-4">
            <div className="w-full sm:w-1/2">
              <Form.Check id="check-api-checkbox">
                <Form.Check.Input
                  checked={isKeepSignIn}
                  onChange={(e) => setIsKeepSignIn(e.target.checked)}
                />
                <Form.Check.Label className="text-white">
                  Keep me logged in
                </Form.Check.Label>
              </Form.Check>
            </div>

            {/* note: temp removed as the functionality is not implemented yet */}
            {/* <div className="w-full sm:w-1/2">
              <div
                onClick={() => onClickForgotPw(email)}
                className="sm:float-right text-primary-900 underline hover:opacity-60 cursor-pointer"
              >
                Forgot password?
              </div>
            </div> */}
          </div>

          <SubmitBtn
            title="Log In"
            isProcessing={isLoggingIn}
            className="w-full"
          />
        </fieldset>
      </Form>

      <div className="text-white">
        Don't have an account? <Link to={URL.ENTER_EMAIL}>Sign up</Link>
      </div>

      <Modal
        show={showResetPwModal}
        size="lg"
        onHide={() => setShowResetPwModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot your password?</Modal.Title>
        </Modal.Header>

        <Modal.Body className="flex flex-col gap-4">
          <div>
            Enter your email address and we will send you instructions to reset
            your password.
          </div>

          <Form noValidate onSubmit={onSubmitForgotPw}>
            <EmailField
              name="forgotPwEmail"
              value={forgotPwEmail}
              isInvalid={errors.forgotPwEmailErr}
              errMsg={errors.forgotPwEmailErr}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange(e, setForgotPwEmail)
              }
              required
            />

            <div className="text-sm italic">
              By clicking 'Continue' you give consent to send email to the
              provided email address.
            </div>

            <Button onClick={() => {}} type="submit" className="w-full">
              Continue
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </OnboardingPage>
  );
};

export default Login;
