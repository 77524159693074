import React, { useState, useEffect, useMemo, useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useParams } from 'react-router-dom';

import { getThumbnail } from '../../Utils';

import { trackAttendeeClickRemoveWatermark } from '../../analytics';

import Logo from '../../components/logo';
import ErrorModal from '../../components/modals/errorModal';
import PageSpinner from '../../components/pageSpinner';
import ShareFooter from '../../components/shareFooter';

import { URL, S3_BUCKET_URL } from '../../constants';

import { getCreationWithData } from '../../services/api/creations';
import { getEventPublic } from '../../services/api/events';
import { updateOneFace } from '../../services/api/mongodb';
import { incrementViewedMetric } from '../../services/api/videoCreationMetrics';

import PaymentModal from './paymentModal';
import VideoDetails from './videoDetails';

const isTouchDevice = isMobile || isTablet;

const ShareVideo = () => {
  const { eventId, faceId } = useParams();

  const queryParameters = new URLSearchParams(window.location.search);
  const payment_success = queryParameters.get('payment_success') === 'true';

  const [event, setEvent] = useState(null);
  const [person, setPerson] = useState(null);
  const [isPlayed, setIsPlayed] = useState(false);
  const [paymentModalState, setPaymentModalState] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  const videoRef = useRef(null);

  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (payment_success) await handleAttendeePayment();

      await fetchVideoData();

      setIsLoading(false);
    };

    onMount();
  }, [eventId, faceId, payment_success]);

  const handleAttendeePayment = async () => {
    try {
      await updateOneFace({ eventId, elementId: faceId, isUpdatePaid: true });

      setPaymentModalState('confirmation');

      window.history.pushState({}, '', `${URL.SHARE}${eventId}/${faceId}`);
    } catch {
      setError({
        subheading: 'An issue occurred while updating your information',
        isContactUs: true,
      });
    }
  };

  const fetchVideoData = async () => {
    try {
      const event = await getEventPublic(eventId);
      const person = await getCreationWithData(eventId, faceId);

      if (!event || !person) setPageError(404);

      setEvent(event);
      setPerson(person);
    } catch (err) {
      console.error(err);
      setError({ subheading: 'Error fetching the video' });
    }
  };

  const { face, creation, metrics, videoUrl } = useMemo(() => {
    return {
      face: person?.face ?? {},
      creation: person?.creations[0] ?? {},
      metrics: person?.metrics ?? {},
      videoUrl: `${S3_BUCKET_URL}.files${event?.paid || person?.face?.paid ? person?.finalVideoUri : person?.previewVideoUri}`,
    };
  }, [event, person]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const handlePlayVideo = async () => {
        await incrementViewedMetric(metrics.metricId);

        // open video in full screen on mobile
        if (isTouchDevice && !isPlayed) {
          var video = document.getElementById('video');

          if (video.requestFullscreen) video.requestFullscreen();
          else if (video.webkitRequestFullscreen)
            video.webkitRequestFullscreen();
          else if (video.msRequestFullScreen) video.msRequestFullScreen();

          setIsPlayed(true);
        }
      };

      videoElement.addEventListener('play', handlePlayVideo);
      return () => {
        videoElement.removeEventListener('play', handlePlayVideo);
      };
    }
  }, [isPlayed, metrics.metricId]);

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`${face.name ?? 'Name'} at ${event?.name ?? 'Event'}`}
      description={`Personalized highlight reel of ${face.name} at ${event?.name}`}
      noTips
      isFullPage
      isPageContainer
      className="mt-[5vw] sm:mt-7 py-0"
    >
      <div className="flex flex-col px-5 sm:px-20 py-7 gap-4 sm:gap-8 bg-grey-100 text-center rounded-md shadow-grey-1">
        <Logo withTagLine />

        <h2 className="m-0">
          <b>{face.name ?? 'Name'}</b> at {event?.name ?? 'Event'}
        </h2>

        <video
          id="video"
          className="w-[calc(var(--page-width)_-_2.5rem)] sm:w-[calc(var(--page-width)_-_10rem)] h-[calc((var(--page-width)_-_2.5rem)_*_9_/_16)] sm:h-[calc((var(--page-width)_-_10rem)_*_9_/_16)] rounded-md"
          src={videoUrl}
          poster={creation.frameId ? getThumbnail(creation.frameId) : null}
          controls
          ref={videoRef}
        />

        <VideoDetails
          event={event}
          person={person}
          videoUrl={videoUrl}
          onClickRemoveWatermark={() => {
            trackAttendeeClickRemoveWatermark();
            setPaymentModalState('request');
          }}
        />
      </div>

      <ShareFooter type="share_creation" />

      <PaymentModal
        show={!!paymentModalState}
        onHide={() => setPaymentModalState(null)}
        event={event}
        person={person}
        isConfirmation={paymentModalState === 'confirmation'}
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error?.subheading}
        isTryAgainBtn
        isContactUs={error?.isContactUs}
      />
    </PageSpinner>
  );
};

export default ShareVideo;
