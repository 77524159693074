import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, getFileUrl, getThumbnail } from '../../../Utils';
import { sendStepCompleteEmail } from '../../../UtilsEmail';

import { trackUploadDemo, trackClickUploadMore } from '../../../analytics';

import { Alert, ProgressBar } from '../../../components';
import BackBtn from '../../../components/backBtn';
import ErrorModal from '../../../components/modals/errorModal';
import PageHeader from '../../../components/pageHeader';
import PageSpinner from '../../../components/pageSpinner';
import UploadInfoPoints from '../../../components/uploadInfoPoints';

import { DEMO_ID } from '../../../constants';

import { getCreationResults } from '../../../services/api/creations';
import { getTemplateFile } from '../../../services/api/template';

import { useEventStore } from '../../../stores/event';
import { useUserStore } from '../../../stores/user';

import DoneBtn from '../components/doneBtn';
import FileDropArea from '../components/fileDropArea';
import MobileReminderBox from '../components/mobileReminderBox';
import PageWithSideBar from '../components/pageWithSideBar';
import UploadMoreBtn from '../components/uploadMoreBtn';
import DemoComponent from './components/demoComponent';
import { getHlReelText } from './components/highlightReelText';
import HlReelSchema from './components/hlReelSchema';

const HighlightReel = () => {
  const eventId = useParams()?.eventId;
  const uploadRef = useRef(null);

  const [hlReel, setHlReel] = useState(null);
  const [uploadPct, setUploadPct] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasCreations, setHasCreations] = useState(false);

  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);
  const [uploadingError, setUploadingError] = useState(false);

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);
  const demoHlReel = demoData.hlReel;

  const assigneeData = JSON.parse(localStorage.getItem('assigneeData')) ?? {};

  const user = useUserStore((state) => state.user);
  const currentEvent = useEventStore((state) => state.currentEvent);
  const setEventId = useEventStore((state) => state.setEventId);
  const setDemoEvent = useEventStore((state) => state.setDemoEvent);

  const isPublic = useMemo(
    () => currentEvent?.isAssigneeAccess,
    [currentEvent?.isAssigneeAccess],
  );

  // set event id on mount
  useEffect(() => {
    if (isDemo) setDemoEvent(eventId);
    else setEventId(eventId, true);
  }, [eventId, isDemo, setDemoEvent, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      let pageError = currentEvent.error ?? null;

      if (
        currentEvent.isAssigneeAccess &&
        !assigneeData[currentEvent.eventId]?.accessPages.includes('hl-reel')
      )
        pageError = 403;

      if (pageError) setPageError(pageError);
      else {
        await fetchHlReelData();
        await fetchCreationsData();
      }

      setIsLoading(false);
    };

    if (currentEvent) {
      if (isDemo) {
        if (demoHlReel.isSelected) setHlReel(demoHlReel);
        setIsLoading(false);
      } else onMount();
    }
  }, [currentEvent]);

  const fetchHlReelData = async () => {
    try {
      const file = await getTemplateFile(eventId, isPublic);

      setHlReel(file);
    } catch (err) {
      console.error(`Error fetching the highlight reel: ${err}`);
      setError('Error fetching the highlight reel');
    }
  };

  const fetchCreationsData = async () => {
    try {
      const creations = await getCreationResults(eventId, isPublic);

      setHasCreations(!isEmpty(creations));
    } catch (err) {
      console.error(`Error fetching the creations: ${err}`);
      setError('Error fetching the creations');
    }
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setShowUpload(false);
    setUploadPct(0);
    setUploadingError(false);
  };

  useEffect(() => {
    let interval;

    if (isUploading && isDemo) {
      interval = setInterval(() => {
        setUploadPct((prev) => {
          if (prev >= 100) {
            const hlReel = { ...demoHlReel };
            hlReel.isSelected = true;

            setHlReel(hlReel);
            localStorage.setItem(
              'demoData',
              JSON.stringify({ ...demoData, hlReel, isHlReelUploaded: true }),
            );

            clearInterval(interval);
            handleUploadComplete();

            return 100;
          }

          return prev + 1;
        });
      }, 50);
    }

    return () => clearInterval(interval);
  }, [demoData, demoHlReel, isDemo, isUploading]);

  const handleUploadComplete = () => {
    setIsUploading(false);

    toast.success('The highlight reel is uploaded!', {
      toastId: 'highlight-reel-upload-success',
    });

    localStorage.setItem(
      'uploadData',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('uploadData')),
        isHlReelUploaded: true,
      }),
    );

    // for demo – track highlight reel upload
    if (isDemo) trackUploadDemo('hl_reel');
    else {
      fetchHlReelData();

      // send email to the user that the upload is complete
      if (!isPublic)
        sendStepCompleteEmail(user, currentEvent, 'Highlight reel upload');
    }
  };

  const handleError = (file) => {
    setUploadingError(true);
    toast.error(`${file.name} file upload failed.`);
  };

  const handleUploadMore = (e, position) => {
    setShowUpload(true);

    if (!isDemo)
      setTimeout(() => {
        uploadRef.current?.upload?.uploader?.onClick(e);
      });

    trackClickUploadMore('hl_reel', currentEvent, position);
  };

  const noHlReel = useMemo(() => {
    return (!isUploading && !hlReel) || showUpload;
  }, [isUploading, showUpload, hlReel]);

  const { heading, subheading, alertData, generatedAlertText, infoPoints } =
    getHlReelText({
      noHlReel,
      isUploading,
      hlReel,
      uploadingError,
      hasCreations,
      orientation: currentEvent?.videoMode,
      isDemo,
      isPublic,
    });

  const allowUploadMore = useMemo(() => {
    return !isDemo && !hasCreations;
  }, [hasCreations, isDemo]);

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`Highlight Reel – ${currentEvent?.name}`}
      isPageContainer
    >
      <BackBtn />

      <PageWithSideBar
        mainContent={
          <>
            <PageHeader heading={heading} subheading={subheading} />

            {noHlReel || uploadingError ? (
              // upload highlight reel view
              <React.Fragment>
                {currentEvent?.type === 'brand' && (
                  <HlReelSchema event={currentEvent} />
                )}

                <DemoComponent handleUploadStart={handleUploadStart} />

                <FileDropArea
                  type="hl-reel"
                  onDrop={handleUploadStart}
                  onProgress={(event) => {
                    setUploadPct(event.progress);
                  }}
                  onComplete={handleUploadComplete}
                  onError={handleError}
                  uploadRef={uploadRef}
                  isDemo={isDemo}
                />
              </React.Fragment>
            ) : isUploading ? (
              // uploading highlight reel view
              <div className="flex flex-col gap-4 text-center">
                <ProgressBar progress={uploadPct} />

                <div className="font-bold uppercase">Uploading</div>
              </div>
            ) : hlReel ? (
              // uploaded highlight reel view
              <React.Fragment>
                <video
                  className="w-[90vw] h-[calc(9_*_90vw_/_16)] sm:w-[calc(var(--page-width)_*_0.55)] sm:h-[calc(9_*_(var(--page-width)_*_0.55)_/_16)] bg-black rounded-md object-contain"
                  src={
                    hlReel.url ??
                    getFileUrl(hlReel.elementId, hlReel.name, null, 0.5)
                  }
                  poster={
                    hlReel.thumbnailUrl
                      ? hlReel.thumbnailUrl
                      : hlReel.frameId
                        ? getThumbnail(hlReel.frameId)
                        : null
                  }
                  controls
                />

                <div className="flex flex-col sm:flex-row gap-4">
                  <DoneBtn addClass={allowUploadMore ? 'sm:w-1/2' : ''} />

                  {allowUploadMore && (
                    <UploadMoreBtn
                      onClick={() => handleUploadMore('Bottom button')}
                    >
                      Re-Upload
                    </UploadMoreBtn>
                  )}
                </div>
              </React.Fragment>
            ) : null}
          </>
        }
        sideContent={
          <>
            {(alertData || generatedAlertText) && (
              <Alert
                alertData={alertData}
                generatedAlertText={generatedAlertText}
              />
            )}

            <UploadInfoPoints
              points={infoPoints}
              isInfo={!noHlReel && !isUploading}
            />

            {!isDemo && !isPublic && <MobileReminderBox />}
          </>
        }
        showDemoTipText={
          isDemo ? (noHlReel || isUploading ? 'upload' : 'summary') : null
        }
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </PageSpinner>
  );
};

export default HighlightReel;
