import React from 'react';
import parse from 'html-react-parser';
import { PiInfoFill, PiCheckCircleFill } from 'react-icons/pi';

const UploadInfoPoints = (props) => {
  const { points, isInfo } = props;

  return (
    <div className="flex flex-col gap-2">
      {points.map((point, index) => {
        return (
          <div className="flex flex-row items-center gap-4" key={index}>
            {isInfo ? (
              <PiInfoFill color="var(--blue-green-color)" size="1.5rem" />
            ) : (
              <PiCheckCircleFill color="var(--success-color)" size="1.5rem" />
            )}

            <div className="max-w-[calc(100%_-_2.5rem)]">{parse(point)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default UploadInfoPoints;
