import config from '../../config';
import { getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const apiKeyClient = () => getApiKeyClient(baseUrl);

export const getGeneratedVideo = async (elementId) => {
  try {
    const response = await apiKeyClient().get(
      `api/elements/video/${elementId}`,
      {
        responseType: 'blob',
      },
    );
    return response.data;
  } catch (err) {
    throw new Error(`Error getting generated video: ${err.message}`);
  }
};
