import React from 'react';
import { useParams } from 'react-router-dom';

import { URL } from '../../../../constants';

import { getShareText } from '../../components/getText';
import VBAccordion from './vbAccordion';
import VBBtn from './vbBtn';

const ShareStep = (props) => {
  const { peopleCount } = props;

  const { eventId } = useParams();

  const { shareText } = getShareText({ peopleCount });

  return (
    <VBAccordion
      heading="Share"
      subheading={shareText}
      isOpen
      isDisabled={peopleCount === 0}
    >
      <VBBtn
        type="share"
        heading="Share"
        subheading="Share via email or copy share link"
        link={`/${eventId}${URL.VB_SHARE}`}
      />
    </VBAccordion>
  );
};

export default ShareStep;
