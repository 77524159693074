import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { isEmpty, getPluralPhrase } from '../../../../Utils';

import {
  trackAnalysisClickVBInNewUploads,
  trackAnalysisDeleteClip,
} from '../../../../analytics';
import PageSubheader from '../../../../components/pageSubheader';

import { URL } from '../../../../constants';

import { fileDelete } from '../../../../services/api/files';
import { queuesDelete } from '../../../../services/api/queues';

import ThumbnailsContainer from '../../components/thumbnailsContainer';
import FailedClips from './failedClips';

const ClipsBlocks = (props) => {
  const eventId = useParams()?.eventId;

  const {
    clips,
    deletingClips,
    setDeletingClips,
    fetchClipsData,
    peopleCount,
    isShowSuccessfulClips,
  } = props;

  if (isEmpty(clips)) return null;

  // deletes file and queues associated with it
  const deleteClip = async (video) => {
    // add elementId to list of deleting clips
    setDeletingClips([...deletingClips, video.elementId]);

    await queuesDelete(video.elementId);
    await fileDelete(video.elementId);
    await fetchClipsData();
  };

  const isProcessing =
    !isEmpty(clips.convertingSelects) || !isEmpty(clips.analysingSelects);
  const hasSuccessfulClips = isProcessing || !isEmpty(clips.finishedSelects);

  return (
    <React.Fragment>
      {hasSuccessfulClips && isShowSuccessfulClips && (
        <div className="w-full flex flex-col items-center gap-4">
          <PageSubheader
            heading="Clips"
            subheading={`We are checking your clips for footage of ${getPluralPhrase(peopleCount, 'person')}.`}
          />

          {!isEmpty(clips.finishedSelects) && (
            <div className="w-full flex flex-col gap-2">
              <div className="font-bold">Done</div>

              <ThumbnailsContainer
                videos={clips.finishedSelects}
                deletingClips={deletingClips}
                deleteClip={(video) => {
                  deleteClip(video);
                  trackAnalysisDeleteClip('Done');
                }}
              />
            </div>
          )}

          {!isEmpty(clips.analysingSelects) && (
            <div className="w-full flex flex-col gap-2">
              <div className="font-bold">Analysing</div>

              <ThumbnailsContainer
                videos={clips.analysingSelects}
                deletingClips={deletingClips}
                deleteClip={(video) => {
                  deleteClip(video);
                  trackAnalysisDeleteClip('Analysing');
                }}
              />
            </div>
          )}

          {!isEmpty(clips.convertingSelects) && (
            <div className="w-full flex flex-col gap-2">
              <div className="font-bold">Converting</div>

              <ThumbnailsContainer
                videos={clips.convertingSelects}
                deletingClips={deletingClips}
                deleteClip={(video) => {
                  deleteClip(video);
                  trackAnalysisDeleteClip('Converting');
                }}
              />
            </div>
          )}
        </div>
      )}

      <FailedClips
        timedOutClips={clips.timedOutSelects}
        errorClips={clips.errorSelects}
        deletingClips={deletingClips}
        deleteClip={(video) => {
          deleteClip(video);
          trackAnalysisDeleteClip('Failed');
        }}
      />

      {!isEmpty(clips.newSelects) && (
        <div className="w-full flex flex-col items-center gap-4">
          <PageSubheader
            heading="New uploads"
            subheading={
              <>
                These clips were added after the analysis had been started. To
                include the new uploads in the final results, go back to{' '}
                <Link
                  to={`/${eventId}${URL.VIDEO_BUILDER}`}
                  onClick={trackAnalysisClickVBInNewUploads}
                >
                  Video Builder
                </Link>{' '}
                and hit 'Analyse' again.
              </>
            }
          />

          <ThumbnailsContainer
            videos={clips.newSelects}
            deletingClips={deletingClips}
            deleteClip={(video) => {
              deleteClip(video);
              trackAnalysisDeleteClip('New uploads');
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ClipsBlocks;
