import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ccLogoGradient from '../assets/cc-logo-gradient.png';
import ccLogoWhite from '../assets/cc-logo-white.png';

type Props = {
  isWhite?: boolean;
  withTagLine?: boolean;
  noLink?: boolean;
  width?: string;
  className?: string;
};
const Logo = (props: Props) => {
  const { isWhite, withTagLine, noLink, width, className } = props;

  const image = (
    <img
      src={isWhite ? ccLogoWhite : ccLogoGradient}
      alt="CrowdClip Logo"
      width={width || '128px'}
    />
  );

  const tagLine = withTagLine && (
    <div className="text-xs">Bring the joy back to video</div>
  );

  if (noLink)
    return (
      <span className={classNames(className)}>
        {image}
        {tagLine}
      </span>
    );

  return (
    <Link
      to="/"
      className={classNames('no-underline hover:opacity-100', className)}
    >
      {image}
      {tagLine}
    </Link>
  );
};

export default Logo;
