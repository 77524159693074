import React, { useState, useMemo } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getPluralPhrase, getCheckInLink } from '../../../../Utils';

import {
  trackVBSaveRemindLaterSuccess,
  trackVBSaveRemindLaterError,
  trackVBAssignModalOpen,
  trackPeopleClickCheckInLink,
} from '../../../../analytics';

import { Button, Spinner, Alert } from '../../../../components';
import PopoverTrigger from '../../../../components/popoverTrigger';
import SellingPoint from '../../../../components/sellingPoint';
import { URL } from '../../../../constants';

import { setAttendeeReviewData } from '../../../../services/api/mongodb';
import { useEventStore } from '../../../../stores/event';

import { getSelectText } from '../../components/getText';
import AssignModal from './assignModal';
import VBAccordion from './vbAccordion';
import VBBtn from './vbBtn';

const SelectStep = (props) => {
  const {
    peopleCount,
    clipsCount,
    isPeopleUploaded,
    isHlReelUploaded,
    isClipsUploaded,
    isDemo,
  } = props;

  const { eventId } = useParams();

  const currentEvent = useEventStore((state) => state.currentEvent);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [isSaving, setIsSaving] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);

  const isAttendeeReview = useMemo(() => {
    return currentEvent?.attendeeReviewData?.isAttendeeReview;
  }, [currentEvent]);

  const isAssetsUploaded = useMemo(() => {
    return isPeopleUploaded && isHlReelUploaded && isClipsUploaded;
  }, [isPeopleUploaded, isClipsUploaded, isHlReelUploaded]);

  const isDisableUpload = useMemo(() => {
    return isAttendeeReview && currentEvent.stage !== 'upload';
  }, [currentEvent.stage, isAttendeeReview]);

  const handleSaveRemindLater = async () => {
    setIsSaving(true);

    try {
      await setAttendeeReviewData({ eventId, isRemindLater: 'true' });
      await updateCurrentEvent();

      toast.success(
        "Awesome! We'll drop you an email 2 days before your release date",
      );
      trackVBSaveRemindLaterSuccess();
    } catch {
      toast.error('Something went wrong. Try again later');
      trackVBSaveRemindLaterError();
    } finally {
      setIsSaving(false);
    }
  };

  const { subheading, alertText, isAllowRemindLater } = getSelectText({
    currentEvent,
    isAssetsUploaded,
    isDisableUpload,
  });

  return (
    <VBAccordion
      heading="Select"
      subheading={subheading}
      headingBtn={
        !isDemo && (
          <Button
            variant="link"
            className="text-md font-normal"
            onClick={(e) => {
              e.stopPropagation();
              setShowAssignModal(true);
              trackVBAssignModalOpen();
            }}
          >
            Assign
          </Button>
        )
      }
      progressText={isAssetsUploaded ? 'Completed' : 'In Progress'}
      isOpen={!isAssetsUploaded}
    >
      {alertText && (
        <Alert alertData={{ variant: 'warning', text: alertText }} />
      )}

      <div className="flex items-center gap-4">
        <VBBtn
          type="people"
          heading="People"
          subheading={
            !isPeopleUploaded
              ? 'Show our AI who to make highlight reels for'
              : `<b>${getPluralPhrase(peopleCount, 'person')}</b> checked-in`
          }
          isComplete={isPeopleUploaded}
          link={!isDisableUpload && `/${eventId}${URL.VB_PEOPLE}`}
        />

        {!isDemo && !isMobile && !isTablet && (
          <React.Fragment>
            <div>or</div>

            <SellingPoint
              variant="purple"
              text={
                <div>
                  Share{' '}
                  <PopoverTrigger
                    content={
                      <Link
                        className="text-white"
                        to={getCheckInLink(eventId)}
                        onClick={() =>
                          trackPeopleClickCheckInLink('Video Builder')
                        }
                        target="_blank"
                      >
                        this link
                      </Link>
                    }
                    placement="top"
                    hidePopover={!isAttendeeReview}
                    popoverBody="Please inform your attendees of the release date you’ve set up, as they won’t be able to check in once the analysis has begun."
                  />{' '}
                  to allow people to self check-in.
                </div>
              }
              addClass="h-[calc(4.5rem_+_2px)]"
            />
          </React.Fragment>
        )}
      </div>

      <VBBtn
        type="hl-reel"
        heading="Highlight Reel"
        subheading={
          !isHlReelUploaded
            ? 'Upload a highlight reel template'
            : '<b>Highlight reel</b> template uploaded'
        }
        isComplete={isHlReelUploaded}
        link={!isDisableUpload && `/${eventId}${URL.VB_HL_REEL}`}
      />

      <VBBtn
        type="clips"
        heading="Clips"
        subheading={
          !isClipsUploaded
            ? 'Upload clips for our AI to find the best moments for each person'
            : `<b>${getPluralPhrase(clipsCount, 'clip')}</b> uploaded`
        }
        isComplete={isClipsUploaded}
        link={!isDisableUpload && `/${eventId}${URL.VB_CLIPS}`}
      />

      {isAllowRemindLater &&
        (isSaving ? (
          <Spinner addClass="m-auto" />
        ) : (
          <div>
            Afraid you might forget to upload your assets? No worries!{' '}
            <Button variant="link" onClick={handleSaveRemindLater}>
              Click here
            </Button>
            , and we’ll send you a reminder 2 days before your release date.
          </div>
        ))}

      <AssignModal
        show={showAssignModal}
        onHide={() => setShowAssignModal(false)}
      />
    </VBAccordion>
  );
};

export default SelectStep;
