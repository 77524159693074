import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { getIsShowSideNav } from '../Utils';

import { useUserStore } from '../stores/user';

const PageContainer = ({
  children,
  title = '',
  description = '',
  addClass = '',
}) => {
  const user = useUserStore((state) => state.user);
  const accessToken = useUserStore((state) => state.accessToken);

  const { hasSideNav, isOpenSideNav } = getIsShowSideNav(accessToken, user);
  const isShowSideNav = hasSideNav && isOpenSideNav;

  const contentClass = classNames(
    {
      'w-[90vw] sm:w-[var(--page-width)] flex flex-col mx-[5vw] mt-[var(--header-height)] py-6': true,
      'sm:mx-auto': !isShowSideNav,
      'sm:ml-auto sm:mr-[3.5vw]': isShowSideNav,
    },
    addClass,
  );

  return (
    <div className={contentClass}>
      {(title || description) && (
        <Helmet>
          {title && <title>{title} | CrowdClip</title>}
          {description && <meta name="description" content={description} />}
        </Helmet>
      )}

      {children}
    </div>
  );
};

export default PageContainer;
