import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TurndownService from 'turndown';

import { isEmpty, getUrl } from '../../../Utils';

import { Spinner, Button, Badge } from '../../../components';
import BackBtn from '../../../components/backBtn';
import ContentHeader from '../../../components/contentHeader';
import PageSpinner from '../../../components/pageSpinner';

import { URL } from '../../../constants';

import { updateOneEvent } from '../../../services/api/mongodb';

import { useEventStore } from '../../../stores/event';
import { useUserStore } from '../../../stores/user';

import { SGTemplate } from '../../../types';

import {
  fetchTemplates,
  getAllEmails,
  getSelectionEmails,
} from './components/Utils';
import CustomTextModal from './components/customTextModal';
import EmailPreviewModal from './components/emailPreviewModal';

const turndownService = new TurndownService();

const EmailSelection = () => {
  const { eventId, emailType } = useParams();

  const queryParameters = new URLSearchParams(window.location.search);
  const redirectUrl = queryParameters.get('redirectUrl');

  const user = useUserStore((state) => state.user);
  const setEventId = useEventStore((state) => state.setEventId);
  const currentEvent = useEventStore((state) => state.currentEvent);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [templates, setTemplates] = useState<{ [key: string]: SGTemplate }>({});
  const [previewTemplate, setPreviewTemplate] = useState<SGTemplate>({});
  const [customText, setCustomText] = useState<string>('');
  const [showCustomTextModal, setShowCustomTextModal] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [pageError, setPageError] = useState(null);

  const navigate = useNavigate();

  const emailTemplates = getSelectionEmails(emailType);
  const emailsData = currentEvent?.emailsData ?? {};

  // set currentEvent id on mount
  useEffect(() => {
    if (eventId) setEventId(eventId);
  }, [eventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (currentEvent.error) setPageError(currentEvent.error);
      else {
        const fetchedTemplates = await fetchTemplates(emailTemplates);
        setTemplates(fetchedTemplates);

        let text = '';
        if (emailType === 'creation')
          text = emailsData.creationTemplateText ?? '';
        setCustomText(text);
      }

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  const handleSelect = async (templateId: any) => {
    try {
      setIsSaving(true);

      const data: any = { eventId };
      if (emailType === 'creation') data.creationTemplateId = templateId;

      await updateOneEvent(data);
      await updateCurrentEvent();

      toast.success('Email preferences are updated');

      if (redirectUrl) navigate(redirectUrl);
    } catch {
      toast.error('Something went wrong. Try again later');
    } finally {
      setIsSaving(false);
    }
  };

  const pageTitle =
    getAllEmails(currentEvent).find(
      (e) => e.selectionUrl === window.location.pathname,
    )?.title ?? 'Email';

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title="Email Hub"
      isPageContainer
    >
      <BackBtn onClick={() => navigate(getUrl(URL.EMAIL_HUB, eventId))} />

      <div className="flex flex-col gap-8">
        <ContentHeader
          title={`${pageTitle} Selection`}
          subtitle="Choose one of the templates below"
        />

        <div className="flex flex-wrap gap-8">
          {emailTemplates.map((email: any) => {
            const template: SGTemplate = templates[email.templateId];

            if (!template) return null;

            return (
              <div className="w-full sm:w-[calc((100%_-_(2_*_2rem))_/_3)] text-center cursor-pointer">
                <div className="group relative w-full aspect-[3/2]">
                  <div className="w-full h-full absolute top-0 left-0 flex flex-col items-center justify-center gap-4 bg-black-overlay-800 text-white rounded-md transition-all duration-300 opacity-0 hover:opacity-100">
                    {email.isComingSoon ? (
                      <div>
                        <div className="text-lg font-bold">Coming Soon</div>
                        <div className="text-sm">Stay tuned</div>
                      </div>
                    ) : isSaving ? (
                      <Spinner size="xl" color="white" />
                    ) : (
                      <React.Fragment>
                        <Button
                          onClick={() => setPreviewTemplate(template)}
                          variant="white-outline"
                          isThin
                          className="w-32"
                        >
                          Preview
                        </Button>

                        <Button
                          onClick={() => handleSelect(email.templateId)}
                          variant="white-outline"
                          isThin
                          className="w-32"
                          disabled={email.isCustom && !customText}
                          allowPointerEvents
                          popoverBody="Add Text first"
                          hidePopover={!email.isCustom || !!customText}
                          isThinPopover
                        >
                          Select
                        </Button>

                        {email.isCustom && (
                          <Button
                            onClick={() => setShowCustomTextModal(true)}
                            variant="white-outline"
                            isThin
                            className="w-32"
                          >
                            {customText ? 'Edit' : 'Add'} Text
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>

                  <img
                    src={template.thumbnail_url}
                    alt="Template Thumbnail"
                    className="w-full h-full object-cover object-top rounded-md border-1 border-grey-200 border-solid"
                  />
                </div>

                <div className="flex items-center justify-center mt-2 gap-1">
                  <div className="font-bold">{email.title}</div>
                  {emailsData.creationTemplateId === email.templateId && (
                    <Badge variant="black" size="sm" text="Current" />
                  )}

                  {email.isComingSoon && (
                    <Badge variant="grey" size="sm" text="Coming Soon" />
                  )}
                </div>

                <div className="text-sm text-black/[0.5]">
                  {email.description}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <EmailPreviewModal
        show={!isEmpty(previewTemplate)}
        onHide={() => setPreviewTemplate({})}
        template={previewTemplate}
        event={currentEvent}
        user={user}
        customText={customText}
      />

      <CustomTextModal
        show={showCustomTextModal}
        onHide={() => setShowCustomTextModal(false)}
        event={currentEvent}
        emailType={emailType}
      />
    </PageSpinner>
  );
};

export default EmailSelection;
