import React, { useMemo } from 'react';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';

import { isEmpty } from '../../../../Utils';

import AnalyseSubStep from './analyseSubStep';
import GenerateSubStep from './generateSubStep';
import ReviewSubStep from './reviewSubStep';
import VBAccordion from './vbAccordion';

const LabelIcon = (props) => {
  const { error, completed, active } = props;

  if (error) return <div className="w-6 h-6 bg-error-900 rounded-full" />;
  if (completed) return <div className="w-6 h-6 bg-success-900 rounded-full" />;
  if (active) return <div className="w-6 h-6 bg-black rounded-full" />;
  return <div className="w-6 h-6 bg-grey-900 rounded-full" />;
};

const CreateStep = (props) => {
  const {
    isDisabled,
    uploadedPeopleCount,
    uploadedClipsCount,
    hlReel,
    processingClips,
    analyseStatus,
    onAnalysisStart,
    requiredShots,
    completePeople,
    incompletePeople,
    generatedPeople,
    isGenerationStarted,
    isDemo,
    handleDemoAnalysisActions,
    handleDemoGenerationActions,
  } = props;

  const isAnalysed = useMemo(() => {
    return analyseStatus === 'analysed';
  }, [analyseStatus]);

  const activeStep = useMemo(() => {
    if (!isAnalysed) return 0;
    else if (!isGenerationStarted) return 1;
    else if (isEmpty(generatedPeople)) return 2;
    else return 3;
  }, [generatedPeople, isAnalysed, isGenerationStarted]);

  const steps = [
    {
      label: 'Analyse with AI',
      content: (
        <AnalyseSubStep
          peopleCount={uploadedPeopleCount}
          clipsCount={uploadedClipsCount}
          hlReel={hlReel}
          clips={processingClips}
          analyseStatus={analyseStatus}
          onAnalysisStart={onAnalysisStart}
          isDemo={isDemo}
          handleDemoAnalysisActions={handleDemoAnalysisActions}
        />
      ),
    },
    {
      label: 'Review',
      content: isAnalysed && (
        <ReviewSubStep
          hlReel={hlReel}
          requiredShots={requiredShots}
          completePeople={completePeople}
          incompletePeople={incompletePeople}
          generatedPeople={generatedPeople}
          isGenerationStarted={isGenerationStarted}
          isDemo={isDemo}
        />
      ),
    },
    {
      label: 'Generate',
      content: isGenerationStarted && (
        <GenerateSubStep
          completePeople={completePeople}
          incompletePeople={incompletePeople}
          generatedPeople={generatedPeople}
          isDemo={isDemo}
          handleDemoGenerationActions={handleDemoGenerationActions}
        />
      ),
    },
  ];

  const isCompleted = activeStep >= steps.length;

  return (
    <VBAccordion
      heading="Create"
      subheading={
        isCompleted ? (
          <>
            Personalised videos of event attendees are generated
            {!isEmpty(processingClips?.newSelects) && (
              <>, but new clips were uploaded afterward</>
            )}
          </>
        ) : (
          <>Craft unique personalised videos for your event attendees</>
        )
      }
      progressText={isCompleted ? 'Completed' : `Step ${activeStep + 1} of 3`}
      isOpen={!isCompleted}
      isDisabled={isDisabled}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{ '.MuiStepConnector-line': { minHeight: '1rem' } }}
      >
        {steps.map((step, index) => {
          return (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={LabelIcon}
                error={step.isError}
                sx={{
                  '.MuiStepLabel-iconContainer': { paddingRight: '20px' },
                  '.MuiStepIcon-text': { display: 'none' },
                  '& .Mui-completed > *': { color: 'var(--success-color)' },
                  '& .Mui-active > *': { color: '#000 !important' },
                  '& .Mui-disabled > *': { color: 'var(--grey)' },
                  '& .Mui-error > *': { color: 'var(--error-color)' },
                }}
              >
                <div className="text-lg font-bold">{step.label}</div>
              </StepLabel>

              {index <= activeStep && (
                <StepContent
                  sx={{ paddingLeft: '2rem', paddingRight: '0' }}
                  TransitionProps={{ in: true }}
                >
                  <div className="flex flex-col items-start gap-4">
                    {step.content}
                  </div>
                </StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
    </VBAccordion>
  );
};

export default CreateStep;
