import React, { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import config from '../src/config';

import { PrivateRoute, PublicRoute } from './ProtectedRoute';

import { goTo, getIsShowSideNav, isProduction } from './Utils';

import { initializeTracker, setUserID } from './analytics';

import Header from './components/header/header';
import PageSpinner from './components/pageSpinner';
import SideNav from './components/sideNav/sideNav';

import { URL } from './constants';

import Analytics from './pages/Analytics/analytics';
import Brand from './pages/Brand/brand';

import CheckinStage from './pages/CheckIn/components/checkinStage';
import EventCheckIn from './pages/CheckIn/eventCheckIn';

import EmailHub from './pages/Dashboard/EmailsHub/emailHub';
import EmailSelection from './pages/Dashboard/EmailsHub/emailSelection';
import Home from './pages/Dashboard/Home/home';

import EventBuilder from './pages/EventBuilder/eventBuilder';

import Error from './pages/General/error';
import PaymentRedirect from './pages/General/paymentRedirect';

import Help from './pages/Help/help';

import ManagedServices from './pages/ManagedServices/managedServices';

import CreateAccount from './pages/Onboarding/createAccount';
import EmailConfirmation from './pages/Onboarding/emailConfirmation';
import EnterEmail from './pages/Onboarding/enterEmail';
import Login from './pages/Onboarding/login';
import ResetPassword from './pages/Onboarding/resetPassword';

import ReviewVideo from './pages/ReviewVideo/reviewVideo';
import ReviewVideoGenerating from './pages/ReviewVideo/reviewVideoGenerating';

import ShareHub from './pages/Share/shareHub';
import ShareVideo from './pages/Share/shareVideo';

import Analysis from './pages/VideoBuilder/Analysis';
import AssigneeVB from './pages/VideoBuilder/AssigneeVB';
import Clips from './pages/VideoBuilder/Clips';
import HighlightReel from './pages/VideoBuilder/HighlightReel';
import People from './pages/VideoBuilder/People';
import Share from './pages/VideoBuilder/Share';
import VideoBuilder from './pages/VideoBuilder/VB';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.css';
import './styles/animation.css';

import { getUserData } from './services/api/auth';
import { getUserEvents } from './services/api/events';
import { getSessionFromToken } from './services/api/sessions';
import { useEventStore } from './stores/event';
import { useUserStore } from './stores/user';

function App() {
  let pathname = window.location.pathname;

  const user = useUserStore((state) => state.user);
  const accessToken = useUserStore((state) => state.accessToken);
  const updateUser = useUserStore((state) => state.setUser);
  const setAccessToken = useUserStore((state) => state.setAccessToken);
  const clearAccessToken = useUserStore((state) => state.clearAccessToken);
  const setUserEvents = useEventStore((state) => state.setUserEvents);

  const { hasSideNav, isOpenSideNav } = getIsShowSideNav(accessToken, user);
  const isShowSideNav = hasSideNav && isOpenSideNav;

  const [isOpenSideNavState, setIsOpenSideNavState] = useState(isOpenSideNav);
  const [isLoading, setIsLoading] = useState(true);

  const toggleSideNav = () => {
    setIsOpenSideNavState(!isOpenSideNavState);
    localStorage.setItem('isOpenSideNav', !isOpenSideNavState);
  };

  const fetchUserData = async () => {
    const session = await getSessionFromToken(accessToken);
    const userData = await getUserData(session.userId);

    const userEvents = await getUserEvents(session.userId);
    setUserEvents(userEvents);

    const user = { ...userData, session };
    updateUser(user);
  };

  useEffect(() => {
    if (user == null && accessToken) {
      fetchUserData()
        .then(() => {
          setAccessToken(accessToken);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          updateUser(null);
          clearAccessToken();
          goTo(URL.ENTER_EMAIL);
        });
    } else {
      setIsLoading(false);
    }
  }, [user, accessToken]);

  useEffect(() => {
    initializeTracker(config, user && user.userId);

    if (user) setUserID(user.userId);
  }, [user]);

  // if page is loaded on mobile – close side nav
  useEffect(() => {
    if (isMobile || isTablet) {
      setIsOpenSideNavState(false);
      localStorage.setItem('isOpenSideNav', false);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (isProduction()) {
      window?.fbq('track', 'PageView');
      hotjar.initialize({ id: config.hotjarID });
    }
  }, []);

  useEffect(() => {
    if (user && isProduction()) {
      ReactPixel.pageView();
    }
  }, [user]);

  if (isLoading) {
    return <PageSpinner isLoading noTips isFullPage />;
  }

  return (
    <div>
      <div className="app">
        {![
          URL.SHARE,
          URL.CHECK_IN,
          URL.CHECK_IN_STAGE,
          URL.PAYMENT_REDIRECT,
        ].reduce((acc, url) => acc || pathname.includes(url), false) && (
          <Header hasSideNav={hasSideNav} toggleSideNav={toggleSideNav} />
        )}

        {isShowSideNav && <SideNav />}

        {/* overlay on the right of side nav is open on mobile */}
        {(isMobile || isTablet) && isShowSideNav && (
          <div
            className="absolute w-screen h-screen bg-black-overlay-600 z-10"
            onClick={toggleSideNav}
          />
        )}

        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />

          {/* onboarding */}
          <Route
            path={URL.ENTER_EMAIL}
            element={
              <PublicRoute>
                <EnterEmail />
              </PublicRoute>
            }
          />
          <Route
            path={URL.CREATE_ACCOUNT}
            element={
              <PublicRoute>
                <CreateAccount />
              </PublicRoute>
            }
          />
          <Route
            path={URL.LOGIN}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={URL.RESET_PASSWORD}
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path={URL.EMAIL_VERIFICATION}
            element={
              <PublicRoute>
                <EmailConfirmation type="verification" />
              </PublicRoute>
            }
          />
          <Route
            path={URL.FORGOT_PASSWORD}
            element={
              <PublicRoute>
                <EmailConfirmation type="reset-password" />
              </PublicRoute>
            }
          />

          {/* video builder */}
          <Route
            path={`/:eventId${URL.VIDEO_BUILDER}`}
            element={
              <PrivateRoute>
                <VideoBuilder />
              </PrivateRoute>
            }
          />
          <Route
            path={`/:eventId${URL.VB_ASSIGNEE}`}
            element={<AssigneeVB />}
          />
          <Route
            path={`/:eventId${URL.VB_PEOPLE}`}
            element={
              <PrivateRoute allowAssignee>
                <People />
              </PrivateRoute>
            }
          />
          <Route
            path={`/:eventId${URL.VB_HL_REEL}`}
            element={
              <PrivateRoute allowAssignee>
                <HighlightReel />
              </PrivateRoute>
            }
          />
          <Route
            path={`/:eventId${URL.VB_CLIPS}`}
            element={
              <PrivateRoute allowAssignee>
                <Clips />
              </PrivateRoute>
            }
          />
          <Route
            path={`/:eventId${URL.VB_ANALYSIS}`}
            element={
              <PrivateRoute>
                <Analysis />
              </PrivateRoute>
            }
          />
          <Route
            path={`/:eventId${URL.VB_SHARE}`}
            element={
              <PrivateRoute>
                <Share />
              </PrivateRoute>
            }
          />

          {/* share pages */}
          <Route path={`${URL.SHARE}:eventId`} element={<ShareHub />} />
          <Route path="/share/:eventId/:faceId" element={<ShareVideo />} />
          {/* Unable to navigate with constant URL, will investigate later */}
          {/* <Route
          path={`${URL.SHARE}:eventId/:faceId}`}
          element={<ShareVideo />}
        /> */}

          {/* check in */}
          <Route path={`${URL.CHECK_IN}:eventId`} element={<EventCheckIn />} />
          <Route
            path={`${URL.CHECK_IN}:eventId/:faceId`}
            element={<EventCheckIn />}
          />
          <Route path={URL.CHECK_IN_STAGE} element={<CheckinStage />} />

          {/* attendee review video */}
          <Route
            path={`${URL.REVIEW_VIDEO}:faceId`}
            element={<ReviewVideo />}
          />
          <Route
            path={`${URL.REVIEW_VIDEO_GENERATING}:faceId`}
            element={<ReviewVideoGenerating />}
          />

          {/* dashboard */}
          <Route
            path={`${URL.DASH_EVENT_BUILDER}`}
            element={
              <PrivateRoute>
                <EventBuilder />
              </PrivateRoute>
            }
          />
          <Route
            path={`${URL.EMAIL_HUB}:eventId`}
            element={
              <PrivateRoute>
                <EmailHub />
              </PrivateRoute>
            }
          />
          <Route
            path={`${URL.EMAIL_HUB}:eventId/:emailType`}
            element={
              <PrivateRoute>
                <EmailSelection />
              </PrivateRoute>
            }
          />
          <Route
            path={URL.DASH_BRAND}
            element={
              <PrivateRoute>
                <Brand />
              </PrivateRoute>
            }
          />
          <Route
            path={URL.DASH_ANALYTICS}
            element={
              <PrivateRoute>
                <Analytics />
              </PrivateRoute>
            }
          />

          <Route path={URL.HELP} element={<Help />} />

          <Route path={URL.MANAGED_SERVICES} element={<ManagedServices />} />
          <Route
            path={`${URL.MANAGED_SERVICES}:eventId`}
            element={<ManagedServices />}
          />

          <Route path={URL.PAYMENT_REDIRECT} element={<PaymentRedirect />} />

          {/* all non-existent URLs */}
          <Route path="*" element={<Error code={404} />} />
        </Routes>

        <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={5000} />
      </div>
    </div>
  );
}

export default App;
