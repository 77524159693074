import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, getPluralPhrase, getDemoSelected } from '../../../../Utils';

import {
  trackAnalysisStartSuccess,
  trackAnalysisStartError,
  trackAnalysisClickSeeProgress,
} from '../../../../analytics';

import { ProgressBar } from '../../../../components';
import FancyBtn from '../../../../components/fancyBtn';
import SellingPoint from '../../../../components/sellingPoint';

import { URL } from '../../../../constants';

import { analyseAll } from '../../../../services/api/analyse';
import { useEventStore } from '../../../../stores/event';

import { getAnalyseText } from '../../components/getText';

const AnalyseSubStep = ({
  peopleCount,
  clipsCount,
  hlReel,
  clips,
  analyseStatus,
  onAnalysisStart,
  isDemo,
  handleDemoAnalysisActions,
}) => {
  const { eventId } = useParams();

  const queryParameters = new URLSearchParams(window.location.search);
  const startAnalysis = queryParameters.get('startAnalysis') === 'true';

  const currentEvent = useEventStore((state) => state.currentEvent);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [isShowAnalyseBtn, setIsShowAnalyseBtn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isAnalysed = useMemo(() => {
    return analyseStatus === 'analysed';
  }, [analyseStatus]);

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const [demoStatus, setDemoStatus] = useState(
    isAnalysed ? analyseStatus : 'not-analysed',
  );
  const [demoHlReel, setDemoHlReel] = useState(
    isAnalysed ? { ...demoData.hlReel, shots: [] } : demoData.hlReel ?? {},
  );

  const { demoSelectedClips, demoAnalysedClips } = useMemo(() => {
    const allSelectedClips = getDemoSelected(demoData.clips);

    const demoSelectedClips = allSelectedClips.filter(
      (clip) => !clip.isAnalysed,
    );
    const demoAnalysedClips = allSelectedClips.filter(
      (clip) => clip.isAnalysed,
    );

    return { demoSelectedClips, demoAnalysedClips };
  }, [demoData.clips]);

  const [demoClips, setDemoClips] = useState({
    newSelects: demoStatus !== 'analysing' ? demoSelectedClips : [],
    convertingSelects: [],
    analysingSelects: [],
    finishedSelects: demoAnalysedClips,
    timedOutSelects: [],
    errorSelects: [],
  });

  const handleDemoAnalysisProgress = useCallback(() => {
    setDemoClips((prevClips) => {
      const nextClips = { ...prevClips };

      // move from converting to analysing
      if (!isEmpty(nextClips.convertingSelects)) {
        nextClips.analysingSelects.push(nextClips.convertingSelects.shift());
      }

      // move from analysing to finished
      else if (!isEmpty(nextClips.analysingSelects)) {
        const clip = nextClips.analysingSelects.shift();
        clip.isAnalysed = true;
        nextClips.finishedSelects.push(clip);
      }

      // all clips are 'analysed'
      else {
        clearInterval();

        // finish 'analysing' highlight reel
        setDemoHlReel({ ...demoHlReel, shots: [] });

        setDemoStatus('analysed');
        demoData.isAssetsProcessed = true;
        // before updating clips in demoData – concat finishedSelects with unselected clips to get all clips
        demoData.clips = nextClips.finishedSelects.concat(
          getDemoSelected(demoData.clips, true),
        );

        handleDemoAnalysisActions(demoData, true);
      }

      return nextClips;
    });
  }, [demoData, demoHlReel, handleDemoAnalysisActions]);

  const imitateAnalysing = useCallback(() => {
    setIsShowAnalyseBtn(false);
    setDemoStatus('analysing');
    demoData.event.stage = 'analysis';
    demoData.isAssetsProcessed = false;

    handleDemoAnalysisActions(demoData);

    setDemoClips((prev) => ({
      ...prev,
      newSelects: [],
      convertingSelects: demoSelectedClips,
    }));

    const interval = setInterval(handleDemoAnalysisProgress, 1000);
    return () => clearInterval(interval);
  }, [demoSelectedClips, demoData, handleDemoAnalysisProgress]);

  useEffect(() => {
    if (startAnalysis) {
      onClickAnalyse();
      window.history.pushState(null, '', URL.VIDEO_BUILDER);
    }
  }, [startAnalysis]);

  const onSuccessfulAnalysisStart = () => {
    trackAnalysisStartSuccess(currentEvent, startAnalysis);

    // remove eventId from localStorage arrays (for GA)
    ['analysedProjects', 'clipsWarningProjects'].forEach((key) => {
      const items = JSON.parse(localStorage.getItem(key)) ?? [];
      localStorage.setItem(
        key,
        JSON.stringify(items.filter((id) => id !== eventId)),
      );
    });
  };

  const onClickAnalyse = async () => {
    setIsSubmitting(true);

    // demo event
    if (isDemo) {
      // reset the clips & hl reel
      setDemoClips({
        ...demoClips,
        convertingSelects: demoSelectedClips,
        finishedSelects: [],
      });
      setDemoHlReel({ ...demoData.hlReel });

      setTimeout(() => {
        onSuccessfulAnalysisStart();
        imitateAnalysing();
        setIsSubmitting(false);
      }, 500);
    }

    // usual event
    else {
      onAnalysisStart();

      const { success, errMsg } = await analyseAll(eventId);

      if (success) {
        onSuccessfulAnalysisStart();
        await updateCurrentEvent();
      } else {
        trackAnalysisStartError(errMsg);
        if (errMsg) toast.error(errMsg);
      }

      setIsSubmitting(false);
    }
  };

  const { analyseText, progress } = getAnalyseText({
    hlReel: isDemo ? demoHlReel : hlReel,
    clips: isDemo ? demoClips : clips,
    peopleCount,
  });

  const status = useMemo(
    () =>
      isShowAnalyseBtn ? 'not-analysed' : isDemo ? demoStatus : analyseStatus,
    [analyseStatus, demoStatus, isDemo, isShowAnalyseBtn],
  );

  switch (status) {
    case 'not-analysed':
      return (
        <React.Fragment>
          <div>
            Analyse <b>{getPluralPhrase(clipsCount, 'clip')}</b> for{' '}
            <b>{getPluralPhrase(peopleCount, 'person')}</b>. Our AI will look
            for the best clips to add to your Highlight Reels wildcard shots.
          </div>

          <FancyBtn
            text="Analyse"
            isSubmitting={isSubmitting}
            onClick={onClickAnalyse}
          />
        </React.Fragment>
      );

    case 'analysing':
      return (
        <React.Fragment>
          <SellingPoint text={analyseText} />

          <div className="w-full flex flex-col gap-4">
            <ProgressBar progress={progress} />

            <div className="text-center">
              <div className="font-bold uppercase">Analysing</div>

              {!isDemo && (
                <Link
                  to={`/${eventId}${URL.VB_ANALYSIS}`}
                  onClick={trackAnalysisClickSeeProgress}
                >
                  See progress
                </Link>
              )}
            </div>
          </div>
        </React.Fragment>
      );

    case 'analysed':
      return (
        <div>
          {analyseText}{' '}
          {(!isDemo || !demoData.isLimited) && (
            <span
              className="underline cursor-pointer text-primary-900 hover:opacity-80"
              onClick={() => setIsShowAnalyseBtn(true)}
            >
              Analyse again
            </span>
          )}
        </div>
      );
    default:
      return null;
  }
};

export default AnalyseSubStep;
