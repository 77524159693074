import React from 'react';
import { Check, ExclamationMark } from '@phosphor-icons/react';

import { Spinner } from '../';

const StatusIcon = (props) => {
  const { status } = props;

  if (status === 'UPLOADED') return null;

  const iconClass =
    'absolute w-full h-full flex items-center justify-center bg-white rounded-full transition-all duration-500';

  return (
    <div className="absolute w-6 h-6">
      {/* check icon */}
      <div
        className={
          `${iconClass} text-success-900 ` +
          (status === 'PROCESSED' ? 'opacity-100' : 'opacity-0')
        }
      >
        <Check />
      </div>

      {/* error icon */}
      <div
        className={
          `${iconClass} text-error-900 ` +
          (status === 'ERROR' ? 'opacity-100' : 'opacity-0')
        }
      >
        <ExclamationMark />
      </div>

      {/* spinner */}
      <Spinner
        color="white"
        className={`transition-all duration-500 ${
          !['PROCESSED', 'ERROR'].includes(status) ? 'opacity-100' : 'opacity-0'
        }`}
      />
    </div>
  );
};

export default StatusIcon;
