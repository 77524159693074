import { getFileImg } from '../Utils';
import { getAttendeeFaceById } from './api/faces';
import { getAttendeeFacePhotos, getAttendeeClips } from './api/files';

export const getAttendeeReviewData = async (faceId) => {
  let face = await getAttendeeFaceById(faceId);

  if (!face) return { person: null, eventId: null };

  const photos = await getAttendeeFacePhotos(face.eventId, faceId);
  const clips = await getAttendeeClips(faceId);

  let matchingClips = [];

  clips.forEach((clip) => {
    // check if this clip has a person with a matching faceId
    let matchingPersonScore = (clip.personsScore || []).find(
      (personScore) => personScore.faceId === face.elementId,
    );

    if (matchingPersonScore) {
      matchingClips.push({
        ...clip,
        matchingScore: matchingPersonScore.score,
      });
    }
  });

  // sort the matching clips by personScore in descending order
  matchingClips.sort(
    (clipA, clipB) => clipB.matchingScore - clipA.matchingScore,
  );

  return {
    person: { face, img: getFileImg(photos[0]), clips: matchingClips },
    eventId: face.eventId,
  };
};
