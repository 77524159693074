import config from '../../config';

const { stripeApiKey } = config;

const stripe = require('stripe')(stripeApiKey);

export const retrieveSession = async (sessionId) => {
  try {
    const session = await stripe.checkout.sessions.retrieve(sessionId);

    return session;
  } catch (err) {
    throw new Error(`Error retrieving session: ${err.message}`);
  }
};
