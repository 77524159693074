import React from 'react';

import { getFileUrl } from '../../../Utils';

const ClipPreview = ({ video, addClass }) => {
  if (
    !video.segment &&
    !['PROCESSED', 'UPLOADED'].includes(video.status) &&
    !video.url
  )
    return null;

  let fileId = video.elementId;
  let fileName = video.name;
  let startTime = 0;
  let endTime = video.parameters?.length || 0;

  if (video.segment) {
    const fileNameParts = video.name.split('@');
    fileId = video.parentElementId;
    fileName = fileNameParts[0] + '@' + fileNameParts[1] + '.mp4';
    startTime = Math.round(video.segment.startTimestampMilliseconds / 1000);
    endTime = Math.round(video.segment.endTimestampMilliseconds / 1000);
  }

  return (
    <video
      ref={(videoElement) => {
        if (videoElement && endTime !== 0) {
          videoElement.currentTime = startTime;
          videoElement.addEventListener('timeupdate', () => {
            if (videoElement.currentTime >= endTime) {
              videoElement.currentTime = startTime;
            }
          });
        }
      }}
      src={video.url ?? getFileUrl(fileId, fileName)}
      className={`${addClass} absolute top-0 left-0 z-1 hidden group-hover:block`}
      autoPlay
      loop
      muted
      playsInline
      preload="meta"
    />
  );
};

export default ClipPreview;
