import config from '../../config';
import { getAccessTokenClient, getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { queues: baseUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);
const apiKeyClient = () => getApiKeyClient(baseUrl);

export const queuesDelete = async (elementId, isPublic = false) => {
  try {
    await (isPublic ? apiKeyClient : accessTokenClient)().delete(
      `api/queues/${elementId}`,
    );
  } catch (err) {
    throw new Error(`Error deleting queues: ${err.message}`);
  }
};
