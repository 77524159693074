import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

const CheckinStepper = (props) => {
  const { activeStep } = props;

  const steps = ['Check-in', 'Upload'];

  return (
    <Stepper className="w-full" activeStep={activeStep} alternativeLabel>
      {steps.map((step, index) => {
        return (
          <Step
            key={index}
            sx={{
              '.MuiStepConnector-root': {
                top: '1rem',
                left: 'calc(-50% + 25px)',
                right: 'calc(50% + 25px);',
              },
            }}
          >
            <StepLabel
              sx={{
                '.MuiStepIcon-root': { fontSize: '2rem' },
                '.MuiStepLabel-label': { fontSize: '1rem', color: '#000' },
                '.Mui-completed': { color: 'var(--success-color) !important' },
                '.Mui-active': { color: 'var(--primary-color) !important' },
                '.Mui-disabled > svg': { color: 'var(--light-grey-2)' },
              }}
            >
              {step}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default CheckinStepper;
