import React, { useState, useMemo } from 'react';
import {
  PiShareFat,
  PiDownloadSimple,
  PiArrowsOut,
  PiArrowsIn,
} from 'react-icons/pi';
import { Link } from 'react-router-dom';

import {
  isEmpty,
  isPlural,
  getPluralPhrase,
  getShareLink,
} from '../../../../Utils';

import {
  trackShareOpenShareHubModal,
  trackShareDownload,
  trackShareToggleVideoSize,
  trackShareReOpenWorkflow,
  trackDemoStartAgain,
} from '../../../../analytics';

import { Button } from '../../../../components';
import PageHeader from '../../../../components/pageHeader';
import { zipAndDownloadVideos } from '../../../../components/videoDownload';

import { URL, DEMO_ID } from '../../../../constants';

import { demoFifa } from '../../components/demoData';

import ShareHubModal from './shareHubModal';

const SharePageHeader = (props) => {
  const { eventId, event, people, isIncreased, setIsIncreased } = props;

  const [isDownloading, setIsDownloading] = useState(false);
  const [showShareHubModal, setShowShareHubModal] = useState(false);

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);
  const hubId = isDemo ? demoData.event.eventId : eventId;

  const resetDemo = () => {
    localStorage.setItem('demoData', JSON.stringify(demoFifa));
    trackDemoStartAgain('Share');
  };

  const handleDownloadAll = async () => {
    setIsDownloading(true);
    let videoUrls = [];

    people.forEach((person) => {
      videoUrls.push({ url: person.videoUrl, faceName: person.face.name });
    });

    await zipAndDownloadVideos(event.name, videoUrls);

    trackShareDownload('hub', event);

    setIsDownloading(false);
  };

  const iconBtns = [
    {
      icon: <PiShareFat size="1.25rem" />,
      onClick: () => {
        setShowShareHubModal(true);
        trackShareOpenShareHubModal(event);
      },
      popoverHeader: 'Share Hub Page',
      popoverBody:
        'Hub Page has all personalised highlight reels in a single view',
      show: true,
    },
    {
      icon: <PiDownloadSimple size="1.25rem" />,
      onClick: () => handleDownloadAll(),
      isProcessing: isDownloading,
      popoverHeader: 'Download all',
      popoverBody: 'All generated videos in one ZIP file',
      show: true,
    },
    {
      icon: isIncreased ? (
        <PiArrowsIn size="1.25rem" />
      ) : (
        <PiArrowsOut size="1.25rem" />
      ),
      onClick: () => {
        setIsIncreased(!isIncreased);
        trackShareToggleVideoSize(event, !isIncreased);
      },
      popoverHeader: isIncreased ? 'Reduce' : 'Increase',
      popoverBody: isIncreased
        ? 'Reduce the video size for an easy overview of all videos at a glance'
        : 'Increase the video size for an easy review of each generated video',
      show: window.innerWidth >= 640,
    },
  ];

  return (
    <React.Fragment>
      <PageHeader
        heading={`Share ${event && ' – ' + event.name}`}
        subheading={
          <>
            Personalised Highlight Reel{isPlural(people)}{' '}
            {isPlural(people) ? 'are' : 'is'} ready to share with{' '}
            {getPluralPhrase(people, 'person')}!{' '}
            {isDemo ? (
              <span>
                Want to try the sample project again?{' '}
                <Link
                  to={`/${eventId}${URL.VIDEO_BUILDER}`}
                  onClick={resetDemo}
                >
                  Re-start the workflow
                </Link>
              </span>
            ) : (
              <span>
                Want to generate reels for more people?{' '}
                <Link
                  to={`/${eventId}${URL.VIDEO_BUILDER}`}
                  onClick={trackShareReOpenWorkflow}
                >
                  Re-open the workflow
                </Link>
              </span>
            )}
          </>
        }
        icons={
          !isEmpty(people) && (
            <div className="flex">
              {iconBtns.map(
                (btn) =>
                  btn.show && (
                    <Button
                      onClick={btn.onClick}
                      variant="transparent-black"
                      form="square"
                      isThin
                      isSubmitBtn
                      isProcessing={btn.isProcessing}
                      spinnerSize="sm"
                      spinnerColor="black"
                      spinnerHoverColor="white"
                      popoverHeader={btn.popoverHeader}
                      popoverBody={btn.popoverBody}
                      popoverPlacement="bottom"
                    >
                      {btn.icon}
                    </Button>
                  ),
              )}
            </div>
          )
        }
      />

      {/* share hub modal */}
      <ShareHubModal
        show={showShareHubModal}
        onHide={() => setShowShareHubModal(false)}
        hubLink={getShareLink(hubId)}
        isDemo={isDemo}
      />
    </React.Fragment>
  );
};

export default SharePageHeader;
