import React from 'react';
import { PiArrowLeft } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { getVBLinkParts } from '../Utils';
import { trackClickBackButton } from '../analytics';
import { URL } from '../constants';
import { useEventStore } from '../stores/event';

const BackBtn = ({ onClick }: { onClick?: () => void }) => {
  const currentEvent = useEventStore((state) => state.currentEvent);

  const navigate = useNavigate();

  const openVB = () => {
    const { eventId, page } = getVBLinkParts(window.location.pathname);

    navigate(
      `/${eventId}${currentEvent.isAssigneeAccess ? URL.VB_ASSIGNEE : URL.VIDEO_BUILDER}`,
    );

    trackClickBackButton(page, currentEvent);
  };

  return (
    <button
      onClick={() => (onClick ? onClick() : openVB())}
      className="w-min flex items-center p-2 mb-2 gap-1 rounded-md hover:bg-grey-100"
    >
      <PiArrowLeft size={20} />
      <div className="uppercase">Back</div>
    </button>
  );
};

export default BackBtn;
