import { saveAs } from 'file-saver';
import JSZip from 'jszip';

export const fetchVideo = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

export const zipAndDownloadVideos = async (eventName, videoUrls) => {
  const zip = new JSZip();
  const videoFolder = zip.folder(`${eventName} videos`);

  const fetchPromises = videoUrls.map(async (video, index) => {
    const videoBlob = await fetchVideo(video.url);
    videoFolder.file(
      `${index + 1}) CrowdClip ${video.faceName} video.mp4`,
      videoBlob,
    );
  });

  await Promise.all(fetchPromises);

  const zipBlob = await zip.generateAsync({ type: 'blob' });

  saveAs(zipBlob, `${eventName} videos.zip`);
};

export const downloadVideo = async (videoUrl, faceName) => {
  try {
    const videoBlob = await fetchVideo(videoUrl);
    const url = window.URL.createObjectURL(videoBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `CrowdClip ${faceName} video.mp4`;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  }
};
