import React from 'react';
import { Modal, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { Button } from '../../components';

import withRouter from '../../withRouter';

const CaptionModal = (props) => {
  const { show, onHide, event, handleNativeShare, isCaptionOnly } = props;

  if (!event) return null;

  const caption = event.caption;
  const hashtag = event.hashtag;

  // copy text to clipboard
  const handleCopy = (copyText) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        toast.success('Copied to clipboard!', { toastId: 'copy-success' });
      })
      .catch((error) => {
        console.error('Failed to copy:', error);
      });
  };

  // text of hashtag with #
  const hashtagText = hashtag
    .map((tag) => {
      return '#' + tag;
    })
    .join(' ');

  // text that user will copy
  const copyText = caption + '\n\n' + hashtagText;

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Body className="flex flex-col gap-4 text-center">
        <h4 className="m-0">Use pre-generated caption?</h4>

        <div>
          No need to get stuck on what to write. Simply use the generated
          hashtags and caption below to make your post stand out.
        </div>

        <InputGroup.Text className="w-full h-32 sm:h-20 flex flex-col items-start text-left text-sm whitespace-pre-wrap overflow-x-auto overflow-y-scroll rounded-md">
          <div>{caption}</div>

          <div>
            {hashtag.map((ht, index) => (
              <React.Fragment key={index}>
                <a
                  href={`https://www.instagram.com/explore/tags/${ht}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  #{ht}
                </a>{' '}
              </React.Fragment>
            ))}
          </div>
        </InputGroup.Text>

        <div className="flex flex-col sm:flex-row gap-4">
          <Button
            className="w-full sm:w-1/2"
            onClick={() => {
              handleCopy(copyText);
              onHide();

              if (!isCaptionOnly) handleNativeShare('With caption');
            }}
          >
            Yes, Copy Caption to Clipboard
          </Button>

          <Button
            variant="pink"
            className="w-full sm:w-1/2"
            onClick={() => {
              onHide();

              if (!isCaptionOnly) handleNativeShare('Without caption');
            }}
          >
            No{!isCaptionOnly && ', Continue Without Caption'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(CaptionModal);
