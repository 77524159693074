import React from 'react';
import { Modal } from 'react-bootstrap';

import { getFileUrl, getThumbnail } from '../../../Utils';

const VideoPreviewModal = (props) => {
  const { show, onHide, video } = props;

  if (!video) return null;

  const time = new Date(video.created).toLocaleString('en-AU', {
    timeZone: 'Australia/Sydney',
  });

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{video.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <video
          className="w-full max-h-[70vh] rounded-md"
          src={getFileUrl(video.elementId, video.name)}
          poster={video.frameId ? getThumbnail(video.frameId) : null}
          controls
          autoPlay
        />
      </Modal.Body>

      <Modal.Footer className="justify-center pt-0 border-0 ">
        Uploaded on {time}
      </Modal.Footer>
    </Modal>
  );
};

export default VideoPreviewModal;
