import React, { useState, useEffect } from 'react';
import LogRocket from 'logrocket';
import { Form } from 'react-bootstrap';
import { isMobile, isTablet } from 'react-device-detect';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  goTo,
  validatePassword,
  validateTsAndCs,
  validateName,
  isProduction,
} from '../../Utils';
import { sendVerificationEmail } from '../../UtilsEmail';

import {
  trackAccountCreateSuccess,
  trackAccountCreateError,
  trackLogInSuccess,
  trackLogInError,
} from '../../analytics';

import { Button } from '../../components';
import EmailField from '../../components/form/emailField';
import NameField from '../../components/form/nameField';
import PasswordField from '../../components/form/passwordField';
import ErrorModal from '../../components/modals/errorModal';
import SubmitBtn from '../../components/submitBtn';

import config from '../../config';
import { URL, PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from '../../constants';

import {
  getUserData,
  updateUserAccount,
  verifyEmail,
} from '../../services/api/auth';
import { mergeEvent, getUserEvents } from '../../services/api/events';
import { signIn } from '../../services/api/sessions';
import { useEventStore } from '../../stores/event';

import OnboardingPage from './onboardingPage';

const CreateAccount = () => {
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isTsAndCs, setIsTsAndCs] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [errors, setErrors] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);

  const changeEvent = useEventStore((state) => state.changeEvent);
  const setUserEvents = useEventStore((state) => state.setUserEvents);

  const navigate = useNavigate();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const verificationIdOnUrl = queryParameters.get('id');
    const userIdOnUrl = queryParameters.get('userId');

    const verifyAndProceed = async () => {
      setIsLoading(true);

      try {
        const userData = await getUserData(userIdOnUrl);

        sessionStorage.setItem('email', userData?.email);

        if (userData.status === 'active') navigate(URL.LOGIN);

        setEmail(userData?.email);
        setUserId(userData?.userId);

        const isVerified = await verifyEmail(
          userData?.userId,
          verificationIdOnUrl,
        );

        if (!isVerified) setShowErrorModal(true);
      } catch (error) {
        console.error(error);
        setShowErrorModal(true);
      }

      setIsLoading(false);
    };

    verifyAndProceed();
  }, []);

  const onResendVerificationEmail = () => {
    sendVerificationEmail(email)
      .then((isSuccess) => {
        if (isSuccess) navigate(URL.EMAIL_VERIFICATION);
      })
      .catch(() => {
        setIsCreating(false);
      });
  };

  const onChange = (e, setValue) => {
    const value = e.target.value;
    setValue(value);
    setErrors({});
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { firstNameErr, lastNameErr } = validateName(firstName, lastName);

    const { passwordErr, confirmPasswordErr } = validatePassword(
      password,
      confirmPassword,
    );

    const { TsAndCsErr } = validateTsAndCs(isTsAndCs);

    if (
      passwordErr ||
      confirmPasswordErr ||
      TsAndCsErr ||
      firstNameErr ||
      lastNameErr
    ) {
      let errorsTemp = {
        firstNameErr,
        lastNameErr,
        passwordErr,
        confirmPasswordErr,
        TsAndCsErr,
      };
      setErrors(errorsTemp);
    } else {
      setIsCreating(true);

      let newUser = { userId, firstName, lastName, password };

      // Update user account with additional details
      const createUserAccount = await updateUserAccount(newUser);

      if (createUserAccount) {
        if (isProduction()) window.fbq('track', 'Lead');

        trackAccountCreateSuccess();

        createEventAndSignIn();
      } else trackAccountCreateError();

      setIsCreating(false);
    }
  };

  const createEventAndSignIn = () => {
    // TODO: change type to event-owner when API privilege level allows

    signIn(email, password, true)
      .then((user) => {
        const previousPage = localStorage.getItem('previousPage');
        goTo(previousPage || URL.HOME);
        localStorage.removeItem('previousPage');

        trackLogInSuccess(previousPage);

        setIsCreating(false);

        const notAllowedDomains = ['crowdclip.com', 'dius.com.au', 'dius.com'];
        const isValidDomain = () => {
          const domain = email.split('@')[1];
          return !notAllowedDomains.includes(domain);
        };

        if (isProduction() && isValidDomain) {
          LogRocket.identify(email);

          const advancedMatching = { em: email };
          const options = {
            autoConfig: true,
            debug: false,
          };
          ReactPixel.init(config.pixelID, advancedMatching, options);
          ReactPixel.track('Leads');

          if (hotjar.initialized()) {
            hotjar.identify(user.session.userId, { email });
          }
        }

        setTimeout(() => {
          if (isMobile || isTablet) {
            toast.warning(
              <div>
                <div>CrowdClip&reg; is best used on a desktop.</div>
                <div>
                  You can continue on your mobile device, but beware, things
                  will look a bit funny.
                </div>
              </div>,
              {
                autoClose: false,
              },
            );
          }
        }, 200);
      })
      .catch(() => {
        toast.error('Something went wrong. Try again later');
        trackLogInError();
        setIsCreating(false);
      });
  };

  return (
    <OnboardingPage
      title="Create Account"
      heading="Create your account in seconds"
      isLoading={isLoading}
    >
      <Form noValidate onSubmit={onSubmit}>
        <fieldset className="gap-4" disabled={isCreating}>
          <EmailField value={email} readOnly disabled required />

          <NameField
            firstNameErrMsg={errors?.firstNameErr}
            lastNameErrMsg={errors?.lastNameErr}
            onChangeFirstName={(e) => onChange(e, setFirstName)}
            onChangeLastName={(e) => onChange(e, setLastName)}
          />

          <Form.Group>
            <div className="flex flex-col sm:flex-row gap-4">
              <PasswordField
                className="w-full sm:w-1/2"
                label="Password*"
                name="password"
                value={password}
                isInvalid={errors?.passwordErr}
                errMsg={errors?.passwordErr}
                onChange={(e) => onChange(e, setPassword)}
              />

              <PasswordField
                className="w-full sm:w-1/2"
                label="Confirm password*"
                name="confirmPassword"
                value={confirmPassword}
                isInvalid={errors?.confirmPasswordErr}
                errMsg={errors?.confirmPasswordErr}
                onChange={(e) => onChange(e, setConfirmPassword)}
              />
            </div>

            <Form.Text className="text-white">
              Password must be {PASSWORD_MIN_LENGTH}-{PASSWORD_MAX_LENGTH}{' '}
              characters long, contain at least 1 lowercase character, 1
              uppercase character, 1 number, and 1 special character.
            </Form.Text>
          </Form.Group>

          <Form.Check id="check-api-checkbox">
            <Form.Check.Input
              value={isTsAndCs}
              onChange={() => setIsTsAndCs(!isTsAndCs)}
              isInvalid={errors?.TsAndCsErr}
            />
            <Form.Check.Label className="text-white">
              Accept{' '}
              <a
                href="https://my.crowdclip.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
            </Form.Check.Label>
            <Form.Control.Feedback type="invalid" className="ml-[-25px]">
              {errors?.TsAndCsErr}
            </Form.Control.Feedback>
          </Form.Check>

          <SubmitBtn
            title="Create Account"
            isProcessing={isCreating}
            className="w-full"
          />
        </fieldset>
      </Form>

      <div className="text-white">
        Already have an account? <Link to={URL.LOGIN}>Log in</Link>
      </div>

      <ErrorModal
        show={showErrorModal}
        btn={
          <div className="w-full flex items-center justify-between gap-4">
            <Button className="w-1/2" onClick={onResendVerificationEmail}>
              Resend Email
            </Button>

            <Button
              variant="pink"
              className="w-1/2"
              onClick={() => navigate(URL.HOME)}
            >
              Return Home
            </Button>
          </div>
        }
        heading="Sorry, we can't verify your account"
        subheading="You may need to resend the verification email. If you still can't verify your account, please contact us."
      />
    </OnboardingPage>
  );
};

export default CreateAccount;
