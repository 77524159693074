import React from 'react';
import classNames from 'classnames';
import {
  PiCaretRight,
  PiPresentation,
  PiPencil,
  PiUsers,
  PiUserCirclePlus,
  PiQrCode,
  PiDownloadSimple,
  PiEnvelope,
  PiChartBar,
} from 'react-icons/pi';

import { isDemoAcc, canEditEvent, getUrl, getCheckInLink } from '../../Utils';

import { trackOpenProject, trackEditProject } from '../../analytics';

import { URL } from '../../constants';

import { useUserStore } from '../../stores/user';
import PopoverTrigger from '../popoverTrigger';
import { QRCode, downloadQRCode } from '../qrCode';
import SideNavBtn from './sideNavBtn';

const CollapseEventLinks = (props) => {
  const { event, isCurrent } = props;
  const user = useUserStore((state) => state.user);

  const containerClass = classNames({
    'pl-[1.6rem]': !isCurrent,
  });

  const eventId = event.eventId;
  const checkInPageLink = getCheckInLink(event.eventId);

  return (
    <div className={containerClass}>
      <SideNavBtn
        icon={<PiPresentation />}
        text="Video Builder"
        href={getUrl(URL.VIDEO_BUILDER, eventId)}
        onClick={() => trackOpenProject('side_nav', event)}
      />

      {canEditEvent(event) && (
        <SideNavBtn
          icon={<PiPencil />}
          text="Edit"
          href={getUrl(URL.DASH_EVENT_BUILDER, eventId)}
          onClick={() => trackEditProject('Side Nav')}
        />
      )}

      <PopoverTrigger
        content={
          // popover is not working when content is custom element
          <button
            className="w-full h-10 flex items-center justify-between px-3 py-1.5 gap-2 no-underline text-black rounded-md hover:bg-grey-100 hover:opacity-100"
            onClick={() => window.open(getUrl(URL.SHARE, eventId), '_blank')}
          >
            <div className="flex gap-2">
              <div className="text-[1.1rem] leading-none">
                <PiUsers />
              </div>
              <div>Hub Page</div>
            </div>

            <div className="text-[1.1rem] leading-none">
              <PiCaretRight />
            </div>
          </button>
        }
        placement="right"
        popoverHeader="There might be no videos yet"
        popoverBody="Videos will appear on the page once generated."
      />

      <SideNavBtn
        icon={<PiUserCirclePlus />}
        text="Faces Check-In"
        href={checkInPageLink}
        isNewTab
      />

      <SideNavBtn
        icon={<PiQrCode />}
        text="Download Check-In QR"
        onClick={() => downloadQRCode(event, 'Side Nav')}
        extraIcon={<PiDownloadSimple />}
      />

      <SideNavBtn
        icon={<PiEnvelope />}
        text="Email Hub"
        href={getUrl(URL.EMAIL_HUB, eventId)}
      />

      <QRCode event={event} link={checkInPageLink} className="hidden" />

      {/* for demo acc only – show link to analytics page */}
      {isDemoAcc(user) && (
        <SideNavBtn
          icon={<PiChartBar />}
          text="Analytics"
          href={URL.DASH_ANALYTICS}
        />
      )}
    </div>
  );
};

export default CollapseEventLinks;
