import React, { useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'react-bootstrap';
import { PiCaretDown, PiCaretRight } from 'react-icons/pi';

import CollapseEventLinks from './collapseEventLinks';

const CollapseEvent = (props) => {
  const { event, isCurrent } = props;

  const [infoOpen, setInfoOpen] = useState(isCurrent);

  // This can happen when no current event is selected
  if (event == null) return null;

  const containerClass = classNames({
    'flex flex-col px-3 py-1.5 gap-2 rounded-md border-1 border-primary-900 border-solid':
      isCurrent,
    '': !isCurrent,
  });

  const nameClass = classNames({
    'text-left': true,
    'w-[calc(var(--size-nav-width)_-_2px_-_1.5rem_-_0.5rem_-_0.75rem_-_24px)]':
      isCurrent,
    // size nav width - 2 * 1px borders - 2 * 0.75rem paddings - 0.5em gap – 0.75rem right padding - 24px orientation icon
    'w-[calc(var(--size-nav-width)_-_1.5rem_-_1rem_-_1.1rem_-_24px)] whitespace-nowrap overflow-hidden text-ellipsis':
      !isCurrent,
    // size nav width - 2 * 0.75rem paddings - 2 * 0.5em gaps - 1.1em chevron icon - 24px orientation icon
  });

  const modeClass = classNames({
    'w-[24px] h-[13.5px] rounded-[2px] border-1 border-solid': true,
    'border-transparent': !event.videoMode,
    'bg-grey-100 border-grey-900': !!event.videoMode,
    'rotate-90': event.videoMode === 'portrait',
  });

  return (
    <div
      className={`${containerClass} event-block ${isCurrent ? 'current' : ''}`}
    >
      {isCurrent ? (
        <div className="flex items-center justify-between pr-3 gap-2 font-bold">
          <div className={nameClass}>{event.name}</div>
          <div className={modeClass} />
        </div>
      ) : (
        <button
          className="w-full h-10 flex items-center justify-between px-3 py-1.5 gap-2 rounded-md hover:bg-grey-100"
          onClick={() => setInfoOpen(!infoOpen)}
        >
          <div className="flex gap-2 items-center">
            <div className="text-[1.1rem] leading-none">
              {infoOpen ? <PiCaretDown /> : <PiCaretRight />}
            </div>

            <div className={nameClass}>{event.name}</div>
          </div>

          <div className={modeClass} />
        </button>
      )}

      <Collapse in={infoOpen}>
        <div id="collapse-wrapper">
          <CollapseEventLinks event={event} isCurrent={isCurrent} />
        </div>
      </Collapse>
    </div>
  );
};

export default CollapseEvent;
