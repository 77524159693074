import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

const NameField = (props) => {
  const {
    firstName,
    lastName,
    firstNameErrMsg,
    lastNameErrMsg,
    onChangeFirstName,
    onChangeLastName,
  } = props;

  return (
    <div className="flex flex-col sm:flex-row gap-4">
      <FloatingLabel label="First Name*" className="w-full sm:w-1/2">
        <Form.Control
          type="text"
          name="firstName"
          value={firstName}
          onChange={onChangeFirstName}
          placeholder="First Name"
          isInvalid={firstNameErrMsg}
          required
        />
        {firstNameErrMsg && (
          <Form.Control.Feedback type="invalid">
            {firstNameErrMsg}
          </Form.Control.Feedback>
        )}
      </FloatingLabel>

      <FloatingLabel label="Last Name*" className="w-full sm:w-1/2">
        <Form.Control
          type="text"
          name="lastName"
          value={lastName}
          onChange={onChangeLastName}
          placeholder="Last Name"
          isInvalid={lastNameErrMsg}
          required
        />
        {lastNameErrMsg && (
          <Form.Control.Feedback type="invalid">
            {lastNameErrMsg}
          </Form.Control.Feedback>
        )}
      </FloatingLabel>
    </div>
  );
};

export default NameField;
