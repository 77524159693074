import ReactGA from 'react-ga4';

import { getDemoSuffix } from '../Utils';

// GENERAL

export const trackClickDoneButton = (page, event, label) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_click_done_button${getDemoSuffix(event)}`,
    label,
  });
};

export const trackClickMainBackButton = (page, event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_click_main_back_button${getDemoSuffix(event)}`,
  });
};

export const trackClickBackButton = (page, event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_click_back_button${getDemoSuffix(event)}`,
  });
};

export const trackClickTakeMeBack = (page, event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_click_take_me_back${getDemoSuffix(event)}`,
  });
};

// UPLOAD

export const trackUploadDemo = (page, count) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_${!!count ? 'batch_' : ''}upload_success-[Demo]`,
    value: count ?? 1,
  });
};

export const trackUploadFileSuccess = (page, eventId, duration) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_upload_success`,
    label: eventId,
    value: duration ?? null,
  });
};

export const trackUploadBatchSuccess = (page, eventId, duration) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_batch_upload_success`,
    label: eventId,
    value: duration ?? null,
  });
};

export const trackUploadError = (page, fileName) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_upload_error`,
    label: fileName,
  });
};

export const trackUploadOrientationChoiceAccept = (page, orientation) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_orientation_choice_accept`,
    label: orientation,
  });
};

export const trackUploadOrientationReminderAccept = (page, isSaved) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_orientation_reminder_accept`,
    label: `${isSaved ? '' : 'NOT '} Saved`,
  });
};

export const trackUploadOrientationModalReject = (page, isReminder) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_orientation_${isReminder ? 'reminder' : 'choice'}_reject`,
  });
};

export const trackUploadShotReminderAccept = (isSaved) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'hl_reel_shot_reminder_accept',
    label: `${isSaved ? '' : 'NOT '} Saved`,
  });
};

export const trackUploadShotReminderReject = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'hl_reel_shot_reminder_reject',
  });
};

export const trackDownloadIndividualSamples = (page) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_download_samples`,
  });
};

export const trackClickUploadMore = (page, event, position) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `${page}_click_upload_more${getDemoSuffix(event)}`,
    label: position,
  });
};

// note: may need to delete
export const trackVBClickUploadMore = (page, event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `vb_click_upload_more_${page}${getDemoSuffix(event)}`,
  });
};

export const trackVBSaveRemindLaterSuccess = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_save_remind_later_success',
  });
};

export const trackVBSaveRemindLaterError = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_save_remind_later_error',
  });
};

// Assign

export const trackVBAssignModalOpen = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_assign_modal_open',
  });
};

export const trackVBAssignModalAddAssignee = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_assign_modal_add_assignee',
  });
};

export const trackVBAssignModalRemoveAssignee = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_assign_modal_remove_assignee',
  });
};

export const trackVBAssignModalChangeAssess = (access) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_assign_modal_change_access',
    label: `To ${access} access`,
  });
};

export const trackVBAssignModalOpenLink = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_assign_modal_open_link',
  });
};

export const trackVBAssignModalCopyLink = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_assign_modal_copy_link',
  });
};

export const trackVBAssignModalSaveSuccess = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_assign_modal_save_success',
  });
};

export const trackVBAssignModalSaveError = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'vb_assign_modal_save_error',
  });
};

// People

export const trackPeopleClickCheckInLink = (source) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'people_click_check_in_link',
    label: source,
  });
};

export const trackPeopleToggleDetails = (isToDetails) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'people_click_details_toggle',
    label: isToDetails ? 'To details' : 'To summary',
  });
};

export const trackPeopleDeletePerson = (event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `people_delete_person${getDemoSuffix(event)}`,
  });
};

export const trackPeopleEditPerson = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'people_click_edit_person',
  });
};

export const trackPeopleUpdatePersonSuccess = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'people_update_person_success',
  });
};

export const trackPeopleUpdatePersonError = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'people_update_person_error',
  });
};

export const trackPeopleUploadAdditionalPhotosSuccess = (count) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'people_upload_additional_photos_success',
    value: count,
  });
};

export const trackPeopleUploadAdditionalPhotosError = (fileName) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'people_upload_additional_photos_error',
    label: fileName,
  });
};

// Clips

export const trackClipsDeleteClip = (event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `clips_delete_clip${getDemoSuffix(event)}`,
  });
};

// ANALYSIS

export const trackAnalysisStartSuccess = (event, isQuickStart) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `analysis_start_success${getDemoSuffix(event)}`,
    label: isQuickStart ? 'Upload' : 'Video Builder',
  });
};

export const trackAnalysisStartError = (errMsg) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'analysis_start_error',
    label: errMsg,
  });
};

export const trackAnalysisClickSeeProgress = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'analysis_click_see_progress',
  });
};

export const trackAnalysisFinish = (event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `analysis_finish${getDemoSuffix(event)}`,
  });
};

export const trackAnalysisShowClipsWarning = (event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `analysis_show_clips_warning${getDemoSuffix(event)}`,
  });
};

export const trackAnalysisClickOnPerson = (event, label) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `analysis_click_on_person${getDemoSuffix(event)}`,
    label,
  });
};

export const trackAnalysisCreationPreviewPlay = (event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `analysis_creation_preview_play${getDemoSuffix(event)}`,
  });
};

export const trackAnalysisCreationPreviewError = (event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `analysis_creation_preview_error${getDemoSuffix(event)}`,
  });
};

export const trackAnalysisOpenHub = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'analysis_open_hub',
  });
};

export const trackAnalysisOpenCreation = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'analysis_open_creation',
  });
};

export const trackAnalysisClickUploadMoreClips = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'analysis_click_upload_more_clips',
  });
};

export const trackAnalysisOpenMAModal = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'analysis_open_missing_assets_modal',
  });
};

export const trackAnalysisClickVBInNewUploads = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'analysis_click_vb_in_new_uploads',
  });
};

export const trackAnalysisDeleteClip = (label) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'analysis_delete_clip',
    label,
  });
};

// GENERATE

export const trackGenerationStartSuccess = (event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `generation_start_success${getDemoSuffix(event)}`,
  });
};

export const trackGenerationStartError = (errMsg) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'generation_start_error',
    label: errMsg,
  });
};

export const trackGenerationFinish = (event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `generation_finish${getDemoSuffix(event)}`,
  });
};

export const trackAttendeeClickGenerate = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'attendee_click_generate',
  });
};

export const trackAttendeeGenerationStartSuccess = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'attendee_generation_start_success',
  });
};

export const trackAttendeeGenerationStartError = (errMsg) => {
  ReactGA.event({
    category: 'Attendee',
    action: 'attendee_generation_start_error',
    label: errMsg,
  });
};

export const trackAttendeeGenerationFinish = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'attendee_generation_finish',
  });
};

// SHARE

export const trackShareOpenShareHubModal = (event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `share_open_share_hub_modal${getDemoSuffix(event)}`,
  });
};

export const trackShareOpenLink = (type, event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `share_open_${type}_link${getDemoSuffix(event)}`,
  });
};

export const trackShareCopyLink = (type, event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `share_copy_${type}_link${getDemoSuffix(event)}`,
  });
};

export const trackShareDownload = (type, event) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `share_download_${type}${getDemoSuffix(event)}`,
  });
};

export const trackShareToggleVideoSize = (event, isToIncreased) => {
  ReactGA.event({
    category: 'Video Builder',
    action: `share_toggle_video_size${getDemoSuffix(event)}`,
    label: isToIncreased ? 'Increased' : 'Reduced',
  });
};

export const trackShareBulkShare = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'share_click_bulk_share',
  });
};

export const trackShareDemoShare = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'share_click_share-[Demo]',
  });
};

export const trackShareCreationShare = (label) => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'share_click_creation_share',
    label,
  });
};

export const trackShareReOpenWorkflow = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'share_click_re_open_workflow',
  });
};

// SET DATE (AR FLOW)

export const trackSetDateUpdateSuccess = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'set_date_update_success',
  });
};

export const trackSetDateUpdateError = () => {
  ReactGA.event({
    category: 'Video Builder',
    action: 'set_date_update_error',
  });
};
