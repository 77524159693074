import React, { useState, useEffect } from 'react';
import { Modal, Form, FloatingLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import TurndownService from 'turndown';

import { isEmpty } from '../../../../Utils';

import SubmitBtn from '../../../../components/submitBtn';

import { updateOneEvent } from '../../../../services/api/mongodb';

import { useEventStore } from '../../../../stores/event';

const turndownService = new TurndownService();

const CustomTextModal = (props: any) => {
  const { show, onHide, event, emailType } = props;

  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [customText, setCustomText] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // fetch data on mount
  useEffect(() => {
    let text = '';
    if (emailType === 'creation')
      text = event?.emailsData?.creationTemplateText ?? '';

    setCustomText(turndownService.turndown(text).replace(/ +\n/g, '\n'));
  }, [emailType, event?.emailsData?.creationTemplateText]);

  if (isEmpty(event)) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setIsSaving(true);

      const data: any = { eventId: event.eventId };

      if (emailType === 'creation') data.creationTemplateText = customText;

      await updateOneEvent(data);
      await updateCurrentEvent();

      toast.success('Your custom text is updated');
      onHide();
    } catch {
      toast.error('Something went wrong. Try again later');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header className="px-4 sm:px-8" closeButton>
        <Modal.Title>Customise email text</Modal.Title>
      </Modal.Header>

      <Modal.Body className="flex flex-col px-4 sm:px-8 gap-4">
        <div>
          Your text will appear above the button on the template. Use ‘Preview’
          for a visual reference.
        </div>

        <Form onSubmit={onSubmit} noValidate className="gap-4">
          <FloatingLabel label="Custom Text">
            <Form.Control
              as="textarea"
              type="text"
              name="customText"
              value={customText ?? ''}
              onChange={(e) => setCustomText(e.target.value)}
              placeholder="Your custom text"
              required
            />

            <Form.Text>
              To italicise text, add one asterisk or underscore before and after
              a word or phrase. E.g. This is _italic text_.
              <br />
              To bold text, add two asterisks or underscores before and after a
              word or phrase. E.g. This is **bold text**.
            </Form.Text>
          </FloatingLabel>

          <SubmitBtn
            title="Save"
            isProcessing={isSaving}
            isDisabled={isEmpty(customText)}
          />
        </Form>
      </Modal.Body>

      <Modal.Footer className="justify-start px-4 sm:px-8">
        <div className="text-sm italic">
          We suggest clicking 'Preview' after saving your text to make sure
          you’re happy with the result.
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomTextModal;
