import React from 'react';

const PageSubheader = (props) => {
  const { heading, subheading } = props;

  return (
    <div className="w-full flex flex-col gap-2">
      {heading && <div className="font-bold uppercase text-lg">{heading}</div>}

      {subheading && <div>{subheading}</div>}
    </div>
  );
};

export default PageSubheader;
