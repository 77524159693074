import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { PiShareFat, PiEnvelope } from 'react-icons/pi';

import { Button } from '../';
import { checkFaceEmail, sendFaceEmail } from '../../UtilsEmail';
import { FACE_EMAIL_STATUS } from '../../constants';

import FormFeedback from './formFeedback';

const EmailSendGroup = (props) => {
  const {
    type,
    event,
    face,
    faceEmails,
    setFaceEmails,
    afterEmailSend,
    btnText,
    className,
    isDemo,
  } = props;
  const emailObj = faceEmails[face.elementId] || {};
  const onChangeEmail = (e, id) => {
    faceEmails[id].email = e.target.value;
    faceEmails[id].err = null;

    setFaceEmails({ ...faceEmails });
  };

  const handleSend = async (e) => {
    const { isSuccess } = await sendFaceEmail(
      e,
      type,
      event,
      face,
      faceEmails,
      setFaceEmails,
      true,
    );

    if (isSuccess && afterEmailSend) afterEmailSend();
  };

  const creationTemplateId = event.emailsData?.creationTemplateId;

  return (
    <Form
      className={className}
      noValidate
      onSubmit={(e) =>
        checkFaceEmail(e, face.elementId, faceEmails, setFaceEmails)
      }
    >
      <fieldset
        className="flex flex-row gap-2"
        disabled={emailObj.status === FACE_EMAIL_STATUS.SENDING || isDemo}
      >
        <Form.Group className="grow">
          <InputGroup>
            <InputGroup.Text className="h-12">
              <PiEnvelope />
            </InputGroup.Text>

            <Form.Control
              type="email"
              name="email"
              value={emailObj.email}
              onChange={(e) => onChangeEmail(e, face.elementId)}
              placeholder={`Enter email address to ${btnText.toLowerCase()}...`}
              isInvalid={emailObj.err}
              required
              className="h-12"
            />
          </InputGroup>

          <FormFeedback error={emailObj.err} />
        </Form.Group>

        <Button
          onClick={handleSend}
          variant="black"
          form="square"
          isThin
          className="h-12"
          disabled={!creationTemplateId}
          allowPointerEvents
          isSubmitBtn
          isProcessing={emailObj.status === FACE_EMAIL_STATUS.SENDING}
          isDone={emailObj.status === FACE_EMAIL_STATUS.SENT}
          spinnerSize="md"
          popoverHeader="Unable to share"
          popoverBody={
            !creationTemplateId
              ? 'First, select the email template above'
              : 'Sharing email is not available in the sample project'
          }
          hidePopover={!isDemo && creationTemplateId}
        >
          <PiShareFat size="1.25rem" />
        </Button>
      </fieldset>
    </Form>
  );
};

export default EmailSendGroup;
