import { isEmpty, getFileImg, getDemoSelected } from '../Utils';
import { getSelectFiles, getClipResults } from './api/files';

const ONE_HOUR = 60 * 60 * 1000; /* ms */

const isTimedOut = (file) => {
  return new Date() - new Date(file.created) > 5 * ONE_HOUR;
};

export const getUploadedSelects = async (eventId, isPublic = false) => {
  let allFiles = await getSelectFiles(eventId, isPublic);

  let selects = allFiles.map((file) => {
    return { ...file, status: 'UPLOADED' };
  });

  return selects;
};

export const getClipGroups = async (eventId, deletingSelects, isDemo) => {
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};

  let allFiles = await getSelectFiles(eventId);

  // while select is deleting it may end up in finished selects while queues are deleted but file not yet
  const filteredFiles = allFiles.filter(
    (select) => !deletingSelects.includes(select.elementId),
  );

  // if no selects uploaded – return no-selects stage
  if (isEmpty(filteredFiles)) return { stage: 'no-selects' };

  let { files, results } = await getClipResults(eventId);

  // if no faces uploaded – return no-faces stage
  if (isEmpty(results)) return { stage: 'no-faces' };

  // array of ids – to later filter processing / errored selects from finished
  let selectIds = [];

  // processing selects – go through 2 steps
  let newSelects = [];

  files.new.forEach((file) => {
    newSelects.push(file);
    selectIds.push(file.elementId);
  });

  // timed out selects (created more than 5 hours ago)
  // NOTE: stays empty array for now – needs solution from BE
  let timedOutSelects = [];

  // processing selects – go through 2 steps
  let convertingSelects = [];
  let analysingSelects = [];

  // sorted by date created so files do not change order when data is updated
  let sortedProcessingSelects = files.processing.sort((one, other) => {
    if (other.created > one.created) return -1;
    return 1;
  });

  sortedProcessingSelects.forEach((file) => {
    if (!file.name.includes('converted')) file.status = 'CONVERTING';
    else if (!file.frameId) file.status = 'GENERATING_THUMBNAIL';
    else file.status = 'EXTRACTING_CLIPS';

    if (file.status === 'CONVERTING') convertingSelects.push(file);
    else analysingSelects.push(file);

    selectIds.push(file.elementId);
  });

  // error selects
  let errorSelects = [];

  files.error.forEach((file) => {
    file.status = 'ERROR';

    errorSelects.push(file);
    selectIds.push(file.elementId);
  });

  // finished selects
  let finishedSelects = [];

  filteredFiles.forEach((file) => {
    if (!selectIds.includes(file.elementId)) {
      file.status = 'PROCESSED';

      finishedSelects.push(file);
    }
  });

  // clips groups
  let clipGroups = [];

  // demo event – filter out non-selected assets
  if (isDemo) {
    const selectedPeople = getDemoSelected(demoData.people).map(
      (person) => person.face.elementId,
    );
    const selectedClips = demoData.clips
      .filter((clip) => clip.isSelected && clip.isAnalysed)
      .map((clip) => clip.elementId);

    results.forEach((group) => {
      if (selectedPeople.includes(group.face.elementId)) {
        clipGroups.push({
          ...group,
          clips: group.clips.filter((clip) =>
            selectedClips.includes(clip.parentElementId),
          ),
        });
      }
    });
  }

  // usual event – use all results
  else clipGroups = [...results];

  clipGroups.forEach((result) => {
    result.img = getFileImg(result.files[0]);

    let matchingClips = [];

    result.clips.forEach((clip) => {
      // Check if this clip has a person with a matching faceId
      let matchingPersonScore = (clip.personsScore || []).find(
        (personScore) => personScore.faceId === result.face.elementId,
      );

      if (matchingPersonScore) {
        matchingClips.push({
          ...clip,
          matchingScore: matchingPersonScore.score,
        });
      }
    });

    // Sort the matching clips by personScore in descending order
    matchingClips.sort(
      (clipA, clipB) => clipB.matchingScore - clipA.matchingScore,
    );
    result.clips = matchingClips;
  });

  return {
    stage: sortedProcessingSelects.length > 0 ? 'processing' : 'finished',
    selects: {
      newSelects,
      convertingSelects,
      analysingSelects,
      finishedSelects,
      timedOutSelects,
      errorSelects,
    },
    clipGroups,
  };
};
