import React from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';

const FormFeedback = (props) => {
  const { error, className } = props;

  return (
    <Form.Control.Feedback
      className={classNames({ show: !!error }, className ?? '')}
      type="invalid"
    >
      {error}
    </Form.Control.Feedback>
  );
};

export default FormFeedback;
