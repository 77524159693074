import React from 'react';

import eventUgcReq from '../../../assets/event-ugc-req.png';

import EventDatesSelector from './eventDatesSelector';
import EventOrientationSelector from './eventOrientationSelector';
import EventWildcardsSelector from './eventWildcardsSelector';
import HeadingWithInfo from './headingWithInfo';

const EventDetailsSection = (props) => {
  const {
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    orientation,
    setOrientation,
    wildcards,
    setWildcards,
    errors,
  } = props;

  return (
    <div className="w-full sm:w-1/2 flex flex-col gap-6">
      <HeadingWithInfo
        heading="Project Details"
        infoHeader="Setting up your project details"
        infoBody={
          <>
            <div>
              Project metadata as start and end date determines the project
              availability. E.g. you won't be able to edit a project after the
              start date, and attendees won't be able to check in after the end
              date.
            </div>

            <hr />

            <div>
              Based on the selected orientation and wildcards attendees will be
              required to upload the corresponding UGC on the Check-In page.
            </div>

            <img src={eventUgcReq} alt="UGC Requirements Graphic" />

            <div>The uploaded template should also match these requisites.</div>
          </>
        }
      />

      <EventDatesSelector
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={onChangeStartDate}
        onChangeEndDate={onChangeEndDate}
        errors={errors}
      />

      <EventOrientationSelector
        orientation={orientation}
        setOrientation={setOrientation}
        error={errors.orientation}
      />

      <EventWildcardsSelector
        wildcards={wildcards}
        setWildcards={setWildcards}
        error={errors.wildcards}
      />
    </div>
  );
};

export default EventDetailsSection;
