import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export const useUserStore = create(
  subscribeWithSelector((set, get) => ({
    user: null,
    accessToken: localStorage.getItem('accessToken') || null,
    setUser: (user) => {
      set((state) => ({ ...state, user }));
    },
    setAccessToken: (accessToken) => {
      set((state) => ({ ...state, accessToken }));
    },
    saveAccessToken: () => {
      localStorage.setItem('accessToken', get().accessToken);
    },
    clearAccessToken: () => {
      localStorage.removeItem('accessToken');
      set((state) => ({ ...state, accessToken: null }));
    },
  })),
);
