import React from 'react';

import { PiUserCircleFill } from 'react-icons/pi';

import { useUserStore } from '../../stores/user';

const ProfileIcon = () => {
  const user = useUserStore((state) => state.user);

  const getInitials = () => {
    return (
      (user.firstName ? user.firstName[0].toUpperCase() : '') +
      (user.lastName ? user.lastName[0].toUpperCase() : '')
    );
  };

  if (!user.firstName && !user.lastName)
    return (
      <div className="w-12 h-12 flex items-center justify-center hover:opacity-80">
        <div className="absolute w-9 h-9 bg-cc-purple-700 rounded-full z-0" />
        <PiUserCircleFill color="white" className="w-full h-full z-[1]" />
      </div>
    );

  return (
    <div className="w-10 h-10 flex items-center justify-center bg-cc-purple-700 text-white font-medium rounded-full hover:opacity-80">
      {getInitials()}
    </div>
  );
};

export default ProfileIcon;
