import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import Logo from '../../components/logo';
import PageSpinner from '../../components/pageSpinner';

const OnboardingPage = (props) => {
  const { title, heading, children, isLoading } = props;

  const s3Folder =
    'https://crowdclip-media-assets.s3.ap-southeast-2.amazonaws.com/onboarding/';
  const backgroundImage = s3Folder + 'onboarding-background.png';
  const imagePath =
    s3Folder + `onboarding-gif-${isMobile || isTablet ? 'mobile' : 'desktop'}`;

  return (
    <PageSpinner isLoading={isLoading} title={title}>
      <div className="h-[calc(100vh_-_var(--header-height))] flex items-center justify-center mt-[var(--header-height)] py-8">
        <img
          className="fixed top-0 w-screen h-screen object-cover -z-10"
          src={backgroundImage}
          alt="Background"
        />

        <div className="w-[var(--page-width)] sm:w-3/4 sm:min-h-[75vh] flex flex-col-reverse sm:flex-row m-auto bg-black-overlay-600 rounded-md sm:rounded-l-md sm:rounded-r-none">
          <div className="w-full sm:w-[60%] h-fit sm:min-h-[75vh] flex flex-col items-center justify-center p-6 sm:p-10 gap-4">
            <Logo />

            <div className="text-center font-bold text-xl sm:text-2xl text-white">
              {heading}
            </div>

            <div className="w-full flex flex-col gap-4">{children}</div>
          </div>

          <div className="w-full sm:w-[40%] h-[calc(var(--page-width)_*_9_/_16)] sm:h-auto object-cover bg-black rounded-t-md sm:rounded-none">
            <video
              className="w-full h-full object-cover"
              loop
              autoPlay
              muted
              playsInline
              onError={(e) => {
                e.target.style.display = 'none';
                e.target.nextSibling.style.display = 'block';
              }}
            >
              <source src={imagePath + '.webm'} type="video/webm" />
              Your browser does not support the webm format.
            </video>
            <img
              className="w-full h-full object-cover"
              src={imagePath + '.gif'}
              alt="Onboarding Graphic"
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>
    </PageSpinner>
  );
};

export default OnboardingPage;
