import axios from 'axios';
import config from '../../../config';

const { apiKey } = config;

export const getHttp = (baseUrl) => {
  return axios.create({
    baseURL: baseUrl,
    headers: { Authorization: `Bearer ${apiKey}` },
  });
};
