import ReactGA from 'react-ga4';

export const trackClickRemoveWatermark = () => {
  ReactGA.event({
    category: 'User',
    action: 'click_remove_watermark',
  });
};

export const trackAttendeeClickRemoveWatermark = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'attendee_click_remove_watermark',
  });
};

export const trackAttendeeConfirmRemoveWatermark = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'attendee_confirm_remove_watermark',
  });
};

export const trackAttendeeCancelRemoveWatermark = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'attendee_cancel_remove_watermark',
  });
};

export const trackPaymentSuccess = (label) => {
  ReactGA.event({
    category: label.toLowerCase().includes('attendee') ? 'Attendee' : 'User',
    action: 'payment_success',
    label,
  });
};

export const trackPaymentError = (label) => {
  ReactGA.event({
    category: label.toLowerCase().includes('attendee') ? 'Attendee' : 'User',
    action: 'payment_error',
    label,
  });
};
